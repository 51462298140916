import React, { FunctionComponent, ReactNode } from "react";
import { Skeleton } from "antd";
import "./styles.scss";

export const LoadingSkeleton = () => {
    return (
        <div>
            <div className="loading-text-skeleton">
            <Skeleton active />
            </div>
        </div>
    );
}