import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import StateStatus from "../../utils/stateStatus";
import { makeLogin, getProfile, makeLoginWithToken } from "./service";
import { LoginData as LoginDataPrams, getProfileResponse, TokenData as loginWithTokenParams } from "./interfaces";
import { ClientDataType, UserType } from "../dashborad/pages/users/interfaces";



// Estado inicial tipado
interface AuthState {
    errMessage: string | null;
    notRegistered: string | null;
    status: {
        login: StateStatus;
        loginWithToken: StateStatus;
        logout: StateStatus;
        getProfile: StateStatus;
    };
    data: {
        profile: UserType | null;
        client: ClientDataType | null;
    };
}

// Thunks tipados
export const loginThunk = createAsyncThunk<getProfileResponse, LoginDataPrams>(
    "auth/login",
    async (data: LoginDataPrams) => {
        const response = await makeLogin({
            username: data.email,
            password: data.password,
        });

        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);

        return response.data;
    }
);

export const loginWithTokenThunk = createAsyncThunk<getProfileResponse, loginWithTokenParams>(
    "auth/login-token",
    async (data: loginWithTokenParams) => {
        const response = await makeLoginWithToken({
            token: data.token,
        });

        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);

        return response.data;
    }
);

export const getProfileThunk = createAsyncThunk<getProfileResponse>(
    "auth/get-profile",
    async () => {
        const response = await getProfile();
        return response.data;
    }
);

export const logoutThunk = createAsyncThunk<boolean>(
    "auth/logout",
    async () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        return true;
    }
);

export const isAuthenticated = (): boolean => {
    const accessToken = localStorage.getItem("access_token");
    return accessToken !== null;
};

// Estado inicial tipado
const initialState: AuthState = {
    errMessage: null,
    notRegistered: null,
    status: {
        login: StateStatus.idle,
        loginWithToken: StateStatus.idle,
        logout: StateStatus.idle,
        getProfile: StateStatus.idle,
    },
    data: {
        profile: null,
        client: null,
    },
};

export const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        resetLoginState: (state) => {
            state.status.login = StateStatus.idle;
            state.status.loginWithToken = StateStatus.idle;
        },
        resetGetProfileState: (state) => {
            state.status.getProfile = StateStatus.idle;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginThunk.pending, (state) => {
                state.status.login = StateStatus.loading;
            })
            .addCase(loginThunk.fulfilled, (state, action) => {
                state.status.login = StateStatus.succeeded;
                state.data.profile = action.payload.profile;
                state.data.client = action.payload.client;
            })
            .addCase(loginThunk.rejected, (state, action) => {
                state.status.login = StateStatus.failed;
                state.errMessage = action.error.message || null;
            })
            .addCase(loginWithTokenThunk.pending, (state) => {
                state.status.loginWithToken = StateStatus.loading;
            })
            .addCase(loginWithTokenThunk.fulfilled, (state, action) => {
                state.status.loginWithToken = StateStatus.succeeded;
                state.data.profile = action.payload.profile;
                state.data.client = action.payload.client;
            })
            .addCase(loginWithTokenThunk.rejected, (state, action) => {
                state.status.loginWithToken = StateStatus.failed;
                state.errMessage = action.error.message || null;
            })
            .addCase(getProfileThunk.pending, (state) => {
                state.status.getProfile = StateStatus.loading;
            })
            .addCase(getProfileThunk.fulfilled, (state, action) => {
                state.status.getProfile = StateStatus.succeeded;
                state.data.profile = action.payload.profile;
                state.data.client = action.payload.client;
            })
            .addCase(getProfileThunk.rejected, (state, action) => {
                state.status.getProfile = StateStatus.failed;
                state.errMessage = action.error.message || null;
            })
            .addCase(logoutThunk.pending, (state) => {
                state.status.logout = StateStatus.loading;
            })
            .addCase(logoutThunk.fulfilled, (state) => {
                state.status.logout = StateStatus.succeeded;
                state.data.profile = null;
                state.data.client = null;
            })
            .addCase(logoutThunk.rejected, (state, action) => {
                state.status.logout = StateStatus.failed;
                state.errMessage = action.error.message || null;
            });
    },
});

export const { resetLoginState, resetGetProfileState } = loginSlice.actions;

export const loginSelector = (state: { login: AuthState }) => state.login;
