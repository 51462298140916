
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTargetThunk, selectTargetPageState } from "./slice";
import StateStatus from "../../../../utils/stateStatus";
import { TabContentVeiculos } from "./tabs/veiculos";
import { TabContentProcessos } from "./tabs/processos";
import "./styles.scss";
import { Badge, Skeleton, Tabs} from "antd";
import { TabContentReceitaFederal } from "./tabs/receita_federal__grupo_economico_cnpj";
import { TabContentInfoCNPJ } from "./tabs/receita_federal__info_cnpj";
import { ServicesEnum } from "../../../../utils/services";
import { TabContentResumo } from "./tabs/resumo";
import { TabContentPartnership } from "./tabs/receita_federal_partnerships";
import { TabContentInfoCPF } from "./tabs/credilink__info_cpf";
import { useLocation, useNavigate, useParams } from "react-router";
import { TabContentEcosystem } from "./tabs/ecosystem";
import { TabContentWhois } from "./tabs/whois";
import { TabContentTST } from "./tabs/tst";
import { TabContentGnews } from "./tabs/gnews";
import { TabContentMarcas } from "./tabs/inpi__marcas";
import { TabContentPatentes } from "./tabs/inpi__patentes";
import { TabContentParecer } from "./tabs/parecer";
import { TabContentSintegraPJ } from "./tabs/sintegra__sintegra_pj";
import { TabContentSintegraPF } from "./tabs/sintegra__sintegra_pf";
import { TabContentSocialmedia } from "./tabs/socialmedia";
import { TabContentJucesp } from "./tabs/jucesp";
import { TabContentBoavista } from "./tabs/boavista";
import { TabContentFileAttachments } from "./tabs/file_attachments";
import { TabContentVideoExplain } from "./tabs/video_explain";
import { selectReportPageState } from "../report-page/slice";

const BadgeNumber = ({ count, isLoading }) =>  {
  if (!count || count <= 0) return <Badge color="gray" size="small" count={0}/>
  // if (isLoading) return <Icons icon="company"/>
  return <Badge color="blue" size="small" count={count} overflowCount={999}/>
}

const TargetPage = ({reportId, targetId}) => {
    const state = useSelector(selectTargetPageState);
    const reportState = useSelector(selectReportPageState);
    const dispatch = useDispatch()
    const target = state.data.target
    const services = target?.services
    const navigate = useNavigate()
    const location = useLocation()
    const [resumoTab, setResumoTab] = useState<React.JSX.Element>()
    const [activeTab, setActiveTab] = useState<string>()

    useEffect(() => {
      if(reportState.data.report?.case_type === "smart_pro") {
        console.log("show video presentation")
        // TODO: show video presentation
      }
    }, [reportId])

    useEffect(() => {
      changeActiveTab()  
    }, [location.search, location.hash])

    useEffect(() => {
      dispatch(getTargetThunk(targetId))
    }, [targetId])

    
    useEffect(() => {
      if(state.status.getTarget === StateStatus.loading) {
        changeActiveTab()
      }
    }, [state.status.getTarget])

    const changeActiveTab = () => {
      const query = new URLSearchParams(location.search)
      const tab = query.get("tab")
      const defaultTab = "resumo"
      setActiveTab(tab ? tab.toString().toLowerCase() : defaultTab)
      window.scrollTo(0, 0);
    }
    
    const onChangeReportTab = () => {
      return Tabs
    }

    interface TabNew {
      key: string,
      label: ReactNode,
      order?: number | undefined,
      count: number | undefined | null,
      status?: 'loading' | 'completed' | 'idle' | 'released' | 'error' | string,
      can_render?: boolean | undefined,
      children: ReactNode,
    }

      const items: TabNew[] = useMemo(() => {
        if(state.status.getTarget !== StateStatus.succeeded  || !target) return []

        setResumoTab(<TabContentResumo />)
        
        const lst = [
          {
            key: "resumo",
            label: 'Resumo',
            order: 1,
            count: null,
            status: target.status,
            can_render: true,
            children: resumoTab,
          },
          {
            key: "orientacoes",
            label: 'Orientações',
            order: 2,
            count: null,
            status: target.status,
            can_render: true,
            children: <TabContentVideoExplain />,
          },
          {
            key: "attachments",
            label: 'Arquivos e anexos',
            order: 3,
            count: (
              (reportState.data.files?.report_files?.filter(x => x.status === "completed")?.length ?? 0) + 
              (reportState.data.files?.target_files?.filter(x => x.xlsx?.status === "completed" && x.id == targetId)?.length ?? 0) + 
              (reportState.data.files?.target_files?.filter(x => x.pdf?.status === "completed"&& x.id == targetId)?.length ?? 0)
            ),
            status: target.status,
            can_render: true,
            children: <TabContentFileAttachments />,
          },
          {
            key: ServicesEnum.parecer.name,
            label: 'Parecer',
            order: 4,
            count: 1,
            status: services?.parecer?.service_status,
            can_render: Boolean(services?.parecer),
            children:  <TabContentParecer />,
          },
          {
            key: ServicesEnum.credilink.info_cpf.name,
            label: 'Dados cadastrais',
            order: 5,
            count: 1,
            status: services?.credilink?.service_status,
            can_render: Boolean(services?.receita_federal && target?.cpf_cnpj?.length > 11),
            children:  <TabContentInfoCNPJ />,
          },
          {
            key: ServicesEnum.receita_federal.info_cnpj.name,
            label: 'Dados cadastrais',
            order: 5,
            count: 1,
            status: services?.receita_federal?.service_status,
            can_render: Boolean(services?.credilink && target?.cpf_cnpj?.length <= 11),
            children:  <TabContentInfoCPF />,
          },
          {
            key: "score_protestos_negativacoes",
            label: "Score, Protestos e Negativações",
            count: (
              (services?.boavista?.counters?.score_risco_6_meses ? 1 : 0 ) 
              + (services?.boavista?.counters?.total_debitos_qtd ?? 0) 
              + (services?.boavista?.counters?.protestos_qtd ?? 0)
              + (services?.cenprot?.counters?.total_debitos_qtd ?? 0)
            ),
            status: services?.boavista?.service_status, 
            can_render: Boolean(services?.boavista),
            children:  <TabContentBoavista/>
          },
          {
            key: ServicesEnum.receita_federal.empresas_cpf_cnpj_e_socio.name,
            label: "Participações societárias",
            count: services?.receita_federal?.counters?.empresas_socio_qtd,
            status: services?.receita_federal?.service_status, 
            can_render: Boolean(services?.receita_federal),
            children:  <TabContentPartnership sectionKey={ServicesEnum.receita_federal.empresas_cpf_cnpj_e_socio.name} />
          },
          {
            key: ServicesEnum.receita_federal.filiais_do_cnpj.name,
            label: "Filiais",
            count: services?.receita_federal?.counters?.filiais_qtd,
            status: services?.receita_federal?.service_status, 
            can_render: Boolean(services?.receita_federal && target?.cpf_cnpj?.length > 11),
            children:  <TabContentPartnership sectionKey={ServicesEnum.receita_federal.filiais_do_cnpj.name} />
          },
          {
            key: ServicesEnum.receita_federal.empresarios_individuais_confirmados_do_cpf.name,
            label: "Empressários individuais confirmados",
            count: services?.receita_federal?.counters?.empresarios_individuais_confirmados_qtd,
            status: services?.receita_federal?.service_status, 
            can_render: Boolean(services?.receita_federal && target?.cpf_cnpj?.length <= 11),
            children:  <TabContentPartnership sectionKey={ServicesEnum.receita_federal.empresarios_individuais_confirmados_do_cpf.name} />
          },
          {
            key: ServicesEnum.receita_federal.empresarios_individuais_possiveis_do_cpf.name,
            label: "Possíveis empressários individuais",
            count: services?.receita_federal?.counters?.empresarios_individuais_possiveis_qtd,
            status: services?.receita_federal?.service_status, 
            can_render: Boolean(services?.receita_federal && target?.cpf_cnpj?.length <= 11),
            children:  <TabContentPartnership sectionKey={ServicesEnum.receita_federal.empresarios_individuais_possiveis_do_cpf.name} />
          },
          {
            key: ServicesEnum.receita_federal.grupo_economico_cnpj.name,
            label: "Grupo econômico",
            count: services?.receita_federal?.counters?.grupo_economico_qtd,
            status: services?.receita_federal?.service_status, 
            can_render: Boolean(services?.receita_federal && target?.cpf_cnpj?.length > 11),
            children:  <TabContentReceitaFederal sectionKey={ServicesEnum.receita_federal.grupo_economico_cnpj.name} />,
          },
          {
            key: ServicesEnum.veiculos.veiculos.name,
            label: "Veículos",
            count: services?.veiculos?.counters?.total_veiculos_count,
            status: services?.veiculos?.service_status, 
            can_render: Boolean(services?.veiculos),
            children:  <TabContentVeiculos />,
          },
          {
            key: ServicesEnum.ecosystem.relationships.name,
            label: "Ecossistema",
            count: services?.ecosystem?.counters?.total_relationships_count,
            status: services?.ecosystem?.service_status,
            can_render: Boolean(services?.ecosystem),
            children:  <TabContentEcosystem />,
          },
          {
            key: ServicesEnum.whois.dominios.name,
            label: "Sites",
            count: services?.whois?.counters?.total_dominios_count,
            status: services?.whois?.service_status,
            can_render: Boolean(services?.whois),
            children:  <TabContentWhois />,
          },
          {
            key: ServicesEnum.gnews.noticias.name,
            label: "Notícias",
            count: services?.gnews?.counters?.total_noticias_count,
            status: services?.gnews?.service_status,
            can_render: Boolean(services?.gnews),
            children:  <TabContentGnews />,
          },
          {
            key: ServicesEnum.socialmedia.socialmedias.name,
            label: "Mídias sociais",
            count: services?.socialmedia?.counters?.total_social_medias_count,
            status: services?.socialmedia?.service_status,
            can_render: Boolean(services?.socialmedia),
            children:  <TabContentSocialmedia />,
          },
          {
            key: ServicesEnum.jucesp.empresas.name,
            label: "Atos societários",
            count: services?.jucesp?.counters?.total_empresas_count,
            status: services?.jucesp?.service_status,
            can_render: Boolean(services?.jucesp),
            children:  <TabContentJucesp />,
          },
          {
            key: ServicesEnum.tst.debitos_trabalhistas.name,
            label: "Débitos Trabalhistas",
            count: services?.tst?.counters?.total_processos_count,
            status: services?.tst?.service_status,
            can_render: Boolean(services?.tst),
            children:  <TabContentTST />,
          },
          {
            key: ServicesEnum.inpi.patentes.name,
            label: "Patente",
            count: services?.inpi?.counters?.total_patentes_count,
            status: services?.inpi?.service_status,
            can_render: Boolean(services?.inpi),
            children:  <TabContentPatentes />,
          },
          {
            key: ServicesEnum.inpi.marcas.name,
            label: "Marcas",
            count: services?.inpi?.counters?.total_marcas_count,
            status: services?.inpi?.service_status,
            can_render: Boolean(services?.inpi),
            children:  <TabContentMarcas />,
          },
          {
            key: ServicesEnum.sintegra.sintegra_pf.name,
            label: "Sintegra",
            count: services?.sintegra?.counters?.total_results_count,
            status: services?.sintegra?.service_status,
            can_render: Boolean(services?.sintegra && target?.cpf_cnpj?.length <= 11),
            children:  <TabContentSintegraPF />,
          },
          {
            key: ServicesEnum.sintegra.sintegra_pj.name,
            label: "Sintegra",
            count: services?.sintegra?.counters?.total_results_count,
            status: services?.sintegra?.service_status,
            can_render: Boolean(services?.sintegra && target?.cpf_cnpj?.length > 11),
            children:  <TabContentSintegraPJ />,
          },
          {
            key: ServicesEnum.processos.processos_para_aquisicao_copia.name,
            label: "Processos relevantes",
            count: services?.processos?.counters?.processos_relevantes_qtd,
            status: services?.processos?.service_status,
            can_render: Boolean(services?.processos),
            children:  <TabContentProcessos sectionKey={ServicesEnum.processos.processos_para_aquisicao_copia.name} />,
          },
          {
            key: ServicesEnum.processos.processos_em_tramitacao_por_nome.name,
            label: "Processos por nome (Em tramitação)",
            count: services?.processos?.counters?.data?.processos_ativos?.qtd_por_nome?.total,
            status: services?.processos?.service_status,
            can_render: Boolean(services?.processos),
            children:  <TabContentProcessos sectionKey={ServicesEnum.processos.processos_em_tramitacao_por_nome.name} />,
          },
          {
            key: ServicesEnum.processos.processos_em_tramitacao_por_cpf_cnpj.name,
            label: "Processos por CPF/CNPJ (Em tramitação)",
            count: services?.processos?.counters?.data?.processos_ativos?.qtd_por_cpf_cnpj?.total,
            status: services?.processos?.service_status,
            can_render: Boolean(services?.processos),
            children:  <TabContentProcessos sectionKey={ServicesEnum.processos.processos_em_tramitacao_por_cpf_cnpj.name} />,
          },
          {
            key: ServicesEnum.processos.processos_inativos_por_nome.name,
            label: "Processos por nome (Arquivados)",
            count: services?.processos?.counters?.data?.processos_inativos?.qtd_por_nome?.total,
            status: services?.processos?.service_status,
            can_render: Boolean(services?.processos),
            children:  <TabContentProcessos sectionKey={ServicesEnum.processos.processos_inativos_por_nome.name} />,
          },
          {
            key: ServicesEnum.processos.processos_inativos_por_cpf_cnpj.name,
            label: "Processos por CPF/CNPJ (Arquivados)",
            count: services?.processos?.counters?.data?.processos_inativos?.qtd_por_cpf_cnpj?.total,
            status: services?.processos?.service_status,
            can_render: Boolean(services?.processos),
            children:  <TabContentProcessos sectionKey={ServicesEnum.processos.processos_inativos_por_cpf_cnpj.name} />,
          },
        ]

        lst.sort((a, b) => {
          // Se ambos objetos têm a propriedade "order", compare por "order"
          if (a.order !== undefined && b.order !== undefined) {
            return a.order - b.order;
          }
          // Se apenas um dos objetos tem "order", ele vem primeiro
          if (a.order !== undefined) {
            return -1;
          }
          if (b.order !== undefined) {
            return 1;
          }
          // Se nenhum tem "order", compare por "count" (maior primeiro)
          return (b.count ?? 0) - (a.count ?? 0);
        });

        return lst;
    }, [
        state.status.getTarget, 
        state.status.getServiceCounters, 
        services?.veiculos?.counters,
        services?.tst?.counters,
        services?.whois?.counters,
        services?.socialmedia?.counters,
        services?.processos?.counters,
        services?.receita_federal?.counters,
        services?.ecosystem?.counters,
        services?.jucesp?.counters,
        services?.parecer?.counters,
        services?.boavista?.counters,
        services?.cenprot?.counters,
        services?.gnews?.counters,
        services?.sigef?.counters,
        services?.sintegra?.counters,
        services?.inpi?.counters,
      ]);

    return (
        <div className="target-view-page">
            {state.status.getTarget === StateStatus.succeeded? (
              <>
                <Tabs 
                  defaultActiveKey={activeTab}
                  activeKey={activeTab}
                  onTabClick={(tabKey) => {
                    setActiveTab(tabKey)
                    navigate(`${location.pathname}?tab=${tabKey}`)
                  }}
                  // @ts-ignore
                  items={items.map((x, key) => (x.can_render ? {
                    label: <>{x.label} {x.count !== null ? <BadgeNumber count={x.count} isLoading={x.status === "loading"} /> : null}</>, 
                    children: x.children, 
                    key: x.key
                  } : {}))} 
                  onChange={onChangeReportTab} 
                />
                <div style={{display: "none"}}>{resumoTab}</div>
              </>
            ) : state.status.getTarget === StateStatus.loading ? (
              <div>
                  <div className="loading-text">
                      <Skeleton active />
                  </div>
              </div>
            ) : <></>}
        </div>
    );
}


export { TargetPage }
