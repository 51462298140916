import React, { FunctionComponent, ReactNode } from "react"
import { Select as AntSelect } from "antd"
//
import "./index.scss"

const { Option } = AntSelect



interface OptionType {
  key?: any,
  label: ReactNode,
  value: string | number,
}

interface SelectTypeProps {
  onChange?: (e: any) => void,
  placeholder?: string | undefined,
  defaultValue?: string | undefined | string[],
  options: OptionType[],
  mode?: "multiple" | "tags" | undefined,
}


const Select: FunctionComponent<SelectTypeProps> = ({
  onChange,
  placeholder,
  defaultValue,
  options,
  mode,
  ...props
}) => 
  <AntSelect
    mode={mode}
    value={defaultValue}
    placeholder={placeholder}
    className="simple-select"
    onChange={(value) => onChange(value)}
    {...props}
  >
    {options.map((option, key) => 
      <Option key={key} value={option.value}>
        {option.key || option.label}
      </Option>
    )}
  </AntSelect>

export default Select
