export const beautifyCpfCnpj = (value) => {
  if (!value) return ""

  const final = value.replace(/[.\-/]/g, "")

  if (final.length < 12) {
    return cpfMask(value)
  } else {
    return cnpjMask(value)
  }
}

export const cpfMask = (value) => {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1") // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cnpjMask = (value) => {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1/$2")
    .replace(/(\d{4})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1") // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const formatCurrency = (value: string | number , truncade = false) => {
  // Param. 'value' deve ser fornecido como valor inteiro, caso string, sem pontos e traços. 
  
  value = value?.toString() ?? ""
  if (value.length === 0) return ""
  if (value === "0") return "R$ 0"
  if (value.length <= 2) return `R$ 0,${value}`

  if (truncade && (parseInt(value) / 100) >= 1000) {
    return `R$ ${formatMilhar(parseInt(value) / 100)}`
  }

  const prefixValue = value.slice(0, -2).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  return `R$ ${prefixValue},${value.slice(-2)}`
}

export const numberToReal = (number, truncade = false) => {
  if (!number) {
    number = 0
  }

  if (truncade && number >= 1000) {
    return `R$ ${formatMilhar(number)}`
  } else {
    return number.toLocaleString("pt-RF", {
      style: "currency",
      currency: "BRL",
    })
  }
}

/**
 * Abrevia unidades do número real.
 * Ex.: 14558333,00 | 123699000555,50  | 4058,65 Mil | 3599.00 Mi
 * Resultado: 14 Mi | 123 Bi | 4 Mil | 3.5 Mil
 */
export const formatMilhar = (number) => {
  const milhares = ["Mil", "Mi", "Bi"]

  let label: string | null = null
  let num: string = ""
  number = parseInt(number).toString()

  milhares.forEach((item, index) => {
    if (number.length >= 4 + index * 3) {
      label = item
      // Obtem os 4 à 6 primeiros digitos e add o separador ".":
      // Ex.: 1999888 -> 1.999 | 125999555888 -> 125.999
      num = index === 0 ? number : number.slice(0, index * 3 * -1)
      num = num.replace(/\B(?=(\d{3})+(?!\d))/g, ".")

      if (num.length === 5 && num[2] !== "0")
        // Ex.: 4.558 Mi | 3.699 Bi
        num = num.slice(0, 3)
      // Resultado: 4.5 Mi | 3.6 Bi
      else if (num.length >= 5)
        // Ex.: 14.558 Mi | 123.699 Bi | 4.058 Mil | 3.099 Mi
        num = num.split(".")[0] // Resultado: 14 Mi | 123 Bi | 4 Mil | 3 Mi
    }
  })

  return `${num} ${label}`
}

