import { Tag, Tooltip } from "antd";
import React, { FunctionComponent } from "react";

interface StatusIconProps {
  status: String | null | undefined,
  isAdmUser: Boolean | undefined
}

export const StatusIcon: FunctionComponent<StatusIconProps> = ({status, isAdmUser}) => {
  switch (status) {
    case "completed":
      return <Tag color="green">Completo</Tag>;

    case "released":
      if(isAdmUser) {
        return (
          <Tooltip title="Pré-visualização disponível, mas há pendências.">
            <Tag color="gold">Liberado</Tag>
          </Tooltip>
        )
      } else {
        return (
          <Tooltip title="Aquarde as buscas terminarem.">
            <Tag color="blue">Realizando pesquisa...</Tag>
          </Tooltip>
        );
      }


    case "loading":
      return (
        <Tooltip title="Aquarde as buscas terminarem.">
          <Tag color="blue">Realizando pesquisa...</Tag>
        </Tooltip>
      );
    case "idle":
      return (
        <Tooltip title="A busca já está na fila para ser executada, aguarde.">
          {/* <Tag color="blue">Parado</Tag> */}
          <Tag color="blue">Fila de execução</Tag>
        </Tooltip>
      );
    case "payment_idle":
      return (
        <Tooltip title="Solicitação aguarda método pagamento">
          {/* <Tag color="blue">Parado</Tag> */}
          <Tag color="purple">Aguardando pagamento.</Tag>
        </Tooltip>
      );
    case "waiting_payment":
      return (
        <Tooltip title="Solicitação aguarda pagamento">
          {/* <Tag color="blue">Parado</Tag> */}
          <Tag color="purple">Aguardando pagamento</Tag>
        </Tooltip>
      );
    case "payment_pending":
      return (
        <Tooltip title="">
          {/* <Tag color="blue">Parado</Tag> */}
          <Tag color="purple">Pagamento pendente</Tag>
        </Tooltip>
      );
    case "processing_payment":
      return (
        <Tooltip title="">
          {/* <Tag color="blue">Parado</Tag> */}
          <Tag color="orange">Processando pagamento</Tag>
        </Tooltip>
      );
    case "error":
      if(isAdmUser) {
        return (
          <Tooltip title="Ocorreu um erro ao executar o serviço.">
            <Tag color="red">Erro</Tag>
          </Tooltip>
        )
      } else {
        return (
          <Tooltip title="As buscas requerem atenção do backoffice">
           <Tag color="blue">Realizando pesquisa....</Tag>
          </Tooltip>
        );
      }

    case "canceled":
      return (
        <Tooltip title="Solicitação cancelada.">
          <Tag color="gray">Cancelado</Tag>
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="Não foi possível finalizar as buscas.">
          <Tag color="red">Impedido</Tag>
        </Tooltip>
      );
  }
};

interface StatusPaymentIconProps {
  status: String,
  billingMode: String
}

export const StatusPaymentIcon: FunctionComponent<StatusPaymentIconProps> = ({status, billingMode}) => {
  if (billingMode === "mensal") {
    return <Tooltip title="Pagamento mensal.">-</Tooltip>;
  }

  switch (status) {
    case "idle":
    case undefined:
      return (
        <Tooltip title="Pagamento pendente">
          {/* <Tag color="blue">Parado</Tag> */}
          <Tag color="blue">Pagamento pendente</Tag>
        </Tooltip>
      );
    case "waiting":
      return (
        <Tooltip title="Aguardando a confirmação do pagamento">
          {/* <Tag color="blue">Parado</Tag> */}
          <Tag color="purple">Aguardando pagamento</Tag>
        </Tooltip>
      );
    case "processing":
      return (
        <Tooltip title="O pagamento esta sendo processado">
          {/* <Tag color="blue">Parado</Tag> */}
          <Tag color="orange">Processando pagamento</Tag>
        </Tooltip>
      );
    case "error":
      return (
        <Tooltip title="Ocorreu um erro no pagamento">
          <Tag color="red">Falha no pagamento</Tag>
        </Tooltip>
      );

    case "paid":
      return (
        <Tooltip title="Pago">
          <Tag color="green">Pago</Tag>
        </Tooltip>
      );
    default:
      return <Tooltip title="">{status}</Tooltip>;
  }
};
