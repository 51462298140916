const routes = {
    report: "/report/",
    nfse: "/nfse",
    survey: "/survey",
    surveyBlock: "/survey/block",
    addTargets: "/report/targets",
    service: "/service/",
    serviceTotalizers: "/service/result/totalizers",
    serviceData: "/service/result/data",
    serviceCounters: "/service/result/counters",
    target: "/target/",
    graph: "/service/graph",
    listReportStatus: "/report/status",
    listTargetsStatus: "/target/status",
    login: "/auth/login",
    loginWithToken: "/auth/login-token",
    refreshToken: "/auth/refresh",
    logout: "/auth/logout",
    forgotPassword: "auth/forgot-password",
    changePassword: "auth/change-password",
    createAccount: "user/register",

    //dashboard
    topReports: "dashboard/top-reports?limit=8",
    listReports: "reports",
    listServicesStatus: "services/status",
    listSurveys: "surveys",
    client: "client",
    createClient: "client-register",
    createReport: "/report/create",
    createReportUnauth: "/report/create/opened",
    calcReportPrice: "/report/calc-price",
    clientUser: 'client/user',
    listUsers: 'client/users',
    listClients: 'clients',
    purchase: 'payment/order',
    order: 'payment/order',
    listOrders: 'orders',
}

export default routes