
import React, { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { serviceCases, ItemType } from "../../../../../../common/mocks/services";
import { cities } from "../../../../../../common/mocks/cities";
import { Form, Row, Col, Tooltip, Collapse, Radio, Space } from "antd";
import Select from "../../../../../../common/components/select";
import Icons from "../../../../../../common/components/Icons";
import moment from "moment/moment";
import { cnpj, cpf } from "cpf-cnpj-validator";
import FormNumberList from "../form-number-list";
import FormDefendantList from "../form-defendant-list";
import Input from "../../../../../../common/components/input";
import MaskedInput from "../../../../../../common/components/masked-input";
import InputNumber from "../../../../../../common/components/input-number";
import InputCurrency from "../../../../../../common/components/input-currency";
import { InfoCaseType, mapInfosByCaseType } from "../../../../../../common/mocks/inputs";
import "./styles.scss";
import { FormInstance } from "antd/lib";

interface FormDefendantListProps {
  initialValue?: string | null | undefined, 
  form: FormInstance<any>,
  name: string,
  label?: ReactNode | undefined,
  initialData: any,
  caseType: string,
}


const ContextOptions: FunctionComponent<FormDefendantListProps> = ({ form, name, label, caseType, initialValue, initialData }) => {
  const [selectedContextType, updateContextType] = useState();
  const [hasRequiredContext, updateHasRequiredContext] = useState({opened: false, required: false});
  const [selectedCities, updateSelectedCities] = useState([]);
  // const [productSelected, updateProductSelected] = useState({});
  const [types, updateTypes] = useState<ItemType[]>([]);

  useEffect(() => {
    if (caseType) {
      // const prod = productItems.find((item) => `${item.id}` === caseType);
      // updateProductSelected(prod);
      selectContext(initialValue);

      if (Object.keys(serviceCases).includes(caseType)) {
        updateTypes(serviceCases[caseType]);
      }

      if (initialValue && initialData) {
        form.setFieldValue(initialValue, initialData);
      }
    }
  }, [caseType]);

  const selectContext = (context) => {
    if (context) {
      updateContextType(context);
      const hasRequired =
        mapInfosByCaseType[caseType][context]?.filter(
          (x) => x.required
        )?.length > 0;

      updateHasRequiredContext({ required: hasRequired, opened: hasRequired });
    }
  };

  return (
    <>
      <Row className="row-context-options">
        <Col sm={24} xs={24} md={24}>
          {types && types.length > 0 ? (
            <Form.Item
              label={label}
              name={name}
              rules={[
                {
                  required: true,
                  message: (
                    <div className="normal-12">Selecione um contexto acima</div>
                  ),
                },
              ]}
            >
              <Radio.Group className="group-context-options">
                <Space direction="vertical">
                  {types.map((type) => (
                    <Radio
                      key={`radios_${type.smart_id}`}
                      value={type.smart_id}
                      onChange={(event) => {
                        selectContext(event.target.value)
                      }}
                    >
                      {type.name}

                      {type.label !== null && type.label !== "" && (
                        <Tooltip title={type.label}>
                          <Icons icon="question" />
                        </Tooltip>
                      )}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          ) : (
            <Form.Item
              style={{ display: "none" }}
              initialValue="coringa"
              name={["report", "context"]}
            >
              <Input></Input>
            </Form.Item>
          )}
        </Col>

        <Col sm={24} xs={24} md={24}>
          {caseType &&
            caseType !== "simples" &&
            selectedContextType &&
            mapInfosByCaseType[caseType] &&
            mapInfosByCaseType[caseType][selectedContextType] ? (
            <>
              <div className="ant-col ant-form-item-label">
                <br />
                <div className="modal-step-name">5. Contextualização</div>
              </div>
              <Collapse
                accordion
                // defaultActiveKey={hasRequiredContext ? ["1"] : []}
                activeKey={hasRequiredContext?.opened ? ["1"] : []}
                onChange={(e) => {
                  updateHasRequiredContext({
                    required: hasRequiredContext?.required,
                    opened: !hasRequiredContext?.opened,
                  });
                }}
                items={[
                  {
                    key: "1",
                    label: `Informações do contexto ${hasRequiredContext?.required ? " " : "(opcional)"
                      }`,
                    children: (
                      <>
                        {caseType &&
                          selectedContextType &&
                          mapInfosByCaseType[caseType] &&
                          mapInfosByCaseType[caseType][selectedContextType] && (
                            <>
                              {mapInfosByCaseType[caseType][
                                selectedContextType
                              ].map((item, index) => {
                                return renderDynamicComponent({
                                  form: form,
                                  item: item,
                                  index: index,
                                  selectedContextType: selectedContextType,
                                  cities: cities,
                                  selectedCities: selectedCities,
                                  updateSelectedCities: updateSelectedCities,
                                });
                              })}
                            </>
                          )}
                      </>
                    ),
                  },
                ]}
              />
            </>
          ) : (
            ""
          )}
          {/* mapInfosByCaseType[selectedService][selectedType]*/}
        </Col>
      </Row>
    </>
  );
};

interface getInputProps {
  type?: string | undefined, 
  placeholder: string | undefined, 
  name: string | undefined | string[],
}

const getInput: FunctionComponent<getInputProps> = ({ type, placeholder, name }) => {
  if (type === "text") return <Input placeholder={placeholder} />;

  if (type === "currency") return <InputCurrency placeholder={placeholder} />;

  if (type === "number") return <InputNumber placeholder={placeholder} />;

  if (type === "date")
    return (
      <MaskedInput
        // initialValue={Object.keys(data).includes(name) ? data[name] : null}
        formats={[
          {
            condition: () => true,
            format: "##/##/####",
          },
        ]}
        placeholder={placeholder}
        mask="_"
      />
    );

  if (type === "cpf")
    return (
      <MaskedInput
        formats={[
          {
            condition: () => true,
            format: "###.###.###-##",
          },
        ]}
        placeholder="Insira o CPF"
        mask="_"
      />
    );

  if (type === "cnpj")
    return (
      <MaskedInput
        formats={[
          {
            condition: () => true,
            format: "##.###.###/####-##",
          },
        ]}
        placeholder="Digite seu CNPJ"
        mask="_"
      />
    );

  return <Input placeholder={placeholder} />;
};

const validateDynamicInput = (item, value) => {

  if (item.required) {
    if (!value || value.length < 2)
      return Promise.reject(
        new Error(`Informe o campo: ${item.label.toLowerCase()}`)
      );
  }
  // else {
  //   return Promise.resolve();
  // }

  if (
    item.type === "date" &&
    !moment(value, "YYYY-MM-DD 00:00", true).isValid()
  ) {
    return Promise.reject(new Error("Informe uma  data válida"));
  }

  if (item.type === "cnpj" && !cnpj.isValid(value))
    return Promise.reject(new Error("Informe um CNPJ inválido"));

  if (item.type === "cpf" && !cpf.isValid(value))
    return Promise.reject(new Error("Informe um CPF inválido"));

  return Promise.resolve();
};

interface RenderDynamicComponentProps {
  form: FormInstance<any>,
  item: InfoCaseType,
  index: number,
  selectedContextType: string,
  cities: any[],
  selectedCities: string[],
  updateSelectedCities: (e: any) => void,
  // initialValue: any[], 
  // service: string, 
}


const renderDynamicComponent: FunctionComponent<RenderDynamicComponentProps> = ({
  form,
  item,
  index,
  selectedContextType,
  cities,
  selectedCities,
  updateSelectedCities,
}) => {
  switch (item.element) {
    case "input":
      return (
        <Form.Item
          label={
            <span className="label-tooltip">
              {item.label}
              {item.tooltip && (
                <Tooltip title={item.tooltip}>
                  <Icons icon="question" />
                </Tooltip>
              )}
            </span>
          }
          key={`service_${index}_${selectedContextType}`}
          name={item.name}
          rules={[
            {
              required: item.required ?? false,
              message: <div className="normal-12">{"Campo obrigatório"}</div>,
            },
            () => ({
              validator(_, value) {
                return validateDynamicInput(item, value);
              },
            }),
          ]}
        >
          {getInput({
            type: item.type,
            placeholder: item.placeholder,
            name: item.name,
          })}
        </Form.Item>
      );

    case "form-defendant-list":
      return (
        <FormDefendantList
          context={selectedContextType}
          key={item.element}
          rules={[
            {
              required: item.required ?? false,
              message: <div className="normal-12">{"Campo obrigatório"}</div>,
            },
          ]}
        />
      );

    case "form-number-list":
      return (
        <FormNumberList
          context={selectedContextType}
          key={item.element}
          rules={[
            {
              required: item.required ?? false,
              message: <div className="normal-12">{"Campo obrigatório"}</div>,
            },
          ]}
        />
      );

    case "select":
      return (
        <Form.Item
          label={
            <span className="label-tooltip">
              {item.label}
              {item.tooltip && (
                <Tooltip title={item.tooltip}>
                  <Icons icon="question" />
                </Tooltip>
              )}
            </span>
          }
          name={item.name}
          key={selectedContextType + item.name}
          rules={[
            {
              required: item.required ?? false,
              message: <div className="normal-12">{"Campo obrigatório"}</div>,
            },
          ]}
        >
          <Select placeholder={item.placeholder} options={item.options ?? []} />
        </Form.Item>
      );

    case "select-location":
      return (
        <Form.Item
          name={item.name}
          className="select-location"
          label={
            <span className="label-tooltip">
              Localidade a ser mais focada nas investigações
              <Tooltip title="Localidade a ser mais focada nas investigações (normalmente é a cidade onde se sabe que o investigado está operando ou tem seus principais negócios ou seus principais bens)">
                <Icons icon="question" />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: item.required ?? false,
              message: <div className="normal-12">{"Campo obrigatório"}</div>,
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Selecione a localidade foco"
            defaultValue={selectedCities ?? []}
            onChange={(option) => {
              updateSelectedCities(option ?? [])
            }}
            options={cities.map((city) => {
              return {
                value: `${city.name}-${city.state}`,
                label: `${city.name} - ${city.state}`,
              };
            })}
          />
        </Form.Item>
      );

    default:
      return form.getFieldValue(["report", "context"]);
  }
};

export default ContextOptions;
