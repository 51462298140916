import React, { FunctionComponent, ReactNode } from "react"
import "./styles.scss"
import { ProgressBar } from "../progress-bar"
import { Link } from "react-router-dom"
import { Col, Row } from "antd"
import { FundViewOutlined } from "@ant-design/icons"

interface CardCounterScoreProps {
    title: string, 
    image: ReactNode,
    toLink: string, 
    labelLink: string | undefined | null,
}

const CardCounterImage: FunctionComponent<CardCounterScoreProps> = ({title, image, toLink, labelLink}) => {
    return (
        <div className="card-counter-video">
            <Row justify={"space-between"}>
                <Col>
                    <div className="title">{title}</div>
                </Col>
            </Row>
            <div style={{textAlign: "center"}}>
                {image}
            </div>
            <div className="footer-card">
                <div className="link"><Link to={toLink} target="_self" >{labelLink}</Link></div>
            </div>
        </div>
    )
}

export default CardCounterImage