import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectTargetPageState } from "../../slice";
import { Button, Col, Row, Tooltip } from "antd";
import Icons from "../../../../../../common/components/Icons";
import { TabContainer } from "../../components/tab-container";
import { selectReportPageState } from "../../../report-page/slice";
import { urlToNewTabLink } from "../../../../../../utils/files";
import "./styles.scss";
import { buildFileIconStatus } from "../../../report-page/components/card-report-files";

const RowFile = ({
  idx,
  label,
  status,
  url,
  type_display,
  filename,
  updated_at,
  created_at,
}) => {
  return (
    <Col xs={24} sm={24} md={13} lg={13}>
      <Row
        style={{ fontWeight: "bold" }}
        className="title-section"
        justify={"space-between"}
      >
        <Col span={24} className="file-infos">
          <div className="file-type">
            {idx ? `${idx + 1} - ${type_display}` : type_display}
          </div>
          <div className="file-created-at">
            Atualizado em {updated_at ?? created_at}
          </div>
        </Col>
        <Col span={18}>
          <span className="filename not-overflow">{label ?? ""}</span>
        </Col>
        {status === "completed" ? (
          <Col span={4} className="file-btn">
            <Tooltip title="Baixar arquivo">
              <Button
                type="primary"
                icon={<Icons icon="download" />}
                shape="circle"
                size={"middle"}
                onClick={() => {
                  const link = urlToNewTabLink(url, filename);
                  link.click();
                }}
              />
            </Tooltip>
          </Col>
        ) : (
          <Col span={4} className="file-status">
            {buildFileIconStatus(status)}
          </Col>
        )}
      </Row>
    </Col>
  );
};
const TabContentFileAttachments = () => {
  const state = useSelector(selectTargetPageState);
  const reportState = useSelector(selectReportPageState);

  const files = reportState.data?.files;

  const targetFiles = files?.target_files?.filter(
    (x) => x.cpf_cnpj === state.data.target?.cpf_cnpj
  );
  const reportFiles = files?.report_files?.filter(
    (x) =>
      x.status === "completed" &&
      ["xlsx", "zip_xlsx"].includes(x.type)
  );
  const attachmentFiles = files?.report_files?.filter(
    (x) =>
      x.status === "completed" &&
      !["xlsx", "zip_xlsx", "xlsx_part", "zip_pdf"].includes(x.type)
  );

  const target = state.data.target;

  useEffect(() => {}, []);

  return (
    <div>
      {!["doc", "mapa_calor"].includes(reportState.data.report?.case_type ?? "") ? (
      <TabContainer
        icon={<Icons icon={"clips"} />}
        title={<>Relatório do <strong>pesquisado(a)</strong></>}
        className="container-files-attachment"
      >
        {targetFiles && targetFiles[0]?.xlsx?.status === "completed" ? (
          <Row gutter={[16, 16]}>
            {[targetFiles[0].xlsx].map((item, idx) => (
              <RowFile
                key={idx}
                idx={null}
                label={item.filename}
                status={item?.status}
                url={item.link}
                type_display={"EXCEL"}
                filename={item.filename}
                updated_at={item.updated_at}
                created_at={item.created_at}
              />
            ))}
          </Row>
        ) : null}

        {targetFiles && targetFiles[0]?.pdf.status === "completed" ? (
          <Row gutter={[16, 16]}>
            {[targetFiles[0].pdf].map((item, idx) => (
              <RowFile
                key={idx}
                idx={null}
                label={item.filename}
                status={item.status}
                url={item.link}
                type_display={"PDF"}
                filename={item.filename}
                updated_at={item.updated_at}
                created_at={item.created_at}
              />
            ))}
          </Row>
        ) : targetFiles && targetFiles[0]?.xlsx?.status !== "completed" ? (
          <div style={{ marginTop: "15px", textAlign: "center" }}>
            O relatório não está pronto.
          </div>
        ) : null}
      </TabContainer>
      ) : null}

      {!["doc", "mapa_calor"].includes(reportState.data.report?.case_type ?? "") ? (
      <TabContainer
        icon={<Icons icon={"clips"} />}
        title={<>Relatório com <strong>todos os pesquisados(as)</strong> do caso</>}
        className="container-files-attachment"
      >
        {reportFiles && reportFiles?.length > 0 ? (
          <Row gutter={[16, 16]}>
            {reportFiles.map((item, idx) => (
              <RowFile
                key={idx}
                idx={idx}
                label={item.filename}
                status={item.status}
                url={item.link}
                type_display={["xlsx", "zip_xlsx"].includes(item.type ?? "") ? "EXCEL" : item.type_display}
                filename={item.filename}
                updated_at={item.updated_at}
                created_at={item.created_at}
              />
            ))}
          </Row>
        ) : (
          <div style={{ marginTop: "15px", textAlign: "center" }}>
            O relatório não está pronto.
          </div>
        )}
      </TabContainer>
    ) : null}

      <TabContainer
        icon={<Icons icon={"clips"} />}
        title={
          ["smart_pro"].includes(reportState.data.report!.case_type)
            ? "Escrituras e procurações, imóveis e outros anexos"
            : ["docs", "simples"].includes(reportState.data.report!.case_type)
            ? "Imóveis e outros anexos"
            : ["mapa_calor"].includes(reportState.data.report!.case_type)
            ? "Escrituras e procurações e outros anexos"
            : "Outros anexos"
        }
        className="container-files-attachment"
      >
        {attachmentFiles && attachmentFiles?.length > 0 ? (
          <Row gutter={[16, 16]}>
            {attachmentFiles.map((item, idx) => (
              <RowFile
                key={idx}
                idx={idx}
                label={item.label}
                status={item.status}
                url={item.link}
                type_display={item.type_display}
                filename={item.filename}
                updated_at={item.updated_at}
                created_at={item.created_at}
              />
            ))}
          </Row>
        ) : (
          <div style={{ marginTop: "15px", textAlign: "center" }}>
            Nenhum arquivo foi anexado.
          </div>
        )}
      </TabContainer>
    </div>
  );
};

export { TabContentFileAttachments };
