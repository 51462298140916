

import {
  Col,
  Form,
  Input,
  message,
  Row,
  Skeleton,
  Table,
  Tag,
  Tooltip,
} from "antd";

import "./style.scss";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { HeaderDash } from "../../../../common/components/header";
import React, { useEffect, useState } from "react";
import StateStatus from "../../../../utils/stateStatus";
import { useDispatch, useSelector } from "react-redux";
import {
  editClientThunk,
  getListClientsThunk,
  resetListClientsCase,
  selectListClientsState,
} from "./slice";
import localeValues from "antd/locale/pt_BR";
import { beautifyCpfCnpj } from "../../../../utils/formatters";
import { getProfileThunk } from "../../../login/slice";
import { Link } from "react-router-dom";
import Icons from "../../../../common/components/Icons";
import EditClientModal from "../../components/edit_client_modal";
import { ClientType } from "./interfaces";

const ClientsAdm = () => {
  const dispatch = useDispatch();
  const state = useSelector(selectListClientsState);
  const [client, setClient] = useState({});
  
  const [data, updateData] = useState<ClientType[]>([]);
  const [form] = Form.useForm();

  const renderIcon = (status) => {
    switch (status) {
      case true:
        return (
          <Tag color="green">
            <CheckOutlined />
          </Tag>
        );
      default:
        return (
          <Tag color="red">
            <CloseOutlined />
          </Tag>
        );
    }
  };

  const renderBillingMode = (record) => {
    switch (record.billing_mode) {
      case "pre_pago":
        return <Tag color="blue">{record.billing_mode_display}</Tag>;
      case "pos_pago":
        return <Tag color="orange">{record.billing_mode_display}</Tag>;
      case "mensal":
        return <Tag color="purple">{record.billing_mode_display}</Tag>;
      default:
        return <Tag color="red">{record.billing_mode_display}</Tag>;
    }
  };

  
  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      //   filters: filters(data, "name"),
      //   filterSearch: true,
      //   onFilter: (value, record) => record.name.includes(value),
      width: "10%",
      render: (_, record) => (
        <Row>
          {/* <span style={{fontSize: "14px"}}>{record.case_name}</span> */}
          <Col span={24}>{record.name}</Col>
          <Col span={24} className="info-client">
              <div>{record.email}</div>
              <div>{beautifyCpfCnpj(record.cpf_cnpj)}</div>
              <div>{record.billing_mode_display}</div>
              <div>
                {record.exists_on_monday ? <><Icons icon={"checked-circle"} /> Cadastrado no monday</> : <> <Icons icon={"close-circle"} />Não cadastrado no monday</>}
              </div>
              <div>
                {record.enabled ? <><Icons icon={"checked-circle"} />Ativo</> : <> <Icons icon={"close-circle"} />Não ativo</>}
              </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      responsive: ["lg", "md"],
      width: "15%",
      render: (text) => (text?.length > 0 ? text : "-"),
    },
    {
      title: "CPF/CNPJ",
      dataIndex: "cpf_cnpj",
      responsive: ["lg", "md"],
      width: "15%",
      render: (text) => (beautifyCpfCnpj(text)),
    },

    {
      title: "Cadastrado no Monday?",
      dataIndex: "exists_on_monday",
      responsive: ["lg", "md"],
      width: "5%",
      align: "center",
      render: (text) => renderIcon(text),
    },

    {
      title: "Modo de faturamento",
      dataIndex: "billing_mode_display",
      responsive: ["lg", "md"],
      width: "10%",
      align: "center",
      render: (_, record) => renderBillingMode(record),
    },

    // {
    //   title: "Descontar da carteira?",
    //   dataIndex: "allow_auto_purchases",
    //   responsive: ["lg", "md"],
    //   align: "center",
    //   render: (text) => renderIcon(text),
    // },

    {
      title: "Cliente ativo?",
      dataIndex: "enabled",
      filterSearch: true,
      responsive: ["lg", "md"],
      width: "5%",
      align: "center",
      //   filters: [
      //     {
      //       text: renderIcon(true),
      //       value: true,
      //     },
      //     {
      //       text: renderIcon(false),
      //       value: false,
      //     },
      //   ],
      //   onFilter: (value, record) => record.is_active === value,
      render: (text) => renderIcon(text),
    },
    // {
    //   title: "Saldo carteira",
    //   dataIndex: "wallet_value",
    //   responsive: ["lg", "md"],
    //   align: "center",
    //   render: (value) => formatCurrency(value),
    // },
    {
      title: "Editar",
      dataIndex: "view",
      align: "center",
      width: "5%",
      render: (_, record) => (
        <Row gutter={[16, 16]} justify="center">
          <Col>
            <Tooltip title="Visualizar dados cadastrais e usuários">
              <Link
                to={"#"}
                onClick={() => {
                  setClient(record);
                }}
              >
                <Icons
                  icon={"edit"}
                  className="icon-view"
                  style={{ fontSize: 20 }}
                />
              </Link>
            </Tooltip>
          </Col>
        </Row>
      ),
    },
    {
      title: "Usuários",
      dataIndex: "view",
      align: "center",
      width: "5%",
      render: (_, record) => (
        <Row gutter={[16, 16]} justify="center">
          <Col>
            <Tooltip title="Editar usuários deste cliente">
              <Link to={`/dashboard/users/${record?.id}`}>
                <Icons
                  icon={"add-user"}
                  className="icon-view"
                  style={{ fontSize: 20 }}
                />
              </Link>
            </Tooltip>
          </Col>
        </Row>
      ),
    },
  ];

  const onChangeTable = (pagination, filters, sorter) => {
    // 
  };

  useEffect(() => {
    dispatch(getProfileThunk());
    dispatch(getListClientsThunk({ page: 1, pageSize: 15, filter: null }));
  }, []);

  useEffect(() => {
    if (state.status.editClient === StateStatus.succeeded) {
      message.success("Dados cadastrais salvos com sucesso!");
      dispatch(getProfileThunk());
      dispatch(
        getListClientsThunk({
          page: state.data.pagination.page,
        })
      );
      setClient({});
    }

    if (state.status.editClient === StateStatus.failed) {
      message.error("Não foi possível editar seus dados cadastrais");
    }
  }, [state.status.editClient]);

  //listReports
  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (state.status.listClients) {
      case StateStatus.succeeded: {
        updateData(state.data.clients ?? []);
        break;
      }
      case StateStatus.failed: {
        dispatch(resetListClientsCase());
        console.error(state.errMessage);
        break;
      }
    }
  }, [state.status.listClients]);

  return (
    <>
      <div className="clients-adm-page">
        <HeaderDash />
        <div className="clients-adm-container">
          <div className="info-title-container">
            <h1 className="info-title">Clientes cadastrados</h1>
            <p className="info-text">
              Veja abaixo os clientes cadastrados e usuários atrelados a cada
              cliente.
            </p>
          </div>
          {state.status.listClients === StateStatus.loading ? (
            <div style={{ width: "100%", height: "150px" }}>
              <Skeleton active />
            </div>
          ) : (
            <>
              <div className="users-box">
                <Row justify={"start"}>
                  <Form form={form}>
                    <Col>
                      <Form.Item name={"search"}>
                        <Input.Search
                          // value={state.data.filters.filter}
                          placeholder="Buscar..."
                          loading={false}
                          enterButton={true}
                          allowClear={true}
                          onPressEnter={(event) => {
                            dispatch(
                              getListClientsThunk({
                                page: 1,
                                pageSize: state.data.pagination.pageSize,
                                filter: event.target.value,
                              })
                            );
                          }}
                          onSearch={(value) => {
                            dispatch(
                              getListClientsThunk({
                                page: 1,
                                pageSize: state.data.pagination.pageSize,
                                filter: value,
                              })
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Form>
                </Row>
                <Row>
                  <Col md={24} sm={24}>
                    <Table
                      rowKey={(record) => record.id}
                      columns={columns}
                      dataSource={data}
                      locale={localeValues.Table}
                      onChange={onChangeTable}
                      pagination={{
                        showSizeChanger: false,
                        current: state.data.pagination?.page,
                        pageSize: state.data.pagination?.pageSize,
                        total: state.data.pagination?.totalCount,
                        onChange: (page, pageSize) => {
                          dispatch(
                            getListClientsThunk({
                              page: page,
                              pageSize: pageSize,
                              filter: state.data.filters.filter,
                            })
                          );
                        },
                      }}
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: (event) => {
                            setClient(record);
                          },
                        };
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
      </div>
      {client?.id ? (
        <EditClientModal
          allowEditCpfCnpj={true}
          isAdmUser={true}
          client={client}
          isLoading={state.status.editClient === StateStatus.loading}
          isVisible={true}
          onCancel={() => setClient({})}
          onSave={(values) => {
            dispatch(editClientThunk(values));
          }}
        />
      ) : null}
    </>
  );
};

export default ClientsAdm;
