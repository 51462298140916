import React, { FunctionComponent, useEffect } from "react";
import propTypes from "prop-types";
import { Row, Modal, Result, Col, Card } from "antd";
import {
  BlueSquareButton,
} from "../../../../common/components/button";
import Icons from "../../../../common/components/Icons";
import { resetCardToken, resetGetOrder, resetPurchaseOrder, selectSearchListState } from "../../pages/list_reports/slice";
import { useDispatch, useSelector } from "react-redux";


interface PaymentResultModalProps { 
  onClose: (e?: any) => void,
} 


const PaymentResultModal: FunctionComponent<PaymentResultModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const state = useSelector(selectSearchListState);

  useEffect(() => {
    console.log({order: state.data.order})
  }, [state.data.order?.id]);

  const closeModal = () => {
    dispatch(resetGetOrder());
    dispatch(resetCardToken());
    dispatch(resetPurchaseOrder());
    onClose()
  }

  return (
    <Modal
      className="create-report"
      title={"Solicitar nova pesquisa"}
      open={true}
      onCancel={() => {
        closeModal()
      }}
      footer={[
        // <Row justify={"space-between"} style={{ marginTop: "30px" }}>
        //   <BlueSquareOulinedButton
        //     key="back"
        //     onClick={onClose}
        //     color={"secondary"}
        //   >
        //     Fechar
        //   </BlueSquareOulinedButton>
        //   {status === "payment-error" ? (
        //     <BlueSquareButton
        //       onClick={onRetry}
        //       loading={isLoading}
        //       color={"primary"}
        //     >
        //       Tentar novamente
        //     </BlueSquareButton>
        //   ) : null}
        // </Row>,
      ]}
      width={"900px"}
    >
      <Row justify="center" className="card-payment-success">
        <Col span={24}>
          {state.data.order?.status === "paid" ? (
            <Card className="card-success">
              <Row justify={"center"}>
                <Col span={24}>
                  <Result
                    icon={<Icons icon="checked-circle" />}
                    status="success"
                    title={"O pagamento foi aprovado"}
                    subTitle="Aguarde, as suas buscas estão sendo executadas, em instantes será possível ver resultados da solicitação."
                    extra={[
                      <BlueSquareButton
                        key="console"
                        onClick={() => {
                          closeModal();
                          // navigate("/dashboard/reports");
                        }}
                      >
                        Ver minhas solicitações
                      </BlueSquareButton>,
                    ]}
                  />
                </Col>
              </Row>
            </Card>
          ) : ["waiting", "processing"].includes(state.data.order?.status ?? "") ? (
            <Card className="card-success">
              <Row justify={"center"}>
                <Col span={24}>
                  <Result
                    icon={<Icons icon="clock" />}
                    status="success"
                    title={"O pagamento esta sendo processado..."}
                    subTitle="Aguarde a aprovação do pagamento, clique abaixo para visualizar o status, as buscas serão executadas automaticamente após a aprovação."
                    extra={[
                      <BlueSquareButton
                        key="console"
                        onClick={() => {
                          closeModal();
                          // navigate("/dashboard/reports");
                        }}
                      >
                        Ver minhas solicitações
                      </BlueSquareButton>,
                    ]}
                  />
                </Col>
              </Row>
            </Card>
          ) : (
            <Card className="card-success">
              <Row justify={"center"}>
                <Col span={24}>
                  <Result
                    // icon={<Icons icon="clock" />}
                    status="error"
                    title="Não foi possível realizar o pagamento..."
                    subTitle="Tente novamente utilizando o mesmo método de pagamento ou outros meios."
                    extra={[
                      <BlueSquareButton
                        key="console"
                        onClick={() => {
                          onClose();
                          // navigate("/dashboard/reports");
                        }}
                      >
                        Fechar
                      </BlueSquareButton>,
                    ]}
                  />
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
    </Modal>
  );
};


export default PaymentResultModal;
