import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { listUsers, addUserToClient } from "./service";
import StateStatus from "../../../../utils/stateStatus";
import { AddUserResponse, AddUserToClientThunkArgs, ClientDataType, ListUsersResponse, UserType } from "./interfaces";


// Thunks com tipagem
export const getListUsersThunk = createAsyncThunk<ListUsersResponse, string>(
    'client/list-users',
    async (clientId: string) => {
        const response = await listUsers(clientId)
        return response.data;
    }
);

export const addUserToClientThunk = createAsyncThunk<AddUserResponse, AddUserToClientThunkArgs>(
    'client/add-user',
    async ({ clientId, data }) => {
        data.client_id = clientId;
        const response = await addUserToClient(data)
        return response.data;
    }
);

// Tipos do estado inicial
interface ListUsersState {
    status: {
        listUsers: StateStatus;
        addUserToClient: StateStatus;
    };
    data: {
        client: ClientDataType | null;
        users: UserType[] | null;
    };
    errMessage: string | null;
}

const initialState: ListUsersState = {
    status: {
        listUsers: StateStatus.idle,
        addUserToClient: StateStatus.idle,
    },
    data: {
        client: null,
        users: null,
    },
    errMessage: null,
};

export const listUsersSlice = createSlice({
    name: 'listUsers',
    initialState,
    reducers: {
        resetListUsersCase: (state) => {
            state.status.listUsers = StateStatus.idle;
        },
    },
    extraReducers: (builder) => {
        builder
            // getListUsersThunk
            .addCase(getListUsersThunk.pending, (state) => {
                state.status.listUsers = StateStatus.loading;
            })
            .addCase(getListUsersThunk.fulfilled, (state, action: PayloadAction<ListUsersResponse>) => {
                state.status.listUsers = StateStatus.succeeded;
                state.data.client = action.payload.client;
                state.data.users = action.payload.users;
            })
            .addCase(getListUsersThunk.rejected, (state, action) => {
                state.status.listUsers = StateStatus.failed;
                state.errMessage = action.error.message || null;
            })

            // addUserToClientThunk
            .addCase(addUserToClientThunk.pending, (state) => {
                state.status.addUserToClient = StateStatus.loading;
            })
            .addCase(addUserToClientThunk.fulfilled, (state) => {
                state.status.addUserToClient = StateStatus.succeeded;
            })
            .addCase(addUserToClientThunk.rejected, (state, action) => {
                state.status.addUserToClient = StateStatus.failed;
            });
    },
});

export const { resetListUsersCase } = listUsersSlice.actions;

export const selectListUsersState = (state: { listUsers: ListUsersState }) => state.listUsers;
