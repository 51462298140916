
import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import "./styles.scss"
//@ts-ignore
import { ReactComponent as Triangle } from "../../../../../../common/images/triangle.svg";
  

interface ProgressBarProps {
    pointValue: number | undefined | null, 
    pointMin: number | undefined | null, 
    pointMax: number,
    height?: string,
}

export const ProgressBar: FunctionComponent<ProgressBarProps> = ({ pointValue, pointMin, pointMax, height}) => {
    const [point, setPoint] = useState<number>(0)

    useEffect(() => {
        changePoint()

        window.addEventListener('resize', function(event) {
            changePoint()
        }, true);
    }, [pointValue])

    const changePoint = () => {
        if(pointValue !== undefined && pointValue !== null) {
            const elem = document.querySelector(".progress-bar")
            const elemPoint = document.querySelector(".progress-bar-point")

            const percent = (pointValue / pointMax)
            //@ts-ignore
            const value = (percent * (elem?.offsetWidth ?? 100)) - (elemPoint?.offsetWidth / 2)
            setPoint(value)
        }
    }

    return (
        <div className="progress-bar-container">
            <div className="progress-bar" style={{height: height ?? "auto"}}>
                <div className="progress-bar-point" style={{marginLeft: `${point}px`}} data-point={pointValue} data-min={pointMin} data-max={pointMax}>
                    {/* <div className="score-value">{pointValue}</div> */}
                    <div className="score-point" >
                        <Triangle />
                    </div>
                </div>
            </div>
        </div>
    )
}