import React, { FunctionComponent } from "react"
import "./styles.scss"
import { ProgressBar } from "../progress-bar"
import { Link } from "react-router-dom"
import { Col, Row } from "antd"
import Icons from "../../../../../../common/components/Icons"

interface CardCounterScoreProps {
    title: string, 
    scoreValue: number | undefined | null, 
    toLink: string, 
    labelLink: string | undefined | null,
    isLoading: boolean,
}

const CardCounterScore: FunctionComponent<CardCounterScoreProps> = ({title, toLink, labelLink, isLoading, scoreValue}) => {
    return (
        <div className="card-counter">
            <Row justify={"space-between"}>
                <Col>
                    <div className="title">{title}</div>
                </Col>
                {isLoading ? (
                    <Col>
                        <Icons icon="loading" spin={true}/>
                    </Col>
                ) : null}
            </Row>
            {scoreValue && scoreValue !== 0 ? (<>
                <div>
                    <ProgressBar pointValue={scoreValue ?? 0} pointMin={0} pointMax={1000}/>
                </div>
                <div className="footer-card">
                    <div className="link"><Link to={toLink} target="_self" >{labelLink}</Link></div>
                    <div className="total-value">{scoreValue ?? 0}</div>
                </div>
            </>) : (<>
                <div>Não encontrado</div>
                <div className="footer-card">
                    <div className="link"><Link to={toLink} target="_self" >{labelLink}</Link></div>
                </div>
            </>)}
        </div>
    )
}

export default CardCounterScore