import React, { FunctionComponent } from "react";
import { Col, Tooltip, Row, Select, Skeleton, Button, Divider } from "antd";
import {
  CheckCircleOutlined,
  LoadingOutlined,
  WarningOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import Icons from "../../../../../../common/components/Icons";
import { urlToNewTabLink } from "../../../../../../utils/files";
import { beautifyCpfCnpj } from "../../../../../../utils/formatters";
import { ReportFilesType } from "../../interfaces";
import "./styles.scss"

export const buildFileIconStatus = (status) => {
  switch (status) {
    case "completed":
      return (
        <Tooltip title="Pronto">
          <CheckCircleOutlined className="status-completed" />
        </Tooltip>
      );
    case "error":
      return (
        <Tooltip title="Erro ao criar arquivo">
          <WarningOutlined className="status-error" />
        </Tooltip>
      );
    case "loading":
      return (
        <Tooltip title="Criando arquivo...">
          <LoadingOutlined spin className="status-loading" />
        </Tooltip>
      );
    case "idle":
    default:
      return (
        <Tooltip title="Na fila de criação...">
          <MinusCircleOutlined className="status-idle" />
        </Tooltip>
      );
  }
};


const CardReportFiles: FunctionComponent<{files: ReportFilesType}> = ({ files }) => {
  return (
    <div className="cart-report-files">
      <div>
        <Row className="msg-file-report">
          {!files.has_target_completed ? (
            <div style={{ color: "#d97e00" }}>
              Aguarde algum pesquisado deste caso ter sua busca completa para
              solicitar os arquivos...
            </div>
          ) : !files?.has_file_completed ? (
            <div>
              Aguarde a criação dos arquivos abaixo estarem prontos para
              baixá-los...
            </div>
          ) : (
            <div>
              Enquanto alguns arquivos abaixo estão sendo criados, você pode
              baixar os arquivos que já estão prontos...
            </div>
          )}
        </Row>
      </div>
      <div className="targets-file-info">
        <Row style={{ fontWeight: "bold" }} className="title-section">
          <Col span={16}>PESQUISADO</Col>
          <Col span={4} className="file-status-title">
            {files?.target_files?.filter((x) => x.pdf?.status === "completed")
              ?.length > 0
              ? "PDF"
              : null}
          </Col>
          <Col span={4} className="file-status-title">
            EXCEL
          </Col>
        </Row>
        <div className="targets-file-content">
          {files?.target_files?.map((item, idx) => (
            <Row key={idx} className="row-section">
              <Col span={16} className="file-target-name">
                <div>{item.name ? item.name : " - "}</div>
                <div>
                  <Tooltip
                    title={
                      item.status !== "completed"
                        ? "Busca em andamento..."
                        : "Busca completa"
                    }
                  >
                    <span style={{ marginRight: "10px" }}>
                      {item.status !== "completed" ? (
                        <Icons icon="loading" spin />
                      ) : (
                        <Icons icon="checked-circle" />
                      )}
                    </span>
                  </Tooltip>

                  {beautifyCpfCnpj(item.cpf_cnpj)}
                </div>
              </Col>
              {item.pdf?.status === "completed" ? (
                <Col span={4} className="file-btn">
                  <Tooltip title="Baixar PDF">
                    <Button
                      type="primary"
                      icon={<Icons icon="download" />}
                      shape="circle"
                      size={"small"}
                      onClick={() => {
                        const link = urlToNewTabLink(
                          item.pdf.link,
                          item.pdf.filename,
                        );
                        link.click();
                      }}
                    />
                  </Tooltip>
                </Col>
              ) : (
                <Col span={4} className="file-btn"></Col>
              )}
              {item.xlsx.status !== "completed" ? (
                <Col span={4} className="file-status-icon">
                  {buildFileIconStatus(item.xlsx.status)}
                </Col>
              ) : (
                <Col span={4} className="file-btn">
                  <Tooltip title="Baixar EXCEL">
                    <Button
                      type="primary"
                      icon={<Icons icon="download" />}
                      shape="circle"
                      size={"small"}
                      onClick={() => {
                        const link = urlToNewTabLink(
                          item.xlsx.link,
                          item.xlsx.filename,
                        );
                        link.click();
                      }}
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
          ))}
        </div>
      </div>
      <Divider />
      <div className="report-file-info">
        {files?.report_files
          ?.filter((x) => !x.type?.includes("part"))
          .map((item, idx) => (
            <Row
              style={{ fontWeight: "bold" }}
              key={idx}
              className="title-section"
            >
              <Col span={20}>
                <span className="not-overflow">{item.label ?? ""}</span>
              </Col>
              {item.status !== "completed" ? (
                <Col span={4} className="file-status-icon">
                  {buildFileIconStatus(item.status)}
                </Col>
              ) : (
                <Col span={4} className="file-btn">
                  <Tooltip title="Baixar EXCEL com todos os pesquisados">
                    <Button
                      type="primary"
                      icon={<Icons icon="download" />}
                      shape="circle"
                      size={"small"}
                      onClick={() => {
                        const link = urlToNewTabLink(item.link, item.filename);
                        link.click();
                      }}
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
          ))}
      </div>
      <Divider />
      <div>
        <Row justify={"center"}>
          <Button
            type="primary"
            disabled={files?.zip_all?.status !== "completed"}
            onClick={() => {
              const item = files?.zip_all;
              const link = urlToNewTabLink(item.link, item.filename);
              link.click();
            }}
          >
            {files?.zip_all?.label?.toUpperCase() ??
              "BAIXAR TODOS ARQUIVOS PRONTOS"}
          </Button>
        </Row>
      </div>
    </div>
  );
};

export default CardReportFiles;
