import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { listClients, editClient } from "./service";
import StateStatus from "../../../../utils/stateStatus";
import { ClientsResponse, ClientsState, EditOrCreateClientParams, GetListClientsParams } from "./interfaces";


// Thunk para listar clientes
export const getListClientsThunk = createAsyncThunk(
    'client/clients',
    async ({ page, pageSize, filter }: GetListClientsParams): Promise<ClientsResponse> => {
        const response = await listClients({
            pagination: pageSize ?? 15,
            page,
            filter,
        });

        return {
            pageSize: pageSize ?? 15,
            filter,
            ...response.data,
        };
    }
);

// Thunk para editar cliente
export const editClientThunk = createAsyncThunk(
    'purchaseCart/editClient',
    async (data: EditOrCreateClientParams) => {
        const { id, ...rest } = data;
        return editClient(id, rest);
    }
);

// Estado inicial
const initialState: ClientsState = {
    status: {
        listClients: StateStatus.idle,
        editClient: StateStatus.idle,
    },
    data: {
        clients: null,
        pagination: {
            page: 0,
            totalCount: 0,
            totalPages: 1,
            pageSize: 1,
        },
        filters: {
            filter: "",
        },
    },
    errMessage: null,
};

// Slice para gerenciamento do estado
export const listClientsSlice = createSlice({
    name: 'listClients',
    initialState,
    reducers: {
        resetListClientsCase: (state) => {
            state.status.listClients = StateStatus.idle;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListClientsThunk.pending, (state) => {
                state.status.listClients = StateStatus.loading;
            })
            .addCase(getListClientsThunk.fulfilled, (state, { meta, payload }) => {
                state.data.pagination.totalCount = payload.pagination.total_count;
                state.data.pagination.totalPages = payload.pagination.total_pages;
                state.data.pagination.pageSize = meta.arg.pageSize;
                state.data.pagination.page = payload.pagination.current_page;
                state.data.filters.filter = meta.arg.filter;
                state.data.clients = payload.items;

                state.status.listClients = StateStatus.succeeded;
            })
            .addCase(getListClientsThunk.rejected, (state, action) => {
                state.status.listClients = StateStatus.failed;
                state.errMessage = action.error.message ?? null;
            })
            // editClientThunk
            .addCase(editClientThunk.pending, (state) => {
                state.status.editClient = StateStatus.loading;
            })
            .addCase(editClientThunk.fulfilled, (state) => {
                state.status.editClient = StateStatus.succeeded;
            })
            .addCase(editClientThunk.rejected, (state, action) => {
                state.status.editClient = StateStatus.failed;
                state.errMessage = action.error.message ?? null;
            });
    },
});

// Exportação das ações e seletores
export const { resetListClientsCase } = listClientsSlice.actions;
export const selectListClientsState = (state: { listClients: ClientsState }) => state.listClients;
