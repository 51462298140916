import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { Col, Row } from "antd";
import { TabContainer } from "../../components/tab-container";
import "./styles.scss";
import CardCounter from "../../components/card-counter";

import { useLocation } from "react-router";
import CardCounterImage from "../../components/card-video";
import { selectReportPageState } from "../../../report-page/slice";
import Icons from "../../../../../../common/components/Icons";

const TabContentResumoDocs = () => {
  const reportState = useSelector(selectReportPageState);
  const location = useLocation();

  const buildLink = (tabKey) => {
    return `${location.pathname}?tab=${tabKey}`;
  };

  const columns: any[] = useMemo(() => [
    {
      title: <>Orientações</>,
      can_render: true,
      render: () => {
        return (
          <Row gutter={[16, 16]} justify="start">
            <Col xs={24} sm={24} md={8} lg={8}>
              <CardCounterImage
                title={"Como ler a pesquisa?"}
                image={<Icons icon="orientations" />}
                toLink={buildLink("orientacoes")}
                labelLink={"Ver tudo"}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: <>Registro de bens e documentos jurídicos</>,
      can_render: true,
      render: () => {
        return (
          <Row gutter={[16, 16]} justify="start">
            {["docs"].includes(reportState.data.report?.case_type ?? "") && (
              <Col xs={24} sm={24} md={8} lg={8}>
                <CardCounter
                  title={"Imóveis"}
                  qtd={null}
                  isLoading={false}
                  toLink={buildLink("attachments")}
                  labelLink={"Ver anexos"}
                  currencyValue={null}
                />
              </Col>
            )}
            {["mapa_calor"].includes(
              reportState.data.report?.case_type ?? ""
            ) && (
              <Col xs={24} sm={24} md={8} lg={8}>
                <CardCounter
                  title={"Escrituras e procurações"}
                  qtd={null}
                  isLoading={false}
                  toLink={buildLink("attachments")}
                  labelLink={"Ver anexos"}
                  currencyValue={null}
                />
              </Col>
            )}
          </Row>
        );
      },
    },
  ], [reportState.data.report?.id, reportState.data.files]);

  return (
    <TabContainer>
      <Row justify={"center"}>
        {columns.map((item, idx) => (
          <>
            {item.can_render && item?.render ? (
              <Col xs={24} sm={24} md={16} lg={16} className="row-counters">
                <div className="title">{item?.title}</div>
                <div className="content">{item?.render()}</div>
              </Col>
            ) : null}
            <br />
          </>
        ))}
      </Row>
    </TabContainer>
  );
};

export { TabContentResumoDocs };
