
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDataThunk, selectTargetPageState } from "../../slice";
import { Col, Row, Table } from "antd";
import localeValues from "antd/locale/pt_BR";
import StateStatus from "../../../../../../utils/stateStatus";
import Icons from "../../../../../../common/components/Icons";
import { TabContainer } from "../../components/tab-container";
import { ServicesEnum } from "../../../../../../utils/services";

const TabContentVeiculos = () => {
  const serviceKey = ServicesEnum.veiculos.name;
  const sectionKey = ServicesEnum.veiculos.name;
  const state = useSelector(selectTargetPageState);
  const dispatch = useDispatch();

  const serviceData = state.data.target?.services ? state.data.target?.services[serviceKey] : null;
  const sectionData = serviceData?.sections && serviceData.sections[sectionKey] ? serviceData.sections[sectionKey] : null;

  useEffect(() => {
    if (serviceData?.dossier_id && serviceData?.service_status === "completed" && !sectionData?.data?.pagination?.current_page) {
      dispatch(
        getServiceDataThunk({
          service: serviceKey,
          section: sectionKey,
          dossierId: serviceData?.dossier_id,
          page: 1,
        })
      );
    }
  }, [serviceData?.dossier_id]);


  const columns = [
    {
      title: "PLACA",
      dataIndex: "placa",
    },
    {
      title: "MODELO",
      dataIndex: "modelo",
    },
    {
      title: "UF",
      dataIndex: "uf",
    },
    {
      title: "RENAVAM",
      dataIndex: "renavam",
    },
    {
      title: "ANO",
      dataIndex: "ano",
    },
    {
      title: "TIPO VEICULO",
      dataIndex: "tipo_veiculo",
    },
    {
      title: "OCORRENCIAS E RESTRICÕES",
      dataIndex: "created_at",
      render: (_, record) => {
        return (
          <>
            {record.ocorrencia}
            {record.ocorrencia && record.restricao ? <br /> : null}
            {record.restricao}
          </>
        );
      },
    },
    {
      title: "VALOR MÉDIO",
      dataIndex: "fipe",
    },
  ];

  return (
    <TabContainer
      icon={<Icons icon={"veiculos"} />}
      title={"Veículos"}
    >
      <Row>
        <Col span={24}>
          <div className="service-label-value inline">
            <label>Total de veículos</label>
            <div>{sectionData?.data?.pagination?.total_items ?? "-"}</div>
          </div>
        </Col>
        <Col span={24}>
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={sectionData?.data?.items ?? []}
            loading={sectionData?.status === StateStatus.loading}
            pagination={{
              showSizeChanger: false,
              current: sectionData?.data?.pagination?.current_page,
              pageSize: sectionData?.data?.pagination?.per_page,
              total: sectionData?.data?.pagination?.total_items,
              onChange: (page, pageSize) => {
                dispatch(
                  getServiceDataThunk({
                    service: serviceKey,
                    dossierId: serviceData.dossier_id,
                    section: sectionKey,
                    page: page,
                  })
                );
              },
            }}
            locale={{emptyText: 'Este pesquisado(a) não possui veículos'}}
          />
        </Col>
      </Row>
    </TabContainer>
  );
};

export { TabContentVeiculos };
