import React, { FunctionComponent, MouseEventHandler, ReactNode } from "react"
import PropTypes from "prop-types"
// 
import "./index.scss"
import { Spin, Button as AntdButton } from "antd"
import {
  LoadingOutlined
} from "@ant-design/icons"

interface ButtonProps {
  children?: ReactNode, 
  isSubmit?: boolean | undefined, 
  loading?: boolean | undefined, 
  className?: string | undefined, 
}

const Button: FunctionComponent<ButtonProps> = ({ children, isSubmit, loading, className, ...props }) => (
  <button type={isSubmit ? "submit" : "button"} className={`simple-button ${className ?? ''}`} {...props}>
    {!loading
      ? children
      : <Spin />
    }
  </button>
)

interface RoudedButtonProps {
  children?: ReactNode, 
  disabled?: boolean | undefined, 
  loading?: Boolean | undefined, 
  className?: String, 
  onClick?: MouseEventHandler<HTMLElement> | undefined,
  icon?: ReactNode,
}

const RoudedButton: FunctionComponent<RoudedButtonProps> = ({ loading, disabled, onClick, icon, className, children, ...props }) => {
  return (
    <AntdButton
      className={`rounded-button ${className ?? ""}`}
      disabled={disabled}
      onClick={onClick}
      icon={loading ? <LoadingOutlined /> : icon}
      {...props}
    >
      {children}
    </AntdButton>
  )
}

interface BlueSquareButtonProps {
  children?: ReactNode, 
  disabled?: boolean | undefined, 
  isLoading?: Boolean | undefined, 
  onClick?: MouseEventHandler<HTMLElement> | undefined,
  htmlType?: string | undefined,
}

const BlueSquareButton: FunctionComponent<BlueSquareButtonProps> = ({ isLoading, children, onClick, disabled }) => {
  return (
    <button onClick={onClick} disabled={disabled} className="blue-square-button">
      <div>
        {isLoading ? <LoadingOutlined /> : null}
        {children}
      </div>
    </button>
  )
}


interface BlueSquareButtonProps {
  children?: ReactNode, 
  disabled?: boolean | undefined, 
  isLoading?: Boolean | undefined, 
  onClick?: MouseEventHandler<HTMLElement> | undefined,
  type?: "submit" | "button" | "reset" | undefined
}

const BlueSquareSmallButton: FunctionComponent<BlueSquareButtonProps> = ({ isLoading, children, onClick, disabled, ...props }) => {
  return (
    <button onClick={onClick} disabled={disabled} className="blue-square-small-button" {...props}>
      <div>
        {isLoading ? <LoadingOutlined /> : null}
        {children}
      </div>
    </button>
  )
}

interface BlueSquareOulinedButtonProps {
  children?: ReactNode, 
  disabled?: boolean | undefined, 
  isLoading?: Boolean | undefined, 
  onClick?: MouseEventHandler<HTMLElement> | undefined,
}

const BlueSquareOulinedButton: FunctionComponent<BlueSquareOulinedButtonProps> = ({ isLoading, children, onClick, disabled }) => {
  return (
    <button onClick={onClick} disabled={disabled} className="blue-square-outlined-button">
      <div>
        {isLoading ? <LoadingOutlined /> : null}
        {children}
      </div>
    </button>
  )
}

interface BlueSquareOulinedSmallButtonProps {
  children?: ReactNode, 
  disabled?: boolean | undefined, 
  isLoading?: Boolean | undefined, 
  onClick?: MouseEventHandler<HTMLElement> | undefined,
}

const BlueSquareOulinedSmallButton: FunctionComponent<BlueSquareOulinedSmallButtonProps> = ({ isLoading, children, onClick, disabled }) => {
  return (
    <button onClick={onClick} disabled={disabled} className="blue-square-outlined-small-button">
      <div>
        {isLoading ? <LoadingOutlined /> : null}
        {children}
      </div>
    </button>
  )
}

interface PlainButtonProps {
  children?: ReactNode, 
  disabled?: boolean | undefined, 
  loading?: Boolean | undefined, 
  onClick?: MouseEventHandler<HTMLElement> | undefined,
  className?: String, 
  icon?: ReactNode,
  color: "primary" | "secondary",
  format: "square" | "rounded",
}

const PlainButton: FunctionComponent<PlainButtonProps> = ({ loading, disabled, onClick, icon, className, children, color, format, ...props }) => {
  const _colorClass = color ? `color-${color}` : ''
  const _fmtClass = format ? `fmt-${format}` : ''

  return (
    <AntdButton
      className={`plain-button ${className ?? ""} ${_colorClass} ${_fmtClass}`}
      disabled={disabled}
      onClick={onClick}
      icon={loading ? <LoadingOutlined /> : icon}
      {...props}
    >
      {children}
    </AntdButton>
  )
}

Button.defaultProps = {
  isSubmit: true,
  loading: false,
}

Button.propTypes = {
  loading: PropTypes.bool,
  isSubmit: PropTypes.bool,
  children: PropTypes.string.isRequired,
}


RoudedButton.defaultProps = {
  disabled: false,
  loading: false,
}

RoudedButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.any,
  children: PropTypes.string.isRequired,
}

PlainButton.defaultProps = {
  disabled: false,
  loading: false,
}

PlainButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.any,
  children: PropTypes.string.isRequired,
}

BlueSquareButton.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.string.isRequired,
}

BlueSquareOulinedButton.prototype = {
  isLoading: PropTypes.bool,
  children: PropTypes.string.isRequired,
}

BlueSquareOulinedSmallButton.prototype ={
  isLoading: PropTypes.bool,
  children: PropTypes.string.isRequired,
}

BlueSquareSmallButton.prototype ={
  isLoading: PropTypes.bool,
  children: PropTypes.string.isRequired,
}



export default Button
export { RoudedButton, PlainButton, BlueSquareButton, BlueSquareOulinedButton, BlueSquareOulinedSmallButton, BlueSquareSmallButton }
