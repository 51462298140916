
import React, { useEffect, useState } from "react"
import "./styles.scss"
//@ts-ignore
import { ReactComponent as Triangle } from "../../../../../../common/images/triangle.svg";
  
export const ParecerRatioBar = ({ pointValue, pointMin, pointMax, className}) => {
    const [point, setPoint] = useState<number>(0)

    useEffect(() => {
        changePoint()
        
        window.addEventListener('resize', function(event) {
            changePoint()
        }, true);
    }, [pointValue])

    const changePoint = () => {
        if(pointValue !== undefined && pointValue !== null) {
            const elem = document.querySelector(".parecer-ratio-bar")
            const elemPoint = document.querySelector(".parecer-bar-point")

            const percent = (pointValue / pointMax)
            //@ts-ignore
            const value = (percent * (elem?.offsetWidth ?? 100)) - (elemPoint?.offsetWidth / 2)
            setPoint(value)
        }
    }

    return (
        <div className={`parecer-ratio-bar-container ${className ?? ''}`}>
            <div className="parecer-ratio-bar">
                <div className="parecer-bar-point" data-point={pointValue} style={{marginLeft: `${point}px`}} data-min={pointMin} data-max={pointMax}>
                    <div className="bar-point">
                        <Triangle />
                    </div>
                </div>
            </div>
        </div>
    )
}