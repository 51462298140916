import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import StateStatus from "../../utils/stateStatus";
import { addTargets, getReport } from "./service";
import { AddTargetsState } from "./interfaces";
import { GetReportResponse } from "../dashborad/pages/report-page/interfaces";

// Estado inicial
const initialState: AddTargetsState = {
    status: {
        addTarget: StateStatus.idle,
        getReport: StateStatus.idle,
    },
    data: {
        report: null,
    },
    errMessage: null,
};

// Função assíncrona para adicionar targets
export const addTargetsThunk = createAsyncThunk<GetReportResponse, any>(
    "add-targets",
    async (data) => {
        const response = await addTargets(data);
        return response.data;
    }
);

// Função assíncrona para obter um relatório
export const getReportThunk = createAsyncThunk<GetReportResponse, string>(
    "add-targets/get-report",
    async (reportId) => {
        const response = await getReport(reportId);
        return response.data;
    }
);

export const addTargetsSlice = createSlice({
    name: "add-targets",
    initialState,
    reducers: {
        clearReport: (state) => {
            state.data.report = null;
            state.status.getReport = StateStatus.idle;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addTargetsThunk.pending, (state) => {
                state.status.addTarget = StateStatus.loading;
            })
            .addCase(addTargetsThunk.fulfilled, (state, action) => {
                state.status.addTarget = StateStatus.succeeded;
                state.data.report = action.payload as GetReportResponse;
            })
            .addCase(addTargetsThunk.rejected, (state) => {
                state.status.addTarget = StateStatus.failed;
                state.errMessage = "Erro ao adicionar novos targets ao caso.";
            })

            .addCase(getReportThunk.pending, (state) => {
                state.status.getReport = StateStatus.loading;
            })
            .addCase(getReportThunk.fulfilled, (state, action) => {
                state.status.getReport = StateStatus.succeeded;
                state.data.report = action.payload;
            })
            .addCase(getReportThunk.rejected, (state) => {
                state.status.getReport = StateStatus.failed;
                state.data.report = null;
                state.errMessage = "Erro ao buscar informações do caso.";
            });
    },
});

export const { clearReport } = addTargetsSlice.actions;
export const selectAddTargetsState = (state: { addTargets: AddTargetsState }) => state.addTargets;
