
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDataThunk, selectTargetPageState } from "../../slice";
import { Col, Row, Table } from "antd";
import localeValues from "antd/locale/pt_BR";
import StateStatus from "../../../../../../utils/stateStatus";
import Icons from "../../../../../../common/components/Icons";
import { TabContainer } from "../../components/tab-container";
import { ServicesEnum } from "../../../../../../utils/services";
import { ProgressBar } from "../../components/progress-bar";
import { ParecerRatioBar } from "../../components/parecer-ratio-bar";

const TabContentParecer = () => {
  const serviceKey = ServicesEnum.parecer.name;
  const sectionKey = ServicesEnum.parecer.parecer.name;
  const state = useSelector(selectTargetPageState);
  const dispatch = useDispatch();

  const serviceData = state.data.target?.services ? state.data.target?.services[serviceKey] : null;
  const sectionData = serviceData?.sections && serviceData.sections[sectionKey] ? serviceData.sections[sectionKey] : null;
  const parecer = sectionData?.data?.items?.length > 0  ? sectionData?.data?.items[0] : null

  useEffect(() => {
    if (serviceData?.dossier_id && serviceData?.service_status === "completed" && !sectionData?.data?.pagination?.current_page) {
      dispatch(
        getServiceDataThunk({
          service: serviceKey,
          section: sectionKey,
          dossierId: serviceData?.dossier_id,
          page: 1,
        })
      );
    }
  }, [serviceData?.dossier_id]);


  const renderComponent = (data, key) => {
    switch(data.type) {
      case "title":
        // return <div key={key}>{data.text}</div>;
        return <></>
      case "ul":
        return <ul key={key}>{data.values.map((item, idx) => renderComponent(item, idx))}</ul>
      case "li":
        return <li key={key}>{data.values.map((item, idx) => renderComponent(item, idx))}</li>
      case "paragraph":
        return <p key={key}>{data.values.map((item, idx) => renderComponent(item, idx))}</p>
      case "text":
        if(data.bold) {
          return (
            <span style={{color: data.styles?.color ?? "black" }}><strong>
              {data.text}{" "}
            </strong></span>
          )
        } else if(data.styles) {
          return (
            <span style={{color: data.styles?.color ?? "black" }}>
              {data.text}{" "}
            </span>
          )
        }
        return <>{data.text}{" "}</>;
      case "breackline":
        return <br key={key}/>;
      case "progress-bar":
        return <ParecerRatioBar className={data.class_} pointValue={data.ratio} pointMin={0} pointMax={100}/>
      default:
        return JSON.stringify(data);
    }
  }


  return (
    <TabContainer
      icon={<Icons icon="parecer" />}
      title={"Proposta de parecer"}
    >
      <Row justify={"center"}>
        {parecer ? (
        <Col xs={20} sm={24} md={12} lg={12}>
          {parecer.content.map((item, idx) => renderComponent(item, idx))}
        </Col>
        ) : null}
      </Row>
    </TabContainer>
  );
};

export { TabContentParecer };
