
import React, { FunctionComponent } from "react"
// import PropTypes from "prop-types"
import MaskedInput from "antd-mask-input";
// 
import "../input/index.scss"
import { MaskedInputProps } from "antd-mask-input/build/main/lib/MaskedInput";
import { InputRef } from "antd";



const MaskInput: React.FunctionComponent<MaskedInputProps & React.RefAttributes<InputRef>> = (props) => {

	const change = (event) => {
		const onlyNumbers = event.target.value.replace(/\D/g, "");
		if (props.onChange) {
			props.onChange(onlyNumbers)
		}
	}
	
	return (
		<MaskedInput {...props} className="simple-input simple-input-mask" onChange={change}/>
	)
}


export default MaskInput
