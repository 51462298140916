import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
//
import { Form, FormRule, Input } from "antd";
// import InputNumber from "../../../../../../common/components/input-number"
//
import "./index.scss";
import { Rule, RuleRender } from "antd/es/form";

interface FormDefendantListProps {
  initialValue?: string[], 
  context: string,
  rules: any[],
}

const FormNumberList: FunctionComponent<FormDefendantListProps> = ({ initialValue, context, rules }) => {
  return (
    <div className="form-list">
      <p className="form-list-title">
        Insira o número do processos seguindo o padrão NUP do CNJ (se for o
        caso)
      </p>

      <Form.List
        name={[context, "processes"]}
        initialValue={initialValue}
        rules={[
          ...rules,
          //     () => ({
          //         validator(_, values) {
          //             if (values === null || values === undefined || values === "undefined" || values.length < 1) {
          //                 return Promise.reject(new Error("Adicione os processos"))
          //             }
          //             return Promise.resolve()
          //         },
          //     }),
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field, index) => {
                return (
                  <div
                    className={`fields-edit ${index > 0 ? "border" : ""}`}
                    key={field.key}
                  >
                    <Form.Item
                      label="Número do processo"
                      name={[index, "number"]}
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (
                              value === undefined ||
                              value === null ||
                              value < 1
                            ) {
                              return Promise.reject(
                                new Error("Informe um número válido")
                              );
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input placeholder="Insira o número" />
                    </Form.Item>

                    {fields.length >= 1 && (
                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className="button-remove"
                      >
                        Remover
                      </button>
                    )}
                  </div>
                );
              })}

              <Form.Item>
                <button
                  type="button"
                  onClick={() => add()}
                  className="button-add"
                >
                  Adicionar
                </button>
              </Form.Item>

              <Form.ErrorList className="list-error" errors={errors} />
            </>
          );
        }}
      </Form.List>
    </div>
  );
};


export default FormNumberList;
