import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDataThunk, selectTargetPageState } from "../../slice";
import { Col, Pagination, Row, Table } from "antd";
import { TabContainer } from "../../components/tab-container";
import { ServicesEnum } from "../../../../../../utils/services";
// @ts-ignore
import StateStatus from "../../../../../../utils/stateStatus";
import Icons from "../../../../../../common/components/Icons";
import { LoadingSkeleton } from "../../../../../../common/components/loading";

const TabContentJucesp = () => {
  const serviceKey = ServicesEnum.jucesp.name;
  const sectionKey = ServicesEnum.jucesp.empresas.name;

  const state = useSelector(selectTargetPageState);
  const dispatch = useDispatch();

  const serviceData = state.data.target?.services
    ? state.data.target?.services[serviceKey]
    : null;
  const sectionData =
    serviceData?.sections && serviceData.sections[sectionKey]
      ? serviceData.sections[sectionKey]
      : null;
  const items = sectionData?.data?.items;

  useEffect(() => {
    if (
      serviceData?.dossier_id &&
      serviceData?.service_status === "completed" &&
      !sectionData?.data?.pagination?.current_page
    ) {
      dispatch(
        getServiceDataThunk({
          service: serviceKey,
          section: sectionKey,
          dossierId: serviceData?.dossier_id,
          page: 1,
        })
      );
    }
  }, [serviceData?.dossier_id]);

  useEffect(() => {
    if (sectionData?.status === StateStatus.succeeded) {
      const serviceData = state.data.target?.services[serviceKey] ?? null;
      
      items?.map((item, idx) => {
        if (item?.arquivamentos === undefined) {
          dispatch(
            getServiceDataThunk({
              service: serviceKey,
              section: ServicesEnum.jucesp.arquivamentos.name,
              filterBy: { jucesp_id: item.id },
              dossierId: serviceData?.dossier_id,
              page: 1,
              subItemsMap: {
                sectionKey: ServicesEnum.jucesp.empresas.name,
                itemIndex: idx,
                itemKey: "arquivamentos",
              },
            })
          );
        }
      });
    }
  }, [sectionData?.status]);

  const columns = [
    {
      title: "CAPITAL SOCIAL",
      dataIndex: "capital",
    },
    {
      title: "NIRE",
      dataIndex: "empresa.nire",
    },
    {
      title: "INSCRIÇÃO ESTADUAL",
      dataIndex: "empresa.inscricao_estadual",
    },
    {
      title: "INÍCIO ATIVIDADES",
      dataIndex: "empresa.inicio_atividades",
    },
    {
      title: "TIPO",
      dataIndex: "empresa.tipo",
    },
    {
      title: "DATA CONSTITUIÇÃO",
      dataIndex: "empresa.data_constituicao",
    },
    {
      title: "PARTICIPANTES",
      dataIndex: "participantes",
      render: (values, record) => {
        return (
          <ul>
            {values?.map((value, idx) => (
              <li key={idx}>{value}</li>
            ))}
          </ul>
        );
      },
    },
    {
      title: "OBJETO SOCIAL",
      dataIndex: "objeto_social",
      render: (values, record) => {
        return (
          <ul>
            {values?.map((value, idx) => (
              <li key={idx}>{value}</li>
            ))}
          </ul>
        );
      },
    },
  ];

  const columnsarquivamentos = [
    {
      title: "CAPITAL SOCIAL",
      dataIndex: "capital_social_formatted",
    },
    {
      title: "DATA",
      dataIndex: "data",
    },
    // {
    //     title: "DESCRIÇÃO DO ATO",
    //     dataIndex: "descricao_do_ato",
    //     render: (value, record) => {
    //         return <div>{value ?? "-"}</div>
    //     },
    // },
    {
      title: "NOME DA PESSOA JURÍDICA",
      dataIndex: "nome_da_pessoa_juridica",
      render: (value, record) => {
        return <div>{value ?? "-"}</div>;
      },
    },
    // {
    //     title: "RESUMO ANÁLISE",
    //     dataIndex: "resumo_analise",
    // },
    {
      title: "DESCRIÃO DO ATO",
      width: "50%",
      dataIndex: "descricao_do_ato",
    },
  ];

  function getValueByKey(key, obj) {
    return key.split(".").reduce((o, i) => (o ? o[i] : undefined), obj);
  }

  function maskPhone(ddd, phone) {
    if (!ddd || ddd === "0000" || ddd === "0") {
      return null;
    }
    if (phone.length === 8) {
      return `(${ddd}) ${phone.slice(0, 4)}-${phone.slice(4)}`;
    }

    return `(${ddd}) ${phone.slice(0, 5)}-${phone.slice(5)}`;
  }

  function formatAddress({ city, cep, logradouro, bairro, uf, numero }) {
    return `${logradouro}, ${numero ?? ""} ${bairro}. ${city} - ${uf}. ${
      cep ?? ""
    } `;
  }

  return (
    <TabContainer
      icon={<Icons icon={"atos-societarios"} />}
      title={"Atos societários"}
    >
      {sectionData?.status === StateStatus.loading ? (
        <LoadingSkeleton />
      ) : sectionData?.status === StateStatus.succeeded &&
        sectionData?.data?.pagination?.total_items > 0 ? (
        <>
          {items.map((company: any, companyIndex) => {
            return (
              <Row key={company.cnpj}>
                <Col xs={24} sm={24} md={16} lg={16}>
                  <Row align={"middle"}>
                    <div>
                      <div
                        style={{
                          width: 0,
                          height: 0,
                          borderTop: "10px solid transparent",
                          borderBottom: "10px solid transparent",
                          borderLeft: "15px solid #007AFF",
                          marginRight: "10px",
                          display: "inline-block",
                        }}
                      ></div>
                      <span style={{ fontWeight: "500", fontSize: "20px" }}>
                        {getValueByKey("empresa.nome", company)} -{" "}
                        {getValueByKey("empresa.cnpj", company)}
                      </span>
                    </div>
                  </Row>
                  <br />
                  <Row>
                    {columns.map((item, idx) => (
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <div className="service-label-value">
                          <label>{item?.title}</label>
                          {item?.render ? (
                            item?.render(
                              getValueByKey(item?.dataIndex, company),
                              company
                            )
                          ) : (
                            <div>
                              {getValueByKey(item?.dataIndex, company) ?? "-"}
                            </div>
                          )}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <Row>
                    <Col span={24}>
                      <div className="service-label-value">
                        <label>ENDEREÇO</label>
                        <div>
                          {company.endereco &&
                            formatAddress({
                              city: company.endereco.municipio,
                              cep: company.endereco.cep,
                              logradouro: company.endereco.logradouro,
                              bairro: company.endereco.bairro,
                              uf: company.endereco.uf,
                              numero: company.endereco.numero,
                            })}
                        </div>
                        {company.endereco ? (
                          <iframe
                            style={{
                              border: "none",
                              width: "320px",
                              height: "200px",
                            }}
                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBloQgYAwgxRmK4wPpBIlyI-u5dxL7DaJc&q=${formatAddress(
                              {
                                city: company.endereco.municipio,
                                cep: company.endereco.cep,
                                logradouro: company.endereco.logradouro,
                                bairro: company.endereco.bairro,
                                uf: company.endereco.uf,
                                numero: company.endereco.numero,
                              }
                            )}&zoom=16 allowfullscreen`}
                          />
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div className="service-label-value">
                    <label>arquivamentos</label>
                    <div>
                      <Table
                        columns={columnsarquivamentos}
                        loading={
                          serviceData?.section &&
                          serviceData?.section[
                            ServicesEnum.jucesp.arquivamentos.name
                          ]?.status === StateStatus.loading
                        }
                        dataSource={company?.arquivamentos?.data.items || []}
                        locale={{
                          emptyText: "Esta empresa não possui arquivamentos.",
                        }}
                        pagination={{
                          showSizeChanger: false,
                          current:
                            company?.arquivamentos?.data?.pagination
                              ?.current_page,
                          pageSize:
                            company?.arquivamentos?.data?.pagination?.per_page,
                          total:
                            company?.arquivamentos?.data?.pagination
                              ?.total_items,
                          onChange: (page, pageSize) => {
                            console.log({ c: company?.arquivamentos });
                            dispatch(
                              getServiceDataThunk({
                                service: serviceKey,
                                section: ServicesEnum.jucesp.arquivamentos.name,
                                filterBy: { jucesp_id: company.id },
                                dossierId: serviceData?.dossier_id,
                                page: page,
                                subItemsMap: {
                                  sectionKey: ServicesEnum.jucesp.empresas.name,
                                  itemIndex: companyIndex,
                                  itemKey: "arquivamentos",
                                },
                              })
                            );
                          },
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <hr
                  style={{
                    color: "#7D7D7D",
                    width: "100%",
                    margin: "40px 0px",
                  }}
                />
              </Row>
            );
          })}

          <Row justify={"end"}>
            <Pagination
              showSizeChanger={false}
              current={sectionData?.data?.pagination?.current_page}
              pageSize={sectionData?.data?.pagination?.per_page}
              total={sectionData?.data?.pagination?.total_items}
              onChange={(page, pageSize) => {
                dispatch(
                  getServiceDataThunk({
                    service: serviceKey,
                    dossierId: serviceData.dossier_id,
                    section: sectionKey,
                    page: page,
                  })
                );
              }}
            />
          </Row>
        </>
      ) : (
        <div style={{ textAlign: "center", width: "100%", marginTop: "15px" }}>
          Não foram encontrados registros para este(a) pesquisado(a).
        </div>
      )}
    </TabContainer>
  );
};

export { TabContentJucesp };
