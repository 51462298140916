import { configureStore } from "@reduxjs/toolkit"
import { addTargetsSlice } from "../features/addTargets/slice"
import { graphSlice } from "../features/graph/slices"
import { targetStatusPageSlice } from "../features/target_status_page/slices"
import { loginSlice } from "../features/login/slice"
import { forgotPassSlice } from "../features/forgot_password/slice"
import { changePasswordSlice } from "../features/change_password/slice"
import { createAccountSlice } from "../features/new_account/slice"
import { listReportsSlice } from "../features/dashborad/pages/list_reports/slice";
import { monitoringSlice } from "../features/dashborad/pages/monitoring_page/slice";
import { newClientSlice } from "../features/dashborad/components/common/new-client/slice";
import { listUsersSlice } from "../features/dashborad/pages/users/slice";
import { listClientsSlice } from "../features/dashborad/pages/clients-adm/slice"
import { createReportUnauthSlice } from "../features/create_report_unauth_page/slice"
import { reportSlice } from "../features/dashborad/pages/report-page/slice"
import { targetSlice } from "../features/dashborad/pages/target-page/slice"
import { useDispatch } from "react-redux"

export const store = configureStore({
  reducer: {
    addTargets: addTargetsSlice.reducer,
    report: reportSlice.reducer,
    target: targetSlice.reducer,
    targetStatusPage: targetStatusPageSlice.reducer,
    graph: graphSlice.reducer,
    login: loginSlice.reducer,
    forgotPass: forgotPassSlice.reducer,
    changePassword: changePasswordSlice.reducer,
    createAccount: createAccountSlice.reducer,
    createReportUnauth: createReportUnauthSlice.reducer,

    //dashboard
    listReports: listReportsSlice.reducer,
    monitoring: monitoringSlice.reducer,
    newClient: newClientSlice.reducer,
    listUsers: listUsersSlice.reducer,
    listClients: listClientsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})


export type AppDispatch = typeof store.dispatch
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export type RootState = ReturnType<typeof store.getState>


export default store