
import React, { useEffect, useState } from "react";

//
import { Row, Modal, Form, message, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { editReportFieldsThunk, getReportStatusThunk, resetStatus, selectReportStatusState } from "../../slices";
import { BlueSquareButton, BlueSquareOulinedButton } from "../../../../common/components/button";
import PropTypes from "prop-types"
import StateStatus from "../../../../utils/stateStatus";
import Input from "../../../../common/components/input";
import TextArea from "antd/lib/input/TextArea";
import { EditReportFieldsArgs } from "../../interfaces";

const ModalEditFieldsReport = ({report, onCancel, onSuccess}) => {
  const slice = useSelector(selectReportStatusState);
  const dispatch = useDispatch();
  const [form] = Form.useForm()
  const [showEmbedVideo, setShowEmbedVideo] = useState(null)

  useEffect(() => {
    if(report) {
      form.setFieldsValue({
        video_description_embed: report.video_description_embed,
        video_description_link: report.video_description_link,
        video_description_password: report.video_description_password,
      })

      setShowEmbedVideo(report.video_description_embed)
    }

  }, [])

  useEffect(() => {
    if(slice.status.editReportFields === StateStatus.succeeded) {
      message.success("Suas alterações foram salvas.")
      onSuccess()
      dispatch(resetStatus())
      dispatch(getReportStatusThunk(report.id));
    }

    if(slice.status.editReportFields === StateStatus.failed) {
      message.error("Não foi possivel salvar as alterações.")
      dispatch(resetStatus())
    }
  }, [slice.status.editReportFields])


  return (
    <>
    <Modal
      className="edit-report-modal"
      title={"Editar informações do report"}
      open={true}
      onCancel={onCancel}
      footer={[
        <Row justify="space-between" style={{ marginTop: "30px" }}>
          <BlueSquareOulinedButton
            onClick={onCancel}>
            Cancelar
          </BlueSquareOulinedButton>

          <BlueSquareButton
            onClick={() => form.submit()}
            isLoading={slice.status.editReportFields === StateStatus.loading}
            disabled={slice.status.editReportFields === StateStatus.loading}
          >
            Salvar
          </BlueSquareButton>
        </Row>,
      ]}
      width={"900px"}
    >
      <Form
        form={form}
        layout={"vertical"}
        onValuesChange={(values, data) => {
          if(Object.keys(values).includes("video_description_embed")) {
            if(values.video_description_embed && values.video_description_embed.trim().length > 0) {
              setShowEmbedVideo(values.video_description_embed)
            } else{
              setShowEmbedVideo(null)
            }
          }
        }}
        onFinish={(values: EditReportFieldsArgs) => {
          values.video_description_password = values.video_description_password!.length > 0 ? values.video_description_password : null
          values.video_description_link = values.video_description_link!.length > 0 ? values.video_description_link : null
          values.video_description_embed = showEmbedVideo
          values.report_id = report.id
          dispatch(editReportFieldsThunk(values))
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={showEmbedVideo ? 12 : 24}>
            <Form.Item
              label="Embed vídeo explicativo (Código HTML)"
              name="video_description_embed"
              rules={[
                {
                  min: 100,
                  message: "Forneça um código Embed válido",
                },
                () => ({
                  validator(_, value) {
                    if(!value || value.trim().length === 0) return Promise.resolve()

                    const doc = document.getElementById("embed-video")
                    if(doc) {
                      const iframe = doc.querySelector("iframe")
                      if (!iframe) return Promise.reject("O código não é valido")
                      return Promise.resolve()
                    } 

                    return Promise.reject()
                  }
                })
              ]}
            >
              <TextArea  autoSize={{ minRows: 10, maxRows: 15 }} placeholder="Código HTML do vídeo"/>
            </Form.Item>
          </Col>
          <Col span={showEmbedVideo ? 12 : 0} style={{paddingTop: "30px"}}>
            {showEmbedVideo ? (
              <div id="embed-video" dangerouslySetInnerHTML={{ __html: showEmbedVideo}}></div>
            ): null}
          </Col>
          <Col span={24}>
            <Form.Item
              label="Link vídeo explicativo"
              name="video_description_link"
            >
              <Input placeholder="Link vídeo"/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Senha do vídeo"
              name="video_description_password"
              rules={[
                {
                  max: 12,
                  message: "Máximo de 12 caracteres",
                },
              ]}
            >
              <Input placeholder="Senha acesso ao vídeo"/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      </Modal>
    </>
  );
};

ModalEditFieldsReport.propTypes = {
  report: PropTypes.object.isRequired, 
  onCancel: PropTypes.func.isRequired, 
  onSuccess: PropTypes.func.isRequired
}


export default ModalEditFieldsReport;