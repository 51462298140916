import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { listServicesStatus, retryService } from "./service";
import StateStatus from "../../../../utils/stateStatus";
import { ListTargetsParams, ListTargetsResponse, RetryServiceResponse, TargetStatusType } from "./interfaces";

// Definindo o thunk de listagem com tipagem adequada
export const getListTargetsThunk = createAsyncThunk<
  ListTargetsResponse,
  ListTargetsParams
>(
  "monitoringSlice/list-targets",
  async ({
    page,
    pageSize = 15,
    filter,
    status,
    service,
    created_starts_at,
    created_ends_at,
  }) => {
    const response = await listServicesStatus({
      pagination: pageSize,
      page,
      filter,
      status,
      service,
      created_starts_at,
      created_ends_at,
    });

    return {
      pageSize,
      filter,
      status,
      service,
      created_starts_at,
      created_ends_at,
      ...response.data,
    };
  }
);

// Thunk para retry de serviço com tipagem
export const retryServiceThunk = createAsyncThunk<RetryServiceResponse, string>(
  "monitoringSlice/retry",
  async (serviceId) => {
    const response = await retryService(serviceId);
    return response.data;
  }
);

// Estado inicial com tipagem
interface MonitoringState {
  status: {
    listTargets: StateStatus;
    retryService: StateStatus;
  };
  data: {
    targets: {
      items: TargetStatusType[];
      pagination: {
        page: number;
        totalCount: number;
        totalPages: number;
        pageSize?: number;
      };
      filters: {
        filter: string;
        status: string;
        service: string | null | undefined;
        created_starts_at?: string;
        created_ends_at?: string;
      };
    };
    retryServiceId: string | null;
  };
  errMessage: {
    listTargets: string | null;
    retryService?: string | null;
  };
}

const initialState: MonitoringState = {
  status: {
    listTargets: StateStatus.idle,
    retryService: StateStatus.idle,
  },
  data: {
    targets: {
      items: [],
      pagination: {
        page: 0,
        totalCount: 0,
        totalPages: 1,
      },
      filters: {
        filter: "",
        status: "error",
        service: null,
      },
    },
    retryServiceId: null,
  },
  errMessage: {
    listTargets: null,
  },
};

// Slice com tipagem
export const monitoringSlice = createSlice({
  name: "monitoringSlice",
  initialState,
  reducers: {
    resetListTargets: (state) => {
      state.status.listTargets = StateStatus.idle;
    },
    resetRetryService: (state) => {
      state.status.retryService = StateStatus.idle;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListTargetsThunk.pending, (state) => {
        state.status.listTargets = StateStatus.loading;
      })
      .addCase(getListTargetsThunk.fulfilled, (state, { payload }) => {
        state.data.targets.items = payload.items;
        state.data.targets.pagination.totalCount = payload.pagination.total_count;
        state.data.targets.pagination.totalPages = payload.pagination.total_pages;
        state.data.targets.pagination.pageSize = payload.pageSize;
        state.data.targets.pagination.page = payload.pagination.current_page;
        state.data.targets.filters.filter = payload.filter;
        state.data.targets.filters.status = payload.status;
        state.data.targets.filters.service = payload.service;
        state.data.targets.filters.created_starts_at = payload.created_starts_at;
        state.data.targets.filters.created_ends_at = payload.created_ends_at;

        state.status.listTargets = StateStatus.succeeded;
      })
      .addCase(getListTargetsThunk.rejected, (state, action) => {
        state.status.listTargets = StateStatus.failed;
        state.errMessage.listTargets = action.error?.message || null;
      })
      .addCase(retryServiceThunk.pending, (state, { meta, payload }) => {
        state.data.retryServiceId = meta.arg;
        state.status.retryService = StateStatus.loading;
      })
      .addCase(retryServiceThunk.fulfilled, (state, action) => {
        state.status.retryService = StateStatus.succeeded;
        state.data.targets.items =
          state.data.targets.items?.map((x) => {
            x.services = x.services.map((s: any) =>
              s.id === action.meta.arg
                ? { ...s, service_status: "loading", delay_minutes: 0 }
                : s
            );
            return x;
          }) || [];
      })
      .addCase(retryServiceThunk.rejected, (state, action) => {
        state.status.retryService = StateStatus.failed;
        state.errMessage.retryService = action.error?.message || null;
      });
  },
});

// Exportando actions e selectores
export const { resetListTargets, resetRetryService } = monitoringSlice.actions;
export const selectMonitoringState = (state: { monitoring: MonitoringState }) =>
  state.monitoring;
