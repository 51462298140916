
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDataThunk, selectTargetPageState } from "../../slice";
import { Col, Row, Table } from "antd";
import localeValues from "antd/locale/pt_BR";
import StateStatus from "../../../../../../utils/stateStatus";
import Icons from "../../../../../../common/components/Icons";
import { TabContainer } from "../../components/tab-container";
import { ServicesEnum } from "../../../../../../utils/services";
import { ProgressBar } from "../../components/progress-bar";
import "./styles.scss";

const TabContentScores = () => {
  const serviceKey = ServicesEnum.boavista.name;
  const sectionKey = ServicesEnum.boavista.scores.name;
  const state = useSelector(selectTargetPageState);
  const dispatch = useDispatch();

  const serviceData = state.data.target?.services ? state.data.target?.services[serviceKey] : null;
  const sectionData = serviceData?.sections && serviceData.sections[sectionKey] ? serviceData.sections[sectionKey] : null;
  const scores = sectionData?.data?.items?.filter(x => ["38", "63"].includes(x.modelo_score) && x.score && x.score > 0)

  useEffect(() => {
    if (serviceData?.dossier_id && serviceData?.service_status === "completed" && !sectionData?.data?.pagination?.current_page) {
      dispatch(
        getServiceDataThunk({
          service: serviceKey,
          section: sectionKey,
          dossierId: serviceData?.dossier_id,
          page: 1,
        })
      );
    }
  }, [serviceData?.dossier_id]);


  return (
    <TabContainer
      icon={<Icons icon={"score"} />}
      title={"Scores"}
    >
      <Row>
        <Col span={24}>
          {scores?.length > 0 ? scores?.map((item, idx) => (
            <Row justify={"center"} className="score-container">
              <Col span={24} style={{textAlign: "center"}}>
                <div><strong style={{fontSize: "18px"}}>PONTUAÇÃO DE CRÉDITO</strong></div>
                <div><strong style={{fontSize: "36px"}}>{item.score}</strong></div>
              </Col>
              <Col span={24} style={{textAlign: "center"}}>
                <ProgressBar pointValue={item.score} pointMin={item.score_min} pointMax={item.score_max} height="30px"/>
              </Col>
              <Col span={24} style={{textAlign: "center"}}>
                  <div style={{maxWidth: "450px", display: "inline-block"}}>{item?.texto}</div>
                  <div style={{maxWidth: "450px", display: "inline-block"}}>{item?.texto_explicativo ?? ""}</div>
              </Col>
              <hr style={{color: '#7D7D7D', width: '100%', margin: '40px 0px'}} />
            </Row>
        )) : <div style={{textAlign: "center", width: "100%", marginTop: "15px"}}>Pontuação de crédito não encontrada.</div>}
        </Col>
      </Row>
    </TabContainer>
  );
};

export { TabContentScores };
