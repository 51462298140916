import React from "react"
import Header from "../../common/components/header"

export const TermsUse = () => {

    return (
        <>
            <Header />
            <div className="terms-use">
                <h3 className="title-terms">Termos de uso e avisos legais</h3>

                <p className="text-terms">ATENÇÃO! O relatório encomendado envolve busca de informações que podem ser consideradas sensíveis. O relatório produto das buscas é totalmente confidencial e não deve ser disponibilizado para outras pessoas. É pressuposto para a elaboração e disponibilização do relatório que o solicitante tenha justa causa para realizar pesquisas das pessoas que ele indicou. A emissora deste dossiê é apenas operadora que realiza pesquisas e consolida dados em nome de e por ordem do solicitante. O solicitante se responsabiliza totalmente por todas as pesquisas encomendadas e dados encontrados, e garante que tem justa causa para a pesquisa. Se houver qualquer discordância com estas condições, com os termos de uso da emissora (ver abaixo), ou qualquer dúvida sobre se o solicitante é parte legítima para a realização das pesquisas ou dúvidas sobre se há justa causa para a pesquisa, o relatório NÃO deve ser encomendado e, se foi, deverá ser imediatamente e completamente destruído, sem que o leitor tenha contato com qualquer outra informação nele presente. Seguir com a solicitação pressupõe irrestrita concordância com essas condições e assunção de todos os riscos e responsabilidades das pesquisas.</p>

                <p className="text-terms">Este ou qualquer dossiê produzido pela emissora e quaisquer outros documentos que o acompanhem contêm informações confidenciais e/ou privilegiadas dirigidas a um destinatário específico. Se você não é o destinatário ou a pessoa autorizada a receber tais documentos, considere-se notificado de que você não pode tomar conhecimento daquilo tratado neste dossiê e/ou anexos, e nem tampouco pode usar, copiar ou divulgar as informações neles contidas, ou tomar qualquer ação baseado nessas informações, sob pena de ser responsabilizado civil e/ou criminalmente de acordo com a lei. Se você estiver nestas condições, interrompa imediatamente a leitura deste documento e o destrua.</p>

                <p className="text-terms">Parte das informações contidas nos dossiês e demais documentos produzidos é obtida por meios automatizados e/ou por intermédio de fornecimento de dados por terceiros. São envidados os melhores esforços para zelar pela veracidade das informações disponibilizadas, mas o eventual uso dos dados, sobretudo para subsidiar medidas que invadam a esfera jurídica de terceiro, deve se dar de forma responsável, se possível com rechecagem de informações e com o aval de advogados, conforme seja o caso. Os emissores do dossiê e demais documentos investigativos se eximem de responsabilidades decorrentes do uso das informações.</p>

                <p className="text-terms">No caso de dossiês ou relatórios gerados parcial ou integralmente de forma automatizada, é natural a existência de certa imprecisão de informações, que pode se dar de forma mais ou menos acentuada a depender do caso. O interessado solicitante compreende e anui expressamente com tal circunstância, comprometendo-se a utilizar por sua exclusiva conta e risco os relatórios, e responsabilizando-se completamente pelo uso indevido, intempestivo, açodado ou imprudente dos documentos obtidos, sendo seu dever providenciar a rechecagem das informações antes de as efetivamente utilizar em medidas que pretenda promover em face de qualquer pessoa, sendo seu dever, também, a consulta a advogados e outros profissionais habilitados sobre a licitude, viabilidade jurídica e conveniência estratégica de eventuais medidas baseadas nos relatórios ou sugeridas neles.</p>

                <p className="text-terms">O(A) emissora do dossiê não presta nenhum serviço privativo ou exclusivo de advogado. Todas as eventuais sugestões e recomendações técnicas contidas neste dossiê levam em consideração apenas estratégias de recuperação de crédito, sem qualquer análise que substitua a realizada por profissionais da área jurídica. Para a análise da possibilidade e viabilidade jurídica de efetivação das medidas é imprescindível a contratação de advogado, nos termos da legislação aplicável. Nenhuma das recomendações técnicas, sugestões, propostas de estratégias, indicação de fraudes e/ou patrimônio, entre outras, dispensa a análise de legalidade e/ou viabilidade jurídica por advogados, sendo que a efetivação de medidas em face dos investigados é de inteira responsabilidade do(a) solicitante do dossiê e dos profissionais jurídicos por ele(a) contratados, sendo certo que os emissores do dossiê ou quaisquer outros documentos elaborados no contexto contratual não têm qualquer responsabilidade pelas repercussões jurídicas e nem pela operacionalização de tais medidas, incluindo, mas não se limitando, a eventuais condenações, pagamento de custas judiciais, pagamento de honorários sucumbenciais, responsabilização por dano processual, cumprimento de prazos judiciais etc.</p>

                <p className="text-terms">O uso das informações e recomendações disponibilizadas é de responsabilidade única e exclusiva do(a) cliente. O(A) cliente não pode entregar qualquer material produzido pelos emissores deste dossiê diretamente para o investigado, nem entregar a ele nada que identifique os emissores deste dossiê. Os emissores deste dossiê se isentam de toda e qualquer responsabilidade pelo uso da informação, sendo que qualquer processo ou outra medida que sofrer em função do uso da informação por seu cliente deverá ser totalmente indenizado pelo próprio cliente. O(A) cliente(contratante) se compromete a utilizar as informações do dossiê fundamentando suas eventuais argumentações nos documentos que forem encontrados, sem nunca imputar ilações, conclusões e dedução ao(à) CONTRATADO(A). O(A) CLIENTE CONTRATANTE, caso aproveite qualquer texto do dossiê, compromete-se a utilizá-lo como se dele fosse, ficando desde logo os direitos autorais daquilo que foi produzido e escrito no próprio dossiê cedidos para uso do CLIENTE CONTRATANTE em nome próprio.</p>

                <p className="text-terms">O(a) interessado(a) solicitante tem total ciência dos termos da Lei n. 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados Pessoais – LGPD/2018), e demais leis aplicáveis à pesquisas patrimoniais ou sobre dados pessoais, serviços de inteligência, serviços de investigação, e todas as demais normas aplicáveis à relação jurídica porventura mantida com o(a) emissor(a) do dossiê, e sabe que necessita ter justa causa para encomendar pesquisas sobre qualquer pessoa, sobretudo as causas descritas no art. 7º, da LGPD/2018. Assim sendo, o(a) interessado(a) solicitante se compromete no sentido de que possui justa causa para as pesquisas solicitadas, e se responsabiliza integralmente por qualquer repercussão que a solicitação venha a trazer, inclusive se comprometendo a indenizar o(a) emissor(a) dos relatórios, dossiês, documentos e afins nos casos em que ela sofrer qualquer prejuízo ou for obrigada a pagar qualquer indenização em razão de o interessado(a) solicitante ter encomendado pesquisas e serviços sobre os quais ela não ele(a) não tinha direito de encomendar.</p>

                <p className="text-terms">O uso das informações deste dossiê, mesmo no caso de degustação, pressupõe a plena e irrestrita concordância com estes termos e de todas as outras disposições que porventura foram contratual ou informalmente combinadas entre as partes, além da estrita observância aos ditames da lei, da ética, dos bons costumes, da cooperação e da boa-fé objetiva.</p>
            </div>
        </>

    )

}