
import React, { FunctionComponent, useEffect, useState } from "react";
import propTypes from "prop-types";
import { Row, Col, Divider, Form, Skeleton, Modal, Image, message } from "antd";
import {
  BlueSquareButton,
  BlueSquareSmallButton,
} from "../../../../common/components/button";
import {
  getReportThunk,
  editOrderThunk,
  purchaseThunk,
  resetCardToken,
  resetPurchaseOrder,
  selectSearchListState,
  createCreditCardTokenThunk,
  resetGetOrder,
} from "../../pages/list_reports/slice";
import { beautifyCpfCnpj, formatCurrency } from "../../../../utils/formatters";
import { useDispatch, useSelector } from "react-redux";
// import { loginSelector } from "../../../login/slice";
import StateStatus from "../../../../utils/stateStatus";
import MaskInput from "../../../../common/components/mask-input";
import Input from "../../../../common/components/input";
import { ReactComponent as OpenExternal } from "../../../../common/images/open-external.svg";
import { ReactComponent as Copy } from "../../../../common/images/copy.svg";

import "./styles.scss";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { BilletType, CardType, ChargeType, OrderType, PixDataType } from "../../pages/list_reports/interfaces";


interface PaymentByPixProps {
  pix: ChargeType,
}

const PaymentByPix: FunctionComponent<PaymentByPixProps> = ({ pix }) => {
  const [copied, setCopied] = useState(false);

  const pixData = pix.data as PixDataType

  return (
    <>
      <Row>
        <Col span={24}>
          <div className="normal-14">
            Aponte a câmera ou copie o código abaixo para concluir o pagamento:
            <br />
            <div
              style={{
                width: "100%",
                backgroundColor: "#FEF2C6",
                padding: "5px",
              }}
            >
              Após o pagamento, o sistema pode levar até 5 minutos para
              atualizar.
            </div>
          </div>
        </Col>
      </Row>
      <Row justify={"center"} style={{ marginTop: "20px" }}>
        {pix ? (
          <Image
            preview={false}
            width={250}
            src={pixData?.qr_code_url ?? ""}
            className="image-qrcode"
          />
        ) : null}
      </Row>
      <Row justify={"center"} gutter={[24, 24]}>
        <Col span={24}>
          <div>COPIA E COLA:</div>
          <div className="payment-qr-code">
            <div>{pixData?.qr_code || "Carregando pix copia e cola..."}</div>
            <div
              onClick={() => {
                navigator.clipboard.writeText(pixData?.qr_code ?? "");
                setCopied(true);
                setInterval(() => setCopied(false), 2000);
              }}
              className="copy-button"
            >
              <Copy />
            </div>
          </div>
          {copied ? <span>Pix copiado!</span> : null}

          {/* <BlueSquareOulinedSmallButton type="htmlButton" onClick={() => navigator.clipboard.writeText(pix?.qr_code)}>Copiar</BlueSquareOulinedSmallButton> */}
        </Col>
      </Row>
    </>
  );
};

interface PaymentByBilletProps {
  billet: ChargeType,
}

const PaymentByBillet: FunctionComponent<PaymentByBilletProps> = ({ billet }) => {

  const billetData = billet.data as BilletType

  return (
    <div className="payment-billet">
      <a href={billetData?.pdf ?? ""} target="_blank" rel="noreferrer">
        Visualizar boleto
      </a>{" "}
      <OpenExternal />
    </div>
  );
};



const PaymentByCard = () => {
  return (
    <>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col sm={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Campo obrigatório",
              },
              () => ({
                validator(_, val) {
                  if (!val || !window.Iugu.utils.validateCreditCardNumber(val)) {
                    return Promise.reject(new Error("Informe um cartão válido"))
                  }
  
                  return Promise.resolve()
                },
              }),
            ]}
            name="number"
            label="Número do cartão de crédito"
          >
            <MaskInput
              mask={[
                {
                  mask: "0000 0000 0000 0000",
                  lazy: true,
                },
              ]}
              placeholder="0000 0000 0000 0000"
              size="middle"
            />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Campo obrigatório",
              },
            ]}
            name="holder_name"
            label="Nome do titular"
          >
            <Input
              style={{ width: "100%" }}
              placeholder={"Digite o nome do titular"}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col sm={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Campo obrigatório",
              },
              () => ({
                validator(_, val) {
                  const value = (`${val}` ?? "").replace(/\D/g, "");
                  if (!value || value.length < 3 || value.length > 4) {
                    return Promise.reject(new Error("CVV inválido"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            name="cvv"
            label="Código de segurança (CVV)"
          >
            <Input
              maxLength={4}
              placeholder={"Digite o código de segurança"}
              formatter={(value) => value.replace(/\D/g, "")}
            />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Campo obrigatório",
              },
              () => ({
                validator(_, value) {
                  const month = value.slice(0, 2)
                  const year = value.slice(2)
                  if (
                    !value ||
                    value.replace(/[^0-9]+/g, "").length < 6 ||
                    !window.Iugu.utils.validateExpirationString(`${month}/${year}`)
                  ) {
                    return Promise.reject(
                      new Error("Data inválida ou expirada")
                    )
                  }

                  return Promise.resolve()
                },
              }),
            ]}
            name="validity"
            label="Validade do cartão"
          >
            <MaskInput
              mask={[
                {
                  mask: "00/0000",
                  lazy: true,
                },
              ]}
              placeholder="00/0000"
              size="middle"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const ProductCartItem = ({ report, target }) => {
  return (
    <Row className="item-purchase-row" justify={"space-between"}>
      <Col span={20}>
        <div>
          {report?.case_type_display} para{" "}
          {target?.product && target?.num_matricula
            ? `Matrícula ${target?.num_matricula}`
            : beautifyCpfCnpj(target?.cpf_cnpj)}
        </div>
      </Col>
      <Col span={4} style={{ textAlign: "right" }}>
        {formatCurrency(target?.price)}
      </Col>
    </Row>
  );
};

interface PaymentModalProps {
  onSuccess: () => void, 
  onFailed: () => void, 
  onCancel: () => void,
} 

const PaymentModal: FunctionComponent<PaymentModalProps> = ({ onSuccess, onFailed, onCancel }) => {
  const [form] = Form.useForm();
  const state = useSelector(selectSearchListState);
  const [charge, setCharge] = useState<ChargeType>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!state.data.order?.status) {
      setCharge({} as ChargeType);
    } else if (
      state.status.getOrder === StateStatus.succeeded ||
      state.status.purchase === StateStatus.succeeded ||
      state.status.editOrder === StateStatus.succeeded
    ) {
      if (state.data.order.status === "paid") {
        onSuccess();
      } else if (state.data.order.status === "processing") {
        onSuccess();
      } else if (state.data.order.charges) {
        const charge = state.data.order.charges.filter(
          (x) => x.payment_method === state.data.order.last_payment_method && ["waiting", "processing"].includes(x.status)
        )[0];
        setCharge(charge);
      } else {
        setCharge({} as ChargeType);
      }
    }
  }, [state.status.getOrder, state.status.purchase, state.status.editOrder]);

  useEffect(() => {
    if (
      state.status.purchase === StateStatus.succeeded ||
      state.status.editOrder === StateStatus.succeeded
    ) {
      dispatch(getReportThunk(state.data.report?.id));

      if (state.data.order?.payment_method === "card") {
        onSuccess();
      }

      dispatch(resetCardToken());
      dispatch(resetPurchaseOrder());
    }

    if (
      state.status.purchase === StateStatus.failed ||
      state.status.editOrder === StateStatus.failed
    ) {
      onFailed();
      dispatch(resetCardToken());
      dispatch(resetPurchaseOrder());
    }
  }, [state.status.purchase, state.status.editOrder]);

  useEffect(() => {
    if (state.status.purchase === StateStatus.succeeded) {
      if(state.data.order.last_payment_method !== "card") {
        message.success(
          "Método de pagamento selecionado, realize o pagamento para finalizar."
        );
      }
    }

    if (state.status.purchase === StateStatus.failed) {
      message.error("Não foi possível selecionar método de pagamento.");
    }
  }, [state.status.purchase]);

  useEffect(() => {
    if (state.status.editOrder === StateStatus.succeeded) {
      if (state.data.order.status !== "paid") {
        message.success("Método de pagamento foi alterado.");
      }
    }

    if (state.status.editOrder === StateStatus.failed) {
      message.error("Não foi possível alterar método de pagamento.");
    }
  }, [state.status.editOrder]);


  useEffect(() => {
    if (state.status.cardToken === StateStatus.succeeded) {
      // message.success("Cartao de credito valido.");

      if(state.data.order?.id) {
        const data = {
          payment_method: "card",
          card_token: state.data.cardToken,
        };

        const id = state.data.order.id;
        dispatch(editOrderThunk({ id, data }));
      } else {
        const data = {
          report_id: state.data.report.id,
          payment_method: "card",
          card_token: state.data.cardToken,
        };

        dispatch(purchaseThunk(data));
        dispatch(resetCardToken());
      }
    }

    if (state.status.cardToken === StateStatus.failed) {
      form.setFields([{
        name: "number", 
        errors: ["Cartão de crédito inválido, verifique as informações"],
    }])
    }
  }, [state.status.cardToken]);


  interface OrderProps {
    type: string, 
    card?: CardType,
  }

  const createOrderRequest = ({ type, card }: OrderProps) => {
    const data = {
      report_id: state.data.report.id,
      payment_method: type,
    };

    if(type === "card") {
      if(card) {
        dispatch(createCreditCardTokenThunk({
          cardNumber: card.number,
          cardName: card.holder_name,
          validity: card.validity,
          cvv: card.cvv,
        }))
      }
    } else {
      dispatch(purchaseThunk(data));
    }
  };

  const editOrderRequest = ({ type, card }: OrderProps) => {
    const data = {
      payment_method: type,
    };

    if(type === "card") {
      if(card) {
        dispatch(createCreditCardTokenThunk({
          cardNumber: card.number,
          cardName: card.holder_name,
          validity: card.validity,
          cvv: card.cvv,
        }))
      }
    } else {
      const id = state.data.order.id;
      dispatch(editOrderThunk({ id, data }));
    }
  };

  return (
    <Modal
      className="payment-modal"
      title={"Solicitar nova pesquisa"}
      open={true}
      onCancel={() => {
        dispatch(resetGetOrder());
        dispatch(resetCardToken());
        dispatch(resetPurchaseOrder());
        onCancel()
      }}
      footer={<>
        <Row justify={charge?.payment_method !== "card" ? "center" : "space-between"} gutter={[8, 8]} style={{ marginTop: "30px" }}>
          <Col >
            <BlueSquareButton  onClick={onCancel}>
              Fechar
            </BlueSquareButton>
          </Col>
          {charge?.payment_method === "card" ? (
            <Col>
              <BlueSquareButton 
              isLoading={(
                state.status.cardToken === StateStatus.loading
                || state.status.editOrder === StateStatus.loading
                || state.status.purchase === StateStatus.loading
              )} 
              onClick={async () => {
                form.submit()                
              }}>
                Finalizar pagamento
              </BlueSquareButton>
            </Col>
          ): null}
        </Row>
        </>}
      width={"900px"}
    >
      <Form
        form={form}
        onFinish={(values) => {
          if(!state.data.order?.id) {
            createOrderRequest({ type: "card", card: values as CardType });
          } else {
            editOrderRequest({ type: "card", card: values as CardType});
          }
        }}
        layout="vertical"
      >
        <Row justify={"center"}>
          <Col span={18}>
            <Row justify={"center"}>
              <Col>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    marginBottom: "30px",
                    marginTop: "15px",
                  }}
                >
                  ETAPA 3 de 3: Pagamento
                </div>
                {/* <br /> */}
                <div style={{ width: "100%" }}>
                  Confira os valores abaixo e realize o pagamento com uma de
                  nossas opções.
                </div>
                {/* <div className="cart-items-label">Items adicionados:</div> */}
              </Col>
            </Row>
            <Divider />
            <Row justify={"space-between"} style={{ width: "100%" }}>
              {state.status.calcReportPrice === StateStatus.loading ? (
                <div style={{ width: "100%", height: "150px" }}>
                  <Skeleton active />
                </div>
              ) : (
                <>
                  {state.data?.report?.case_type !== "docs"
                    ? state.data?.report?.targets?.map((target, idx) => (
                        <Col key={idx} span={24}>
                          <ProductCartItem
                            report={state.data?.report}
                            target={target}
                          />
                          {/* <Divider /> */}
                        </Col>
                      ))
                    : state.data?.report?.docs_products?.map((doc, idx) => (
                        <Col key={idx} span={24}>
                          <ProductCartItem
                            report={state.data?.report}
                            target={doc}
                          />
                          {/* <Divider /> */}
                        </Col>
                      ))}
                </>
              )}
            </Row>
            <br />
            <Row justify="start">
              <Col span={20}>
                <strong>Valor total: </strong>
              </Col>
              <Col span={4} style={{ textAlign: "right" }}>
                <span className="font-normal-14-600">
                  {formatCurrency(state.data.report?.price)}
                </span>
              </Col>
            </Row>
            <Divider />
            {state.status.getReport === StateStatus.loading ||
            state.status.getOrder === StateStatus.loading ||
            state.status.purchase === StateStatus.loading ||
            state.status.editOrder === StateStatus.loading ? (
              <Row justify={"center"}>
                <LoadingOutlined spin />
              </Row>
            ) : !charge?.payment_method ? (
              <>
                <Row justify={"start"}>
                  <Col>
                    <div className="font-normal-14-600">Pagar com</div>
                  </Col>
                </Row>
                <Row
                  className="payment-methods"
                  justify="center"
                  style={{ marginTop: "30px" }}
                  gutter={[16, 16]}
                >
                  <Col>
                    <BlueSquareSmallButton
                      onClick={() => {
                        if(!state.data.order?.id) {
                          createOrderRequest({ type: "pix" });
                        } else {
                          editOrderRequest({ type: "pix" });
                        }
                      }}
                      type="button"
                      disabled={state.data.report?.price <= 0}
                    >
                      PIX
                    </BlueSquareSmallButton>
                  </Col>
                  <Col>
                    <BlueSquareSmallButton
                      onClick={() => {
                        setCharge({payment_method: "card"} as ChargeType)
                      }}
                      type="button"
                      disabled={state.data.report?.price <= 0}
                    >
                      Cartão de crédito
                    </BlueSquareSmallButton>
                  </Col>
                  <Col>
                    <BlueSquareSmallButton
                      onClick={() => {
                        if(!state.data.order?.id) {
                          createOrderRequest({ type: "billet" });
                        } else {
                          editOrderRequest({ type: "billet" });
                        }
                      }}
                      type="button"
                      disabled={state.data.report?.price <= 0}
                    >
                      Boleto
                    </BlueSquareSmallButton>
                  </Col>
                </Row>
                <Divider />
              </>
            ) : (
              <>
                <Row justify={"start"}>
                  <Col>
                    <div className="font-normal-18-600">
                      {charge?.payment_method === "card"
                        ? "Pagar com cartão"
                        : charge?.payment_method === "pix"
                        ? "Pagar com pix"
                        : charge?.payment_method === "billet"
                        ? "Pagar com boleto"
                        : null}
                    </div>
                    <br />
                  </Col>
                </Row>
                <Row justify={"start"}>
                  <Col span={24}>
                    {charge?.payment_method === "card" ? (
                      <PaymentByCard />
                    ) : charge?.payment_method === "pix" ? (
                      <PaymentByPix pix={charge} />
                    ) : charge?.payment_method === "billet" ? (
                      <PaymentByBillet billet={charge} />
                    ) : null}
                  </Col>
                </Row>
                <Row
                  justify="start"
                  style={{ marginTop: "30px" }}
                  gutter={[16, 16]}
                >
                  <Col>
                    <strong>Alterar método de pagamento:</strong>
                  </Col>
                  {charge?.payment_method !== "pix" ? (
                    <Col>
                      <Link
                        to="#"
                        onClick={() => {
                          if(!state.data.order?.id) {
                            createOrderRequest({ type: "pix" });
                          } else {
                            editOrderRequest({ type: "pix" });
                          }
                        }}
                      >
                        Pagar com PIX
                      </Link>
                    </Col>
                  ) : null}
                  {charge?.payment_method !== "billet" ? (
                    <Col>
                      <Link
                        to="#"
                        onClick={() => {
                          if(!state.data.order?.id) {
                            createOrderRequest({ type: "billet" });
                          } else {
                            editOrderRequest({ type: "billet" });
                          }
                        }}
                      >
                        Pagar com boleto
                      </Link>
                    </Col>
                  ) : null}
                  {charge?.payment_method !== "card" ? (
                    <Col>
                      <Link
                        to="#"
                        onClick={() => {
                          setCharge({ payment_method: "card" } as ChargeType);
                        }}
                      >
                        Pagar com cartão de crédito
                      </Link>
                    </Col>
                  ) : null}
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};


export default PaymentModal;
