import StateStatus from "../../utils/stateStatus";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getGraph } from "./service";

interface Node {
    id: string;
    is_target: boolean,
    type: string,
    // Adicione outros atributos do nó se houver
}

interface Edge {
    id: string;
    // Adicione outros atributos da aresta se houver
}

interface GraphData {
    nodes: Node[];
    edges: Edge[];
    nodeLibrary?: { [key: string]: Node };
}

interface GraphState {
    status: {
        getGraph: { [key: string]: StateStatus };
        getAllGraph: StateStatus;
    };
    errMessage: {
        getGraph: string | null;
    };
    graph: GraphData;
    cache: {
        nodes: string[];
        edges: string[];
    };
}

export const getGraphThunk = createAsyncThunk(
    "graph/get",
    async (targetId: string) => {
        const response = await getGraph(targetId);
        return response.data;
    }
);

const initialState: GraphState = {
    status: {
        getGraph: {},
        getAllGraph: StateStatus.idle,
    },
    errMessage: {
        getGraph: null,
    },
    graph: {
        nodes: [],
        edges: [],
    },
    cache: {
        nodes: [],
        edges: [],
    },
};

export const graphSlice = createSlice({
    name: "Graph",
    initialState,
    reducers: {
        resetGraphState: (state) => {
            state.status.getGraph = {};
            state.status.getAllGraph = StateStatus.idle;
            state.graph.nodes = [];
            state.graph.edges = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGraphThunk.pending, (state, action) => {
                state.status.getAllGraph = StateStatus.loading;
                state.status.getGraph[action.meta.arg] = StateStatus.loading;
            })
            .addCase(getGraphThunk.fulfilled, (state, action) => {
                state.status.getGraph[action.meta.arg] = StateStatus.succeeded;
                state.graph.nodes.push(
                    ...action.payload.graph.nodes.filter(
                        (node: Node) => !state.cache.nodes.includes(node.id)
                    )
                );
                state.graph.edges.push(
                    ...action.payload.graph.edges.filter(
                        (edge: Edge) => !state.cache.edges.includes(edge.id)
                    )
                );

                state.cache.nodes.push(
                    ...action.payload.graph.nodes.map((node: Node) => node.id)
                );
                state.cache.edges.push(
                    ...action.payload.graph.edges.map((edge: Edge) => edge.id)
                );

                const completed = Object.values(state.status.getGraph).filter(
                    (x) => x === StateStatus.succeeded
                ).length;

                if (completed === Object.values(state.status.getGraph).length) {
                    state.status.getAllGraph = StateStatus.succeeded;

                    const nodeLibrary: { [key: string]: Node } = {};
                    state.graph.nodes.forEach((item) => {
                        nodeLibrary[item.id] = item;
                    });
                    state.graph.nodeLibrary = nodeLibrary;
                }
            })
            .addCase(getGraphThunk.rejected, (state, action) => {
                state.status.getGraph[action.meta.arg] = StateStatus.failed;
                state.status.getAllGraph = StateStatus.failed;

                state.errMessage.getGraph = action.error.message || null;
            });
    },
});

export const { resetGraphState } = graphSlice.actions;
export const selectGraphState = (state: { graph: GraphState }) => state.graph;
