
import React, { FunctionComponent, useEffect, useState } from "react";
import propTypes from "prop-types";
import { Row, Col, Form, Modal, Checkbox, Divider, Select } from "antd";
import { cpf, cnpj } from "cpf-cnpj-validator";
// import "./styles.scss"
import Input, {
  InputPhone,
} from "../../../../common/components/input";
import MaskInput from "../../../../common/components/mask-input";
import { RoudedButton } from "../../../../common/components/button";
import getAddressByCep from "../../../../service/viacep";
import Icons from "../../../../common/components/Icons";
import { ClientType } from "../../pages/clients-adm/interfaces";

interface EditClientModalProps {
  allowEditCpfCnpj: boolean | undefined
  isAdmUser: boolean | undefined
  client?: ClientType | undefined
  isVisible: boolean | undefined
  isLoading: boolean | undefined
  onSave: (e: any) => void, 
  onCancel: (e: any) => void,
} 

const EditClientModal: FunctionComponent<EditClientModalProps> = ({
  allowEditCpfCnpj,
  isAdmUser,
  client,
  isVisible,
  isLoading,
  onSave,
  onCancel,
}) => {
  const [loadingCep, setLoadingCep] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();

    if (client) {
      let phone = "";
      if (client.phone_number && client.phone_area_code) {
        phone = `${client.phone_area_code}${client.phone_number}`;
      }

      form.setFieldsValue({
        email: client.email,
        name: client.name,
        cpf_cnpj: client.cpf_cnpj,
        phone: phone,
        phone_prefix: client.phone_country_code ?? "55",
        // wallet_value: client.wallet_value,
        billing_mode: client.billing_mode,
        // allow_auto_purchases: client.allow_auto_purchases,
        enabled: client.enabled ?? true,
        address: {
          cep: client.address?.cep,
          city: client.address?.city,
          street: client.address?.street,
          neighborhood: client.address?.neighborhood,
          number: client.address?.number,
          state: client.address?.state,
          country: client.address?.country,
          complement: client.address?.complement,
        },
      });

      if (client.address?.cep) {
        searchAddressByCep(client.address?.cep);
      }
    }
  }, [isVisible]);

  const searchAddressByCep = (value) => {
    if (value?.length !== 9) return;
    setLoadingCep(true);

    getAddressByCep(value)
      .then((response) => {
        form.setFields([
          { name: ["address", "cep"], value: response.cep },
          { name: ["address", "city"], value: response.localidade },
          { name: ["address", "street"], value: response.logradouro },
          { name: ["address", "state"], value: response.uf },
          { name: ["address", "neighborhood"], value: response.bairro },
        ]);
        setLoadingCep(false);
        form.validateFields();
      })
      .catch((err) => {
        setLoadingCep(false);
      });
  };

  return (
    <Modal
      className="edit-client-modal"
      title="Dados cadastrais"
      open={isVisible}
      onCancel={onCancel}
      footer={[
        <Row justify={"space-between"} style={{ marginTop: "30px" }}>
          <RoudedButton key="back" onClick={onCancel}>
            Cancelar
          </RoudedButton>
          <RoudedButton
            className={"btn-blue"}
            onClick={() => form.submit()}
            loading={isLoading}
          >
            Salvar
          </RoudedButton>
        </Row>,
      ]}
      width={"900px"}
    >
      <div className="normal-14">
        Preencha seus dados cadastrais de cliente.
      </div>
      {/* <hr
        style={{
          width: "100%",
          height: "1px",
          border: "none",
          backgroundColor: "#C2C2C2",
        }}
      /> */}
      <br />
      <Form
        form={form}
        onFinish={(values) => {
          values.id = client?.id;
          values.address.cep = values.address.cep.replace(/\D/g, "");
          values.address.complement =
            values.address.complement?.length === 0
              ? null
              : values.address.complement;
          values.phone_area_code = values.phone.slice(0, 2);
          values.phone_number = values.phone.slice(2);
          values.phone_country_code = "55";
          delete values.phone_prefix;
          delete values.phone;
          if (onSave) {
            onSave(values);
          }
        }}
        layout="vertical"
        initialValues={{
          prefix: "55",
        }}
        size="large"
        scrollToFirstError={true}
      >
        <Row gutter={[24, 24]}>
          <Col sm={24} xs={24} md={12}>
            <Row gutter={16}>
              <Col sm={24} xs={24} md={24}>
                <Form.Item
                  name={"name"}
                  label="Nome"
                  initialValue={""}
                  rules={[
                    {
                      required: true,
                      message: (
                        <div className="normal-12">Informe um nome válido!</div>
                      ),
                    },
                  ]}
                >
                  <Input
                    // disabled={!allowEditCpfCnpj}
                    placeholder="Digite o nome ou a razão"
                  />
                </Form.Item>
              </Col>

              <Col sm={24} xs={24} md={24}>
                <Form.Item
                  name={"cpf_cnpj"}
                  label="CPF ou CNPJ"
                  initialValue=""
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (
                          value !== undefined &&
                          value !== null &&
                          value.length > 0
                        ) {
                          const val = value.replace(/[^0-9]+/g, "");

                          if (val.length > 11) {
                            if (cnpj.isValid(val)) return Promise.resolve();

                            return Promise.reject(new Error("CNPJ inválido"));
                          }

                          if (!cpf.isValid(val))
                            return Promise.reject(new Error("CPF inválido"));

                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error("Informe um CPF ou CNPJ")
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <MaskInput
                    maskOptions={{
                      dispatch: function (appended, dynamicMasked) {
                        const value = dynamicMasked.unmaskedValue;
                        const isCPF = value?.length <= 11 && [undefined, null, ""].includes(appended);
                        return dynamicMasked.compiledMasks[isCPF ? 0 : 1];
                      },
                    }}
                    disabled={!allowEditCpfCnpj}
                    mask={[
                      {
                        mask: "000.000.000-00",
                        lazy: true,
                      },
                      {
                        mask: "00.000.000/0000-00",
                        lazy: true,
                      },
                    ]}
                    placeholder="Insira seu CPF ou CNPJ"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col sm={24} xs={24} md={24}>
                <Form.Item
                  name={"email"}
                  label="E-mail"
                  initialValue={""}
                  rules={[
                    {
                      required: true,
                      message: (
                        <div className="normal-12">
                          Informe um e-mail válido!
                        </div>
                      ),
                    },
                    {
                      type: "email",
                      message: (
                        <div className="normal-12">
                          Informe um e-mail válido!
                        </div>
                      ),
                    },
                  ]}
                >
                  <Input
                    disabled={!allowEditCpfCnpj}
                    placeholder="Digite o e-mail"
                  />
                </Form.Item>
              </Col>

              <Col sm={24} xs={24} md={24}>
                <Form.Item
                  name={"phone"}
                  label="Telefone"
                  initialValue={""}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Informe um telefone!",
                    },
                    {
                      min: 10,
                      max: 11,
                      message: "Informe um telefone válido!",
                    },
                  ]}
                >
                  <InputPhone style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              {/* {isAdmUser ? (
                <Col sm={24} xs={24} md={24}>
                  <Form.Item label="Saldo na carteira" name="wallet_value">
                    <InputCurrency placeholder={"R$ 0,00"} />
                  </Form.Item>
                </Col>
              ) : null} */}
              <Col sm={24} xs={24} md={24}>
                {isAdmUser ? (
                  <>
                    <Form.Item
                      name={"billing_mode"}
                      label="Modo de faturamento"
                      initialValue={""}
                      rules={[
                        {
                          required: true,
                          message: (
                            <div className="normal-12">
                              Informe um opção válido!
                            </div>
                          ),
                        },
                      ]}
                    >
                      <Select
                        placeholder="Selecione uma opção"
                        options={[
                          {
                            value: "pre_pago",
                            label: "Pré-pago",
                          },
                          {
                            value: "pos_pago",
                            label: "Pós-pago",
                          },
                          {
                            value: "mensal",
                            label: "Mensal",
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      name={"enabled"}
                      valuePropName="checked"
                    >
                      <Checkbox>Cliente ativo?</Checkbox>
                    </Form.Item>
                  </>
                ) : null}
                {/* <Form.Item
                  name={"allow_auto_purchases"}
                  valuePropName="checked"
                >
                  <Checkbox>
                    Permite descontar automaticamente da carteira, caso tenha
                    saldo?
                  </Checkbox>
                </Form.Item> */}
              </Col>
            </Row>
          </Col>

          <Col sm={24} xs={24} md={12}>
            <div className="endereco">
              {/* <h2>Endereço</h2> */}
              <Row gutter={16} align="middle">
                <Col sm={24} xs={24} md={12}>
                  <Form.Item
                    label="CEP"
                    name={["address", "cep"]}
                    rules={[
                      { required: true, message: "Favor, digite o cep!" },
                    ]}
                    initialValue={client?.address?.cep ?? ""}
                    hasFeedback
                  >
                    <MaskInput
                      mask={[
                        {
                          mask: "00000-000",
                          lazy: true,
                        },
                      ]}
                      placeholder="Digite o CEP"
                      onBlur={(event) => {
                        searchAddressByCep(event.target.value);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col>{loadingCep && <Icons icon={"loading"} spin={true} />}</Col>
              </Row>
              <Row gutter={16}>
                <Col sm={24} xs={24} md={12}>
                  <Form.Item
                    name={["address", "city"]}
                    label="Cidade"
                    initialValue={""}
                    rules={[
                      {
                        required: true,
                        message: (
                          <div className="normal-12">Informe a cidade!</div>
                        ),
                      },
                    ]}
                  >
                    <Input placeholder="Digite a cidade" />
                  </Form.Item>
                </Col>

                <Col sm={24} xs={24} md={12}>
                  <Form.Item
                    name={["address", "state"]}
                    label="Estado"
                    initialValue={""}
                    rules={[
                      {
                        required: true,
                        message: (
                          <div className="normal-12">Informe o estado!</div>
                        ),
                      },
                      {
                        min: 2,
                        max: 2,
                        message: (
                          <div className="normal-12">
                            Informe a sigla do estado. Ex.: SP
                          </div>
                        ),
                      },
                    ]}
                  >
                    <Input placeholder="Digite a cidade" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col sm={24} xs={24} md={12}>
                  <Form.Item
                    name={["address", "street"]}
                    label="Rua"
                    initialValue={""}
                    rules={[
                      {
                        required: true,
                        message: (
                          <div className="normal-12">Informe a rua!</div>
                        ),
                      },
                    ]}
                  >
                    <Input placeholder="Digite a rua" />
                  </Form.Item>
                </Col>

                <Col sm={24} xs={24} md={12}>
                  <Form.Item
                    name={["address", "neighborhood"]}
                    label="Bairro"
                    initialValue={""}
                    rules={[
                      {
                        required: true,
                        message: (
                          <div className="normal-12">Informe o bairro!</div>
                        ),
                      },
                    ]}
                  >
                    <Input placeholder="Digite o bairro" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col sm={24} xs={24} md={12}>
                  <Form.Item
                    name={["address", "number"]}
                    label="Número"
                    initialValue={""}
                    rules={[
                      {
                        required: true,
                        message: (
                          <div className="normal-12">Informe o número!</div>
                        ),
                      },
                    ]}
                  >
                    <Input placeholder="Digite o número" />
                  </Form.Item>
                </Col>

                <Col sm={24} xs={24} md={12}>
                  <Form.Item
                    name={["address", "complement"]}
                    label="Complemento"
                  >
                    <Input placeholder="Digite o complemento" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Divider />
      </Form>
    </Modal>
  );
};


export default EditClientModal;
