import { Tooltip } from "antd"
import React, { FunctionComponent } from "react"
import { GraphPersonIcon } from "./components/GraphPersonIcon"
import { CircleCompanyIcon } from "./components/CircleCompanyIcon"
// @ts-ignore
import { ReactComponent as CompanyIcon } from "../../images/company.svg";

import { 
  FileExcelOutlined, 
  FilePdfOutlined, 
  ShareAltOutlined, 
  InfoCircleOutlined, 
  LeftCircleOutlined,
  DownCircleOutlined,
  ArrowDownOutlined,
  UpCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  FileZipOutlined,
  DownloadOutlined,
  UserOutlined,
  EyeOutlined,
  SendOutlined,
  ShoppingCartOutlined,
  ClockCircleOutlined,
  SearchOutlined,
  EditOutlined,
  UserAddOutlined,
  CopyOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
  PaperClipOutlined,
  FlagOutlined,
  DashboardOutlined,
  FilePptOutlined,
  TeamOutlined,
  StockOutlined,
  DollarOutlined,
  ExceptionOutlined,
  FileDoneOutlined,
  AlignCenterOutlined,
  NodeIndexOutlined,
  ContainerOutlined,
  CheckOutlined,
  CheckSquareOutlined,
  AuditOutlined,
  BarcodeOutlined,
  FileSyncOutlined,
  CarOutlined,
  IeOutlined,
  GoogleOutlined,
} from "@ant-design/icons"
import { WhatsappIcon } from "./components/WhatsappIcon"
import { TooltipPlacement } from "antd/lib/tooltip"

interface IconsProps {
  icon: string, 
  title?: string | undefined, 
  placement?: TooltipPlacement | undefined, 
  className?: string | undefined,
  onClick?: (e: any) => void
  spin?: boolean | undefined,
  style?: any,
}

const Icons: FunctionComponent<IconsProps> = ({ icon, title, placement, className, ...props }) => {
  return (
    <Tooltip title={title} placement={placement}>
      <Icon
        icon={icon}
        className={`inquest-icon ${icon}-icon ${className ?? ""}`}
        {...props}
      />
    </Tooltip>
  )
}

interface IconProps {
  icon: string,
  className: string,
  spin?: boolean | undefined,
}

export const Icon: FunctionComponent<IconProps> = ({ icon, ...props }) => {
  switch (icon) {
    case "graph-person":
      return <GraphPersonIcon {...props} />
    case "circle-company":
        return <CircleCompanyIcon {...props} />
    case "excel-file":
      return <FileExcelOutlined {...props} />
    case "pdf-file":
      return <FilePdfOutlined {...props} />
    case "zip-file":
      return <FileZipOutlined {...props} />
    case "clips":
      return <PaperClipOutlined {...props} />
    case "flag":
      return <FlagOutlined {...props} />  
    case "graph":
      return <ShareAltOutlined {...props} />
    case "info-cpf":
      return <UserOutlined {...props} />
    case "orientations":
        return <InfoCircleOutlined {...props} />
    case "parecer":
        return <DashboardOutlined {...props}/>
    case "info-circle":
      return <InfoCircleOutlined {...props} />
    case "processo":
        return <FilePptOutlined {...props} />
    case "ecosystem":
      return <NodeIndexOutlined {...props}/>
    case "score":
        return <StockOutlined {...props}/>
    case "protesto":
      return <ExceptionOutlined {...props} />
    case "veiculos":
      return <CarOutlined  {...props}/>
    case "company":
      return <CompanyIcon  {...props}/>
    case "debito":
      return <DollarOutlined {...props}/>
    case "sintegra":
      return <FileDoneOutlined {...props}/>
    case "tst":
      return <FileSyncOutlined {...props}/>
    case "marcas":
      return <BarcodeOutlined {...props}/>
    case "patentes":
      return <AuditOutlined {...props}/>
    case "noticias":
      return <GoogleOutlined {...props}/>
    case "sites":
      return <IeOutlined {...props}/>
    case "midias-sociais":
      return <TeamOutlined {...props}/>
    case "atos-societarios":
      return <ContainerOutlined {...props}/>
    case "left-arrow-circle":
      return <LeftCircleOutlined {...props} />
    case "down-arrow-circle":
      return <DownCircleOutlined {...props} />
    case "up-arrow-circle":
      return <UpCircleOutlined {...props} />
    case "down-arrow":
      return <ArrowDownOutlined {...props} />
    case "checked-circle":
      return <CheckCircleOutlined {...props} />
    case "circle-alert":
      return <ExclamationCircleOutlined {...props}/>
    case "circle-plus":
      return <PlusCircleOutlined {...props}/>
    case "plus":
      return <PlusOutlined {...props}/>
    case "copy":
      return <CopyOutlined {...props}/>
    case "loading":
      return <SyncOutlined {...props} />
    case "download":
      return <DownloadOutlined {...props} />
    case "user-profile":
      return <UserOutlined {...props} />
    case "whatsapp":
      return <WhatsappIcon {...props} />
    case "eye":
      return<EyeOutlined {...props} />
    case "delete":
      return <DeleteOutlined {...props} />
    case "add-user":
      return<UserAddOutlined {...props} />
    case "edit":
      return <EditOutlined {...props} />  
    case "send":
      return <SendOutlined {...props} />
    case "cart-shopping":
      return <ShoppingCartOutlined {...props} />
    case "question":
      return <QuestionCircleOutlined {...props} />  
    case "clock":
        return <ClockCircleOutlined {...props} />
    case "search":
        return <SearchOutlined {...props} />
    case "close-circle":
      return <CloseCircleOutlined {...props} />
    default:
      return <span>{icon}</span>
  }
}

export default Icons
