import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { buildFileReport, getReport, getReportFiles } from "./service";
import StateStatus from "../../../../utils/stateStatus";
import { BuildFileReportArgs, BuildFileReportResponse, GetReportResponse, ListReportFilesResponse, ReportData, ReportFilesType } from "./interfaces";


interface ReportState {
    status: {
        getReport: StateStatus;
        listReportFiles: StateStatus;
        buildFileReport: StateStatus;
    };
    data: {
        report: ReportData | undefined | null;
        files: ReportFilesType | undefined | null;
    };
    errMessage: {
        getReport: string | null;
        listReportFiles: string | null;
        buildFileReport: string | null;
    };
}

const initialState: ReportState = {
    status: {
        getReport: StateStatus.idle,
        listReportFiles: StateStatus.idle,
        buildFileReport: StateStatus.idle,
    },
    data: {
        report: null,
        files: null,
    },
    errMessage: {
        getReport: null,
        listReportFiles: null,
        buildFileReport: null,
    }
};

// Tipos dos retornos dos Thunks


// Thunks com Tipagem
export const getReportThunk = createAsyncThunk<GetReportResponse, string>(
    "report-page/get",
    async (reportId) => {
        const response = await getReport(reportId);
        return response.data;
    }
);

export const listReportFilesThunk = createAsyncThunk<ListReportFilesResponse, string>(
    "report-page/list-files",
    async (reportId) => {
        const response = await getReportFiles(reportId);
        return response.data;
    }
);

export const buildFileReportThunk = createAsyncThunk<BuildFileReportResponse, BuildFileReportArgs>(
    "report-page/build-file-target",
    async ({ reportId }) => {
        const response = await buildFileReport(reportId);
        return response.data;
    }
);

export const reportSlice = createSlice({
    name: "report-page-slice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // getReportThunk
            .addCase(getReportThunk.pending, (state) => {
                state.status.getReport = StateStatus.loading;
            })
            .addCase(getReportThunk.fulfilled, (state, action) => {
                state.data.report = action.payload;
                state.status.getReport = StateStatus.succeeded;
            })
            .addCase(getReportThunk.rejected, (state, action) => {
                state.status.getReport = StateStatus.failed;
                state.errMessage.getReport = action.error.message || null;
            })
            // buildFileReportThunk
            .addCase(buildFileReportThunk.pending, (state) => {
                state.status.buildFileReport = StateStatus.loading;
            })
            .addCase(buildFileReportThunk.fulfilled, (state) => {
                state.status.buildFileReport = StateStatus.succeeded;
            })
            .addCase(buildFileReportThunk.rejected, (state, action) => {
                state.status.buildFileReport = StateStatus.failed;
                state.errMessage.buildFileReport = action.error.message || null;
            })
            // listReportFilesThunk
            .addCase(listReportFilesThunk.pending, (state) => {
                state.status.listReportFiles = StateStatus.loading;
            })
            .addCase(listReportFilesThunk.fulfilled, (state, action) => {
                state.data.files = action.payload;
                state.status.listReportFiles = StateStatus.succeeded;
            })
            .addCase(listReportFilesThunk.rejected, (state, action) => {
                state.status.listReportFiles = StateStatus.failed;
                state.errMessage.listReportFiles = action.error.message || null;
            });
    }
});

// Seletor com tipagem
export const selectReportPageState = (state: { report: ReportState }) => state.report;
