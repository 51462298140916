
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDataThunk, selectTargetPageState } from "../../slice";
import { Col, Row, Table } from "antd";
import localeValues from "antd/locale/pt_BR";
import StateStatus from "../../../../../../utils/stateStatus";
import Icons from "../../../../../../common/components/Icons";
import { TabContainer } from "../../components/tab-container";
import { ServicesEnum } from "../../../../../../utils/services";

const MAP_SECTIONS = {
  grupo_economico_cnpj:  {title: "Grupo econômico"},
}

interface TabContentReceitaFederalProps {
  sectionKey: string, 
}

const TabContentReceitaFederal: FunctionComponent<TabContentReceitaFederalProps> = ({ sectionKey }) => {
  const serviceKey = ServicesEnum.receita_federal.name;

  const state = useSelector(selectTargetPageState);
  const dispatch = useDispatch();

  const serviceData = state.data.target?.services ? state.data.target?.services[serviceKey] : null;
  const sectionData = serviceData?.sections && serviceData.sections[sectionKey] ? serviceData.sections[sectionKey] : null;

  useEffect(() => {
    if (serviceData?.dossier_id && serviceData?.service_status === "completed" && !sectionData?.data?.pagination?.current_page) {
      dispatch(
        getServiceDataThunk({
          service: serviceKey,
          section: sectionKey,
          dossierId: serviceData?.dossier_id,
          page: 1,
        })
      );
    }
  }, [serviceData?.dossier_id]);

  						

  const columns = [
    {
      title: "EMPRESA DO GRUPO",
      dataIndex: "razao_social",
    },
    {
      title: "QSA: SÓCIOS E/OU ADMINISTRADORES",
      dataIndex: "qsa",
      render: (values, record) => {
        return (
          <>
            {values?.map((socio) => (
              <div style={{marginBottom: "10px"}}>
                <div><strong>{socio.name}</strong></div>
                <div>{socio.qualificacao_socio}</div>
              </div>
            ))}
          </>
        );
      },
    },
    {
      title: "OBJETO SOCIAL",
      dataIndex: "desc_cnae_fiscal",
      render: (value, record) => {
        return (
          value ? (
            <div>{value.codigo} - {value.descricao}</div>
          ) : null
        )
      }
    },
    {
      title: "CNAE(S) SECUNDÁRIA(S)",
      dataIndex: "cnae_secundaria",
      render: (values, record) => {
        return (
          <ul>
            {values?.map((value, idx) => (
              <li key={idx}>{value.codigo} - {value.descricao}</li>
            ))}
          </ul>
        );
      },
    },
    {
      title: "ENDEREÇO",
      
      dataIndex: "address_formatted",
    },
    {
      title: "COINCIDÊNCIAS",
      width: "5%",
      dataIndex: "qsa",
      render: (values, record) => {
        return (
          <div>
            {values ? values?.reduce((a, b) => b.matches ? a + 1 : a, 0) : null}
          </div>
        );
      },
    },
    {
      title: "RELAÇÃO COM A PESQUISADA",
      dataIndex: "explain_relation",
      render: (values, record) => {
        return (
          <ul>
            {values?.map((relation, idx) => (
              <li key={idx}>{relation}</li>
            ))}
          </ul>
        );
      },
    },
  ];

  return (
    <TabContainer
      icon={<Icons icon={"clips"} />}
      title={MAP_SECTIONS[sectionKey]?.title}
    >
      <Row>
        <Col span={24}>
          <div className="service-label-value inline">
            <label>Total de empresas</label>
            <div>{sectionData?.data?.pagination?.total_items ?? "-"}</div>
          </div>
        </Col>
        <Col span={24}>
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={sectionData?.data?.items ?? []}
            loading={sectionData?.status === StateStatus.loading}
            pagination={{
              showSizeChanger: false,
              current: sectionData?.data?.pagination?.current_page,
              pageSize: sectionData?.data?.pagination?.per_page,
              total: sectionData?.data?.pagination?.total_items,
              onChange: (page, pageSize) => {
                dispatch(
                  getServiceDataThunk({
                    service: serviceKey,
                    dossierId: serviceData.dossier_id,
                    section: sectionKey,
                    page: page,
                  })
                );
              },
            }}
            locale={{emptyText: 'Este pesquisado(a) não possui grupo econômico'}}
          />
        </Col>
      </Row>
    </TabContainer>
  );
};

export { TabContentReceitaFederal };
