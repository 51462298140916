
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDataThunk, selectTargetPageState } from "../../slice";
import { Col, Row, Table } from "antd";
import StateStatus from "../../../../../../utils/stateStatus";
import Icons from "../../../../../../common/components/Icons";
import { TabContainer } from "../../components/tab-container";
import { ServicesEnum } from "../../../../../../utils/services";

const MAP_SECTIONS = {
  processos_em_tramitacao_por_cpf_cnpj:  {title: "Processos em tramitação por Nome"},
  processos_em_tramitacao_por_nome: {title: "Processos em tramitação por CPF/CNPJ"},
  processos_inativos_por_nome: {title: 'Prcessos arquivados por nome'},
  processos_inativos_por_cpf_cnpj: {title: 'Processos arquivados por CPF/CNPJ'},
  processos_para_aquisicao_copia: {title: 'Processos relevantes'},
}

interface TabContentProcessosProps {
  sectionKey: string, 
}

const TabContentProcessos: FunctionComponent<TabContentProcessosProps> = ({ sectionKey }) => {
  const serviceKey = ServicesEnum.processos.name;
  const state = useSelector(selectTargetPageState);
  const dispatch = useDispatch();

  const serviceData = state.data.target?.services ? state.data.target?.services[serviceKey] : null;
  const sectionData = serviceData?.sections && serviceData.sections[sectionKey] ? serviceData.sections[sectionKey] : null;

  useEffect(() => {
    if (serviceData?.dossier_id && serviceData?.service_status === "completed" && !sectionData?.data?.pagination?.current_page) {
      dispatch(
        getServiceDataThunk({
          service: serviceKey,
          section: sectionKey,
          dossierId: serviceData?.dossier_id,
          page: 1,
        })
      );
    }
  }, [serviceData?.dossier_id]);

  

  const columns = [
    {
      title: "Processo",
      dataIndex: "distribution_date",
      render: (_, record) => {
        return <div>
          <p style={{fontSize: '14px', fontWeight: 500, marginBottom: '0'}}>{record.number}</p>
          <p style={{ marginBottom: '0'}}>Distribuição: {record.distribution_date}</p>
          <p style={{ marginBottom: '0'}}>Órgão Julgador: {record.court}</p>
          <p style={{ marginBottom: '0'}}>Área do Direito: {record.kind}</p>
        </div>
      },
      width: '250px'
    },
    {
      title: "Valor Declarado",
      dataIndex: "declared_value",
      width: '150px',
      render: (value, record) => {
        if (record.pole === 'INDIRETO') {
          return (<div style={{display: 'flex', flexDirection: 'column'}}>
            <div>{value}</div>
            <div style={{fontSize: '12px'}}>indireto/incidente</div>
          </div>)
        }
        
        if (record.pole === 'ATIVO') {
          return (<div style={{color: 'green', display: 'flex', flexDirection: 'column'}}>
            <div>{value}</div>
            <div style={{fontSize: '12px'}}>ativo financeiro</div>
          </div>)
        }

        return (<div style={{color: 'red', display: 'flex', flexDirection: 'column'}}>
          <div>{value}</div>
          <div style={{fontSize: '12px'}}>passivo financeiro</div>
        </div>)
      }
    },
    {
      title: "Natureza",
      dataIndex: "nature",
    },
    {
      title: "Assunto",
      dataIndex: "subject",
    },
    {
      title: 'Probabilidade de ser da pessoa pesquisada',
      dataIndex: "probability_description",
      render: (value) => {
       if (value.includes("Baixa")) {
        return <span style={{color: '#DCBE14'}}>{value}</span>
       }
       if (value.includes("Alta")) {
        return <span style={{color: '#DC6E14'}}>{value}</span>
       }

       return value
      }
    },
    {
      title: "Figuram um pesquisado principal e outra(s) pessoa(s) no polo passivo do processo",
      dataIndex: "has_target_and_others_polo_passivo",
      render: (value) => {
        if (value) {
          return <span style={{color: '#007AFF'}}>SIM</span>
        }

        return <span>NÃO</span>
      }
    },
    {
      title: "Pessoas Relacionadas",
      dataIndex: "related_people",
      width: "25%",
      render: (value, _) => {
        return (
          <div>
            {value.map((person) => (
              <p>
                {person.is_target ? (
                  <span style={{color: '#009BED', fontWeight: 500}}>
                    {person.name} ({person.role})
                  </span>
                ) : (
                  <>
                    {person.name} ({person.role})
                  </>
                )}
              </p>
            ))}
          </div>
        );
      },
    },
  ];

  return (
    <TabContainer
      icon={<Icons icon={"processo"} />}
      title={MAP_SECTIONS[sectionKey]?.title}
    >
      <Row>
        <Col span={24}>
          <div className="service-label-value inline">
            <label>Total de processos</label>
            <div>{sectionData?.data?.pagination?.total_items ?? "-"}</div>
          </div>
        </Col>
        <Col span={24}>
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={sectionData?.data?.items ?? []}
            loading={sectionData?.status === StateStatus.loading}
          
            pagination={{
              showSizeChanger: false,
              current: sectionData?.data?.pagination?.current_page,
              pageSize: sectionData?.data?.pagination?.per_page,
              total: sectionData?.data?.pagination?.total_items,
              onChange: (page, pageSize) => {
                dispatch(
                  getServiceDataThunk({
                    service: serviceKey,
                    dossierId: serviceData.dossier_id,
                    section: sectionKey,
                    page: page,
                  })
                );
              },
            }}
            locale={{emptyText: 'Este pesquisado(a) não possui processos'}}
          />
        </Col>
      </Row>
    </TabContainer>
  );
};

export { TabContentProcessos };
