import React, { FunctionComponent } from "react";
import { Form, Checkbox } from "antd";

interface CustomServicesOptionsProps {
  name: string, 
  label?: string | undefined, 
}

const CustomServicesOptions: FunctionComponent<CustomServicesOptionsProps> = ({ name, label }) => {
  const options = [
    {
      label: "Veículos",
      value: "veiculos"
    },
    { 
      label: "Processos",
      value: "processos" 
    },
    {
      label: "Score, negativações e protestos",
      value: "boavista"
    }
  ]
  
  return (
      <Form.Item
        name={name}
        label={label}
        rules={[
          () => ({
            validator(_, value) {
              if (!value || value?.length < 1)
                return Promise.reject(
                  "Selecione pelo menos 1 opção de busca acima"
                );

              return Promise.resolve();
            },
          }),
        ]}
      >
        <Checkbox.Group options={options} style={{ marginBottom: "15px" }}>
          {/* <Row>
            <Col span={24} className="checkbox-item">
              <Checkbox value="veiculos">
                Veículos{" "}
              </Checkbox>
            </Col>
            <Col span={24} className="checkbox-item">
              <Checkbox value="processos">
                Processos{" "}
              </Checkbox>
            </Col>
            <Col span={24} className="checkbox-item">
              <Checkbox value="boavista">
                Score, Negativaçoes e Protestos{" "}
              </Checkbox>
            </Col>
          </Row> */}
        </Checkbox.Group>
      </Form.Item>
  );
};


export default CustomServicesOptions;