import "./styles.scss";
import React, { FunctionComponent, ReactNode } from "react";
import { Steps, StepProps } from "antd";

interface StepperItemsProp {
  title?: ReactNode,
  description?: ReactNode,
}

interface StepperProp {
  current: Number,
  items: StepperItemsProp[]
}

const Stepper: FunctionComponent<StepperProp> = ({current, items}) => {
  return (
    <Steps
      className="stepper"
      current={current as number}
      items={items?.map((x) => (
        {
            title: x.title,
            description: x.description,
            // subTitle: x.subTitle,
        }
      ))}
    ></Steps>
  );
};

export default Stepper
