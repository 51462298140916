
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDataThunk, selectTargetPageState } from "../../slice";
import { Col, Row, Table } from "antd";
import localeValues from "antd/locale/pt_BR";
import StateStatus from "../../../../../../utils/stateStatus";
import Icons from "../../../../../../common/components/Icons";
import { TabContainer } from "../../components/tab-container";
import { ServicesEnum } from "../../../../../../utils/services";
import { ProgressBar } from "../../components/progress-bar";
import "./styles.scss";
import { TabContentScores } from "../boavista_scores";
import { TabContentProtestos } from "../boavista__protestos";
import { TabContentDebitos } from "../boavista__debitos";
import { TabContentCenprot } from "../cenprot";

const TabContentBoavista = () => {

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <TabContentScores />
        </Col>
        <Col span={24}>
          <TabContentProtestos />
        </Col>
        <Col span={24}>
          <TabContentCenprot />
        </Col>
        <Col span={24}>
          <TabContentDebitos />
        </Col>
      </Row>
    </div>
  );
};

export { TabContentBoavista };
