import React from "react"
import { Route, Routes } from "react-router-dom"
import AddTargetsPage from "../features/addTargets"
import GraphPage from "../features/graph"
import { TermsUse } from "../features/dashborad/terms_use"
import TargetStatusPage from "../features/target_status_page"
import LoginPage, {Logout} from "../features/login"
import ForgotPasswordPage from "../features/forgot_password"
import NewAccountPage from "../features/new_account"
import ChangePassword from "../features/change_password"
import Dashboard from "../features/dashborad"
import CreateReportUnauthPage from "../features/create_report_unauth_page"
import MonitoringPage from "../features/dashborad/pages/monitoring_page"
import ReportPage from "../features/dashborad/pages/report-page"
import { TargetPage } from "../features/dashborad/pages/target-page"

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/report/:reportId" element={<ReportPage />} />
      <Route path="/create-report/:caseType/:optionId" element={<CreateReportUnauthPage />} />
      <Route path="/report/:reportId/update" element={<AddTargetsPage />} />
      <Route path="/report/:reportId/target/:targetId" element={<ReportPage />} />
      <Route path="/report/:reportId/graph" element={<GraphPage />} />
      <Route path="/report/:reportId/status" element={<TargetStatusPage />} />
      <Route path="/monitoring" element={<MonitoringPage />} />
      <Route path="/termos" element={<TermsUse />} />

      <Route path="/" element={<LoginPage/>}/>
      <Route path="/checkout/:token" element={<LoginPage/>}/>
      <Route path="/logout" element={<Logout/>}/>
      <Route path="/esqueci-minha-senha" element={<ForgotPasswordPage/>}/>
      <Route path="/nova-conta/:page" element={<NewAccountPage/>}/>
      <Route path="/alterar-senha/:token" element={<ChangePassword/>}/>


      <Route path="/dashboard/*" element={<Dashboard />} />
    </Routes>
  )
}
