import React, { FunctionComponent } from "react";

//
import "./styles.scss";
import { Row, Upload, UploadProps } from "antd";
import Icons from "../Icons";
import { UploadRef } from "antd/lib/upload/Upload";
import { UploadFile } from "antd/lib";

const { Dragger } = Upload;

interface FileUploadBoxProps {
  accept?: string | undefined, 
  label?:  string | undefined, 
  maxCount?: number | undefined,
  fileList: UploadFile[], 
  multiple: boolean | undefined,
  customRequest: (e: any) => void,
  beforeUpload: (e: any) => void,
  onRemove: (e: any) => void,
  onClick: (e: any) => void,
}

const FileUploadBox: FunctionComponent<FileUploadBoxProps> = ({ accept, label, maxCount, ...props }) => {
  return (
    <Dragger
      accept={accept}
      className="file-upload-box"
      maxCount={maxCount ?? 1}
      showUploadList={{
        showRemoveIcon: true,
        removeIcon: <Icons icon="delete" />,
      }}
      {...props}
    >
      <Row justify="center" className="icon">
        <Icons icon="download" />
      </Row>
      <Row justify="center" className="label">
        {label || "Solte seu arquivo aqui..."}
      </Row>
    </Dragger>
  );
};



export default FileUploadBox;
