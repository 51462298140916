import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import StateStatus from "../../utils/stateStatus";
import {
  getReportStatussStatus,
  retryService,
  createFileTarget,
  closeReport,
  resendEmail,
  deleteReportFile,
  deleteFileTarget,
  editReportFields,
  createReportFile,
  getTargetStatusStatus,
  nfseEmit,
  listNfse,
  sendToMonday
} from "./service";
import { CreateFileTargetArgs, CreateReportFileArgs, EditReportFieldsArgs, ReportStatusType, TargetStatusPageState, TargetStatusType } from "./interfaces";



export const getReportStatusThunk = createAsyncThunk<
  ReportStatusType,
  string
>("targetStatusPage/get", async (reportId) => {
  const response = await getReportStatussStatus(reportId);
  return response.data;
});

export const getTargetStatusThunk = createAsyncThunk<
  TargetStatusType,
  string
>("targetStatusPage/get-target", async (targetId) => {
  const response = await getTargetStatusStatus(targetId);
  return response.data;
});


export const closeReportThunk = createAsyncThunk<
  any,
  string
>("targetStatusPage/close", async (reportId) => {
  const response = await closeReport(reportId);
  return response.data;
});

export const nfseEmitThunk = createAsyncThunk<any, string>("targetStatusPage/nfse-emit", async (reportId) => {
  const response = await nfseEmit(reportId)
  return response.data
})

export const listNfseThunk = createAsyncThunk<any, string>("targetStatusPage/list-nfse", async (reportId) => {
  const response = await listNfse(reportId)
  return response.data
})

export const resendEmailThunk = createAsyncThunk<
  any,
  string
>("targetStatusPage/resend-email", async (reportId) => {
  const response = await resendEmail(reportId);
  return response.data;
});

export const retryServiceThunk = createAsyncThunk<
  any,
  string
>("targetStatusPage/retry", async (serviceId) => {
  const response = await retryService(serviceId);
  return response.data;
});

export const sendToMondayThunk = createAsyncThunk<
  any,
  string
>("targetStatusPage/sent-to-monday", async (reportId) => {
  const response = await sendToMonday(reportId);
  return response.data;
});

export const createReportFileThunk = createAsyncThunk<
  any,
  CreateReportFileArgs
>("targetStatusPage/create-report-files", async ({ reportId, type }) => {
  const response = await createReportFile(reportId, type);
  return response.data;
});

export const deleteReportFileThunk = createAsyncThunk<
  any,
  string
>("targetStatusPage/delete-report-file", async (fileId) => {
  const response = await deleteReportFile(fileId);
  return response.data;
});

export const createFileTargetThunk = createAsyncThunk<
  any,
  CreateFileTargetArgs
>("targetStatusPage/create-target-file", async ({ targetId, type }) => {
  const response = await createFileTarget(targetId, type);
  return response.data;
});

export const deleteFileTargetThunk = createAsyncThunk<
  any,
  string
>("targetStatusPage/delete-target-file", async (fileId) => {
  const response = await deleteFileTarget(fileId);
  return response.data;
});

export const editReportFieldsThunk = createAsyncThunk<
ReportStatusType, EditReportFieldsArgs
>("targetStatusPage/edit-report", async (data: EditReportFieldsArgs) => {
  const response = await editReportFields(data);
  return response.data;
});


const initialState: TargetStatusPageState = {
  status: {
    getReportStatus: StateStatus.idle,
    getTargetStatus: StateStatus.idle,
    retryService: StateStatus.idle,
    createReportFile: StateStatus.idle,
    deleteReportFile: StateStatus.idle,
    buildPdfTarget: StateStatus.idle,
    createFileTarget: StateStatus.idle,
    deleteFileTarget: StateStatus.idle,
    closeReport: StateStatus.idle,
    resendEmail: StateStatus.idle,
    editReportFields: StateStatus.idle,
    nfseEmit: StateStatus.idle,
    listNfse: StateStatus.idle,
  },
  data: {
    retryServiceId: null,
    retryTargetId: null,
    deleteFileId: null,
    report: null,
    reportFilesUploading: [],
    nfs: [],
  },
  errMessage: {
    getReportStatus: null,
    getTargetStatus: null,
    retryService: null,
    createReportFile: null,
    buildPdfTarget: null,
    createFileTarget: null,
    resendEmail: null,
    editReportFields: null,
    closeReport: null,
    deleteReportFile: null,
    deleteFileTarget: null,
    nfseEmit: null,
    listNfse: null,
  }
};

export const targetStatusPageSlice = createSlice({
  name: "targetStatusPage",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = {
        getReportStatus: StateStatus.idle,
        getTargetStatus: StateStatus.idle,
        retryService: StateStatus.idle,
        createReportFile: StateStatus.idle,
        deleteReportFile: StateStatus.idle,
        buildPdfTarget: StateStatus.idle,
        createFileTarget: StateStatus.idle,
        deleteFileTarget: StateStatus.idle,
        closeReport: StateStatus.idle,
        resendEmail: StateStatus.idle,
        editReportFields: StateStatus.idle,
        nfseEmit: StateStatus.idle,
        listNfse: StateStatus.idle,
      };
    },
    setReportFilesUploading: (state, { payload }: PayloadAction<any[]>) => {
      state.data.reportFilesUploading = payload;
    },
    resetReportFilesUploading: (state) => {
      state.data.reportFilesUploading = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Report status
      .addCase(getReportStatusThunk.pending, (state) => {
        state.status.getReportStatus = StateStatus.loading;
      })
      .addCase(getReportStatusThunk.fulfilled, (state, { payload }) => {
        state.data.report = payload as ReportStatusType;
        state.status.getReportStatus = StateStatus.succeeded;
        state.status.retryService = StateStatus.idle;
      })
      .addCase(getReportStatusThunk.rejected, (state, { error }) => {
        state.status.getReportStatus = StateStatus.failed;
        state.errMessage.getReportStatus = error.message;
      })

      // Report status
      .addCase(nfseEmitThunk.pending, (state) => {
        state.status.nfseEmit = StateStatus.loading
      })
      .addCase(nfseEmitThunk.fulfilled, (state, { payload }) => {
        state.status.nfseEmit = StateStatus.succeeded
      })
      .addCase(nfseEmitThunk.rejected, (state, { error }) => {
        state.status.nfseEmit = StateStatus.failed
        state.errMessage.nfseEmit = error.message
      })

      .addCase(listNfseThunk.pending, (state) => {
        state.status.listNfse = StateStatus.loading
      })
      .addCase(listNfseThunk.fulfilled, (state, { payload }) => {
        state.data.nfs = payload.items
        state.status.listNfse = StateStatus.succeeded
      })
      .addCase(listNfseThunk.rejected, (state, { error }) => {
        state.status.listNfse = StateStatus.failed
        state.errMessage.listNfse = error.message
      })


      // Target status
      .addCase(getTargetStatusThunk.pending, (state) => {
        state.status.getTargetStatus = StateStatus.loading
      })
      .addCase(getTargetStatusThunk.fulfilled, (state, { payload }) => {
        const targets: TargetStatusType[] = []

        if(state.data.report) {
          state.data.report?.targets?.forEach(target => {
              if(target.id === payload.id) {
                  targets.push(payload)
              } else {
                  targets.push(target)
              }
          })
          
          state.data.report.targets = targets
        }
        state.status.getTargetStatus = StateStatus.succeeded
        state.status.retryService = StateStatus.idle
      })
      .addCase(getTargetStatusThunk.rejected, (state, { error }) => {
        state.status.getTargetStatus = StateStatus.failed
        state.errMessage.getTargetStatus = error.message
      })


      // Close report
      .addCase(closeReportThunk.pending, (state) => {
        state.status.closeReport = StateStatus.loading;
      })
      .addCase(closeReportThunk.fulfilled, (state) => {
        state.status.closeReport = StateStatus.succeeded;
      })
      .addCase(closeReportThunk.rejected, (state, { error }) => {
        state.status.closeReport = StateStatus.failed;
        state.errMessage.closeReport = error.message;
      })

      // Edit report fields
      .addCase(editReportFieldsThunk.pending, (state) => {
        state.status.editReportFields = StateStatus.loading;
      })
      .addCase(editReportFieldsThunk.fulfilled, (state) => {
        state.status.editReportFields = StateStatus.succeeded;
      })
      .addCase(editReportFieldsThunk.rejected, (state, { error }) => {
        state.status.editReportFields = StateStatus.failed;
        state.errMessage.editReportFields = error.message;
      })

      // Resend email
      .addCase(resendEmailThunk.pending, (state) => {
        state.status.resendEmail = StateStatus.loading;
      })
      .addCase(resendEmailThunk.fulfilled, (state) => {
        state.status.resendEmail = StateStatus.succeeded;
      })
      .addCase(resendEmailThunk.rejected, (state, { error }) => {
        state.status.resendEmail = StateStatus.failed;
        state.errMessage.resendEmail = error.message;
      })

      // Retry service
      .addCase(retryServiceThunk.pending, (state, { meta }) => {
        state.data.retryServiceId = meta.arg;
        state.status.retryService = StateStatus.loading;
      })
      .addCase(retryServiceThunk.fulfilled, (state) => {
        state.status.retryService = StateStatus.succeeded;
      })
      .addCase(retryServiceThunk.rejected, (state, { error }) => {
        state.status.retryService = StateStatus.failed;
        state.errMessage.retryService = error.message;
      })

      // Create report file
      .addCase(createReportFileThunk.pending, (state, { meta }) => {
        state.status.createReportFile = StateStatus.loading;
      })
      .addCase(createReportFileThunk.fulfilled, (state) => {
        state.status.createReportFile = StateStatus.succeeded;
      })
      .addCase(createReportFileThunk.rejected, (state, { error }) => {
        state.status.createReportFile = StateStatus.failed;
        state.errMessage.createReportFile = error.message;
      })

      // Create file target
      .addCase(createFileTargetThunk.pending, (state, action) => {
        state.data.retryTargetId = action.meta.arg.targetId;
        state.status.createFileTarget = StateStatus.loading;
      })
      .addCase(createFileTargetThunk.fulfilled, (state) => {
        state.status.createFileTarget = StateStatus.succeeded;
      })
      .addCase(createFileTargetThunk.rejected, (state, { error }) => {
        state.status.createFileTarget = StateStatus.failed;
        state.errMessage.createFileTarget = error.message;
      })

      // Delete report file thunk
      .addCase(deleteReportFileThunk.pending, (state, action) => {
        state.data.deleteFileId = action.meta.arg;
        state.status.deleteReportFile = StateStatus.loading;
      })
      .addCase(deleteReportFileThunk.fulfilled, (state) => {
        state.status.deleteReportFile = StateStatus.succeeded;
      })
      .addCase(deleteReportFileThunk.rejected, (state, { error }) => {
        state.status.deleteReportFile = StateStatus.failed;
        state.errMessage.deleteReportFile = error.message;
      })

      // Delete file target thunk
      .addCase(deleteFileTargetThunk.pending, (state, action) => {
        state.data.deleteFileId = action.meta.arg;
        state.status.deleteFileTarget = StateStatus.loading;
      })
      .addCase(deleteFileTargetThunk.fulfilled, (state) => {
        state.status.deleteFileTarget = StateStatus.succeeded;
      })
      .addCase(deleteFileTargetThunk.rejected, (state, { error }) => {
        state.status.deleteFileTarget = StateStatus.failed;
        state.errMessage.deleteFileTarget = error.message;
      });
  }
});

export const { 
    resetStatus, 
    setReportFilesUploading, 
    resetReportFilesUploading 
} = targetStatusPageSlice.actions

export const selectReportStatusState = (state: { targetStatusPage: TargetStatusPageState }) => state.targetStatusPage;