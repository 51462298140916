import React, { FunctionComponent, ReactNode } from "react";
import PropTypes from "prop-types";
//
import { Select as AntSelect } from "antd";
import "./index.scss";
import { BaseOptionType } from "antd/es/select";

// const { Option } = AntSelect;


export interface ItemType {
  label: string,
  value: string,
}

export interface CaseTypeObj {
  preventivo: ItemType,
  simples: ItemType,
  docs: ItemType,
  smart: ItemType,
  smart_pro: ItemType,
  custom: ItemType,
  mapa_calor: ItemType,
}

const CASE_TYPES: CaseTypeObj = {
  preventivo: {value: "preventivo", label: "REPORT PREVENTIVO"},
  simples: {value: "simples", label: "REPORT SIMPLES"},
  docs: {value: "docs", label: "DOCS"},
  smart: {value: "smart", label: "REPORT SMART"},
  smart_pro: {value: "smart_pro", label: "REPORT SMART PRO"},
  custom: {value:"custom", label: "REPORT PERSONALIZADO"},
  mapa_calor: {value: "mapa_calor", label: "MAPA DE CALOR"},
}

export interface OptionDataType {
  case_type: ItemType,
  context?: string | null,
  price?: number,
  doc?: string,
  exclude_states?: string[],
  states?:  string[],
}

export interface OptionType {
  key?: number,
  label: string,
  value: number,
  data: OptionDataType
}

export interface SearchOptionType {
  label: string | undefined,
  options: OptionType[],
}

const searchOptions: SearchOptionType[] = [
  {
    label: "Verificar antes",
    options: [
      { label: "Contratar colaborador (REPORT PREVENTIVO)", value: 0, data:  {case_type: CASE_TYPES.preventivo, context: "human_resources", price: 3500} },
      { label: "Parceiros, avalistas (REPORT PREVENTIVO)", value: 1, data:  {case_type: CASE_TYPES.preventivo, context: "credit_analysis", price: 3500} },
      { label: "Ser sócio de alguém (REPORT PREVENTIVO)", value: 2, data:  {case_type: CASE_TYPES.preventivo, context: "coringa", price: 3500} },
      { label: "Contratar fornecedor (REPORT PREVENTIVO)", value: 3, data:  {case_type: CASE_TYPES.preventivo, context: "coringa", price: 3500} },
      { label: "Comprar ou vender imóvel (REPORT PREVENTIVO)", value: 4, data:  {case_type: CASE_TYPES.preventivo, context: "credit_analysis", price: 3500} },
      { label: "Compliance (REPORT PREVENTIVO)", value: 5, data:  {case_type: CASE_TYPES.preventivo, context: "coringa", price: 3500} },
    ],
  },
  {
    label: "Resolver",
    options: [
      { label: "Buscar ativos (busca de imóveis apenas em SP) (REPORT SIMPLES)", value: 20, key: 20, data:  {case_type: CASE_TYPES.simples, context: "coringa", price: 6800} },
      { label: "Buscar ativos (pesquisa imobiliária por região do pesquisado) com recomendação técnica (REPORT SMART)", value: 21, key: 21, data:  {case_type: CASE_TYPES.smart, context: null, price: 58700} },
      { label: "Buscar ativos (imóveis, escrituras e procurações em todo Brasil) com recomendação técnica (REPORT SMART PRO)", value: 22, key: 22, data:  {case_type: CASE_TYPES.smart_pro, context: null, price: 99700} },
    ],
  },
  {
    label: "Busca de documentos",
    options: [
      { label: "Aquisição de matrícula de imóvel (DOCS)", value: 40, data: {case_type: CASE_TYPES.docs, context: "coringa", doc: "aquisicao_matricula", 
        exclude_states: ["AC", "AL", "TO", "SE", "RO", "PI", "RN", "RR", "PB", "AP"] } },

      { label: "Pesquisa imobiliária estadual (DOCS)", value: 41, data: {case_type: CASE_TYPES.docs, context: "coringa", doc: "pesquisa_imobiliaria_estadual", 
        states: ["RO", "DF", "MS", "SP", "RJ", "ES", "PR", "SC", "RS", "MT", "MG"] } },

      { label: "Pesquisa imobiliária por cartório (DOCS)", value: 42, data: {case_type: CASE_TYPES.docs, context: "coringa", doc: "pesquisa_imobiliaria_cartorio", 
        // exclude_states: ["DF", "MS", "ES", "RS", "RJ"] 
        states: ["PB", "RR", "PI", "CE", "TO", "AL", "AC", "BA", "MA", "PE", "PA", "RN", "GO", "SE", "AM", "AP"] 
      } },

      // { label: "Aquisição de escrituras e procurações (DOCS)", value: 43, data: {case_type: CASE_TYPES.docs, context: "coringa", doc: "aquisicao_escrituras_procuracoes", 
      //   states: ["BR", "SP"] } },

      { label: "Mapa de calor - Escrituras e procurações em todo o Brasil", value: 44, data: {case_type: CASE_TYPES.mapa_calor, context: "coringa", price: 41500} },
    ],
  },
  {
    label: "Outros",
    options: [{ label: "Montar minha pesquisa personalizada", value: 60, data: {case_type: CASE_TYPES.custom, context: "coringa"} }],
  },
];

interface SelectSearchTypeProp {
  title?: ReactNode,
  description?: ReactNode,
  onChange: (e: any) => void,
  placeholder?: string | undefined,
  defaultValue?: string | number | undefined,
  listHeight?: number | undefined
}

const SelectSearchType: FunctionComponent<SelectSearchTypeProp> = ({
  onChange,
  placeholder,
  defaultValue,
  listHeight
}) => {
  

  return (
    <AntSelect
      listHeight={listHeight}
      value={defaultValue}
      placeholder={placeholder ?? "Selecione uma opção"}
      className="select-search-type"
      onChange={(value, option) => {
        onChange(option)
      }}
      options={searchOptions}
    />
  );
};

export default SelectSearchType;
export {searchOptions, CASE_TYPES};
