
export interface OptionType {
  value: string,
  label: string,
}

export interface InfoCaseType {
  element: string,
  type?: string,
  required?: boolean,
  name?: string[],
  placeholder?: string,
  label?: string,
  tooltip?: string | null | undefined,
  options?: OptionType[],
}

// SIMPLE
const _simple: InfoCaseType[] = [
  {
    element: "form-list",
  },
];

//
// PRO SMART
const ps_credit_recovery: InfoCaseType[] = [
  // {
  //   element: "select-location",
  //   name: ["charge_and_credit_recovery", "focus_city"],
  // },
  {
    element: "input",
    type: "date",
    required: true,
    name: ["charge_and_credit_recovery", "contract_date"],
    placeholder: "Insira a data",
    label: "Data da assinatura do acordo",
    tooltip:
      "Data da celebração do contrato ou título de crédito que está sendo cobrado",
  },
  {
    element: "input",
    type: "date",
    required: true,
    name: ["charge_and_credit_recovery", "contract_due_date"],
    placeholder: "Insira a data",
    label: "Data do vencimento / inadimplemento",
    tooltip: "Data do vencimento do contrato ou do inadimplemento da dívida",
  },
  // {
  //   element: "input",
  //   type: "date",
  //   required: true,
  //   name: ["charge_and_credit_recovery", "debt_protest_date"],
  //   placeholder: "Insira a data",
  //   label: "Data do protesto",
  //   tooltip:
  //     "Data do protesto da dívida e/ou da anotação no cadastro de proteção ao crédito e/ou envio de notificação extrajudicial de cobrança (se for o caso)",
  // },
  // {
  //   element: "input",
  //   type: "date",
  //   required: true,
  //   name: ["charge_and_credit_recovery", "process_distribution_date"],
  //   placeholder: "Insira a data",
  //   label: "Data da distribuição do processo",
  //   tooltip: "Data da distribuição do processo (se for o caso)",
  // },
  // {
  //   element: "input",
  //   type: "date",
  //   required: true,
  //   name: ["charge_and_credit_recovery", "process_copy_date"],
  //   placeholder: "Insira a data",
  //   label: "Data da distribuição",
  //   tooltip:
  //     "Envio das cópias (se o caso já tiver sido judicializado) (pode ser enviado por e-mail - monitoramento@inquest.tech)",
  // },
  {
    element: "input",
    type: "currency",
    required: true,
    name: ["charge_and_credit_recovery", "monetary_value"],
    placeholder: "Inserir valor",
    label: "Valor histórico da causa ou do crédito",
    tooltip:
      "Inserir aqui o valor histórico da causa ou, caso não tenha havido distribuição, o valor nominal do crédito",
  },
  // {
  //   element: "form-number-list",
  //   name: ["charge_and_credit_recovery", "processes"],
  // },
  // {
  //   element: "form-defendant-list",
  //   name: ["charge_and_credit_recovery", "defendants"],
  // },
  // {
  //   element: "input",
  //   type: "text",
  //   name: ["charge_and_credit_recovery", "warranty"],
  //   placeholder: "Insira as garantias",
  //   label: "Garantias prestadas",
  //   tooltip: "Houve alguma garantia prestada no contrato? Indicar quais foram.",
  // },
  {
    element: "input",
    type: "text",
    name: ["charge_and_credit_recovery", "observations"],
    placeholder: "Insira as observações adicionais",
    label: "Observações",
    tooltip:
      "Inserir aqui qualquer informação adicional e complementar que se considere relevante.",
  },
];

const ps_credit_recovery_rj: InfoCaseType[] = [
  // {
  //   element: "select-location",
  //   name: ["judicial_recovery", "focus_city"],
  // },
  // {
  //   element: "input",
  //   type: "text",
  //   name: ["judicial_recovery", "co_participants"],
  //   placeholder: "Insira os dados",
  //   label: "Devedores solidários",
  //   tooltip:
  //     "Há devedores solidários, avalistas, coobrigados etc.? Se sim, indicar nome, CPF/CNPJ e o documento que comprova a solidariedade/garantia pessoal.",
  // },
  // {
  //   element: "input",
  //   type: "text",
  //   name: ["judicial_recovery", "process_number"],
  //   placeholder: "Insira o número",
  //   label: "Processo de RJ ou Falência",
  //   tooltip:
  //     "Indicar o número do processo de recuperação ou falência e em que fase o processo está.",
  // },
  // {
  //   element: "input",
  //   type: "text",
  //   name: ["judicial_recovery", "current_stage"],
  //   placeholder: "Insira a fase",
  //   label: "Fase do processo",
  //   tooltip: "Indicar em qual fase o processo está.",
  // },
  {
    element: "input",
    type: "date",
    required: true,
    name: ["judicial_recovery", "contract_date"],
    placeholder: "Insira a data",
    label: "Data da assinatura do acordo",
    tooltip:
      "Data da celebração do contrato ou título de crédito que está sendo cobrado, com a comprovação documental (pode ser enviado por e-mail - monitoramento@inquest.tech)",
  },
  {
    element: "input",
    type: "date",
    required: true,
    name: ["judicial_recovery", "contract_due_date"],
    placeholder: "Insira a data",
    label: "Data do vencimento / inadimplemento",
    tooltip: "Data do vencimento do contrato ou do inadimplemento da dívida",
  },
  // {
  //   element: "input",
  //   type: "date",
  //   required: false,
  //   name: ["judicial_recovery", "debt_protest_date"],
  //   placeholder: "Insira a data",
  //   label: "Data do protesto",
  //   tooltip:
  //     "Data do protesto da dívida e/ou da anotação no cadastro de proteção ao crédito e/ou envio de notificação extrajudicial de cobrança (se for o caso)",
  // },
  {
    element: "input",
    type: "currency",
    required: true,
    name: ["judicial_recovery", "monetary_value"],
    placeholder: "Inserir valor",
    label: "Valor histórico da causa ou do crédito",
    tooltip:
      "Inserir aqui o valor histórico da causa ou, caso não tenha havido distribuição, o valor nominal do crédito",
  },
  // {
  //   element: "form-number-list",
  //   name: ["judicial_recovery", "processes"], // OBS: não há isso no postman
  // },
  // {
  //   element: "form-defendant-list",
  //   name: ["judicial_recovery", "defendants"],
  // },
  // {
  //   element: "input",
  //   type: "text",
  //   name: ["judicial_recovery", "warranty"],
  //   placeholder: "Insira as garantias",
  //   label: "Garantias prestadas",
  //   tooltip: "Houve alguma garantia prestada no contrato? Indicar quais foram.",
  // },
  {
    element: "input",
    type: "text",
    name: ["judicial_recovery", "observations"],
    placeholder: "Insira as observações adicionais",
    label: "Observações",
    tooltip:
      "Inserir aqui qualquer informação adicional e complementar que se considere relevante.",
  },
];

const ps_due_diligence_ma: InfoCaseType[] = [
  {
    element: "select-location",
    name: ["due_dilligence_merge_and_acquisition", "focus_city"],
  },
  {
    element: "input",
    type: "text",
    name: ["due_dilligence_merge_and_acquisition", "context_description"],
    placeholder: "Insira o contexto",
    label: "Contexto negocial",
    tooltip:
      "Qual é o motivo da investigação preventiva? Explicar o contexto da negociação que está acontecendo e as eventuais dúvidas e/ou pontos de atenção que se procura esclarecer com a investigação",
  },
  {
    element: "input",
    type: "date",
    required: true,
    name: ["due_dilligence_merge_and_acquisition", "future_contract_date"],
    placeholder: "Insira a data",
    label: "Data estimada para o negócio",
    tooltip: "Data em que as partes pretendem celebrar o novo contrato",
  },
  {
    element: "input",
    type: "currency",
    name: ["due_dilligence_merge_and_acquisition", "monetary_value"],
    placeholder: "Inserir valor",
    label: "Valor do negócio",
    tooltip:
      "Inserir aqui o valor histórico da causa ou, caso não tenha havido distribuição, o valor nominal do crédito",
  },
  {
    element: "input",
    type: "text",
    name: ["due_dilligence_merge_and_acquisition", "warranty"],
    placeholder: "Insira as garantias",
    label: "Garantias prestadas",
    tooltip: "Houve alguma garantia prestada no contrato? Indicar quais foram.",
  },
  {
    element: "input",
    type: "text",
    name: ["due_dilligence_merge_and_acquisition", "observations"],
    placeholder: "Insira as observações adicionais",
    label: "Observações",
    tooltip:
      "Inserir aqui qualquer informação adicional e complementar que se considere relevante.",
  },
];

const ps_preventive: InfoCaseType[] = [
  {
    element: "select-location",
    name: ["due_dilligence_general", "focus_city"],
  },
  {
    element: "input",
    type: "text",
    name: ["due_dilligence_general", "context_description"],
    placeholder: "Insira o contexto",
    label: "Contexto negocial",
    tooltip:
      "Qual é o motivo da investigação preventiva? Explicar o contexto da negociação que está acontecendo e as eventuais dúvidas e/ou pontos de atenção que se procura esclarecer com a investigação",
  },
  {
    element: "input",
    type: "text",
    name: ["due_dilligence_general", "objectives"],
    placeholder: "Insira o objeto",
    label: "Objeto do negócio",
    tooltip:
      "Objeto específico do contrato que as partes pretendem celebrar (ex.: compra da empresa XXX; investimento na start-up XPTO, Fornecedores, compra de imóvel de Matrícula n. xxx do 2º CRI de São Paulo/SP; contratação da pessoa Fulano de Tal como diretor de vendas etc.)",
  },
  {
    element: "input",
    type: "date",
    required: true,
    name: ["due_dilligence_general", "future_contract_date"],
    placeholder: "Insira a data",
    label: "Data estimada para o negócio",
    tooltip: "Data em que as partes pretendem celebrar o novo contrato",
  },
  {
    element: "input",
    type: "currency",
    name: ["due_dilligence_general", "monetary_value"],
    placeholder: "Inserir valor",
    label: "Valor do negócio",
    tooltip:
      "Valor do contrato que as partes pretendem celebrar (se for contrato mensal, inserir a soma das parcelas; se for superior a um ano, inserir o valor de doze parcelas).",
  },
  {
    element: "input",
    type: "text",
    name: ["due_dilligence_general", "warranty"],
    placeholder: "Insira as garantias",
    label: "Garantias a serem prestadas",
    tooltip:
      "Haverá alguma garantia a ser prestada no novo contrato? Se sim, indicar qual será esta garantia.",
  },
  {
    element: "input",
    type: "text",
    name: ["due_dilligence_general", "observations"],
    placeholder: "Insira as observações adicionais",
    label: "Observações",
    tooltip:
      "Inserir aqui qualquer informação adicional e complementar que se considere relevante.",
  },
];

const ps_compliance: InfoCaseType[] = [
  {
    element: "select-location",
    name: ["compliance", "focus_city"],
  },
  {
    element: "input",
    type: "text",
    name: ["compliance", "context_description"],
    placeholder: "Insira a denúncia",
    label: "Denúncia",
    tooltip:
      "Qual é o motivo da investigação? Explicar de forma objetiva a denúncia e a suspeita que existe no caso.",
  },
  {
    element: "input",
    type: "text",
    name: ["compliance", "context_history"],
    placeholder: "Insira o histórico",
    label: "Histórico ampliado",
    tooltip:
      "Construir a narrativa resumida do caso (incluindo os fatos denunciados ou suspeitos e o histórico dos principais investigados, incluindo a data em que entraram na empresa, eventuais incidentes envolvendo-os, personalidades etc.).",
  },
  {
    element: "input",
    type: "date",
    required: true,
    name: ["compliance", "relationship_start_date"],
    placeholder: "Insira a data",
    label: "Data da entrada na empresa ou do início do relacionamento",
    tooltip: null,
  },
  {
    element: "input",
    type: "date",
    required: true,
    name: ["compliance", "relationship_end_date"],
    placeholder: "Insira a data",
    label: "Data da saída da empresa ou do fim do relacionamento",
    tooltip: null,
  },
  // OBS: suspect_date nao existe  >
  {
    element: "input",
    type: "date",
    name: ["compliance", "suspect_date"],
    placeholder: "Insira a data",
    label: "Data do início da suspeita",
    tooltip: null,
  },
  // OBS: suspect_date nao existe  <
  {
    element: "input",
    type: "date",
    name: ["compliance", "start_date"],
    placeholder: "Insira a data",
    label: "Início do período de não-conformidade",
    tooltip:
      "Inserir aqui a data em que se estima que os fatos suspeitos de não-conformidade foram iniciados",
  },
  {
    element: "input",
    type: "date",
    required: true,
    name: ["compliance", "end_date"],
    placeholder: "Insira a data",
    label: "Data de saída",
    tooltip:
      "Data da saída do colaborador dos quadros da empresa; ou término da relação com aquela pessoa;ou data em que o suspeito foi confrontado diretamente com o fato de não-conformidade",
  },
  {
    element: "input",
    type: "text",
    name: ["compliance", "objectives"],
    placeholder: "Insira o objetivo e o escopo",
    label: "Objetivos e escopo",
    tooltip:
      "Objetivo da investigação (explicar neste campo a finalidade para a qual se pretende utilizar as informações, quais são os objetivos da investigação e o que se espera que a investigação revele).",
  },
  {
    element: "input",
    type: "text",
    name: ["compliance", "observations"],
    placeholder: "Insira as observações adicionais",
    label: "Observações",
    tooltip:
      "Inserir aqui qualquer informação adicional e complementar que se considere relevante.",
  },
];

const ps_divorce: InfoCaseType[] = [
  // {
  //   element: "select-location",
  //   name: ["divorce", "focus_city"],
  // },
  // {
  //   element: "input",
  //   type: "text",
  //   name: ["divorce", "history"],
  //   placeholder: "Insira o histórico",
  //   label: "História do casal",
  //   tooltip:
  //     "Narrar de forma objetiva a história do casal com os principais fatos (incluindo quando se conheceram, período de namoro, período de noivado, período de casamento, período de crise, período de separação de fato, período de divórcio e partilha, período posterior). A narrativa PRECISA incluir as principais razões do término, mesmo que forem pessoais ou desagradáveis, como extraconjugalidade, violência doméstica, desvio de patrimônio do casal, alcoolismo, vício em drogas etc (sigilo garantido).",
  // },
  {
    element: "input",
    type: "date",
    required: false,
    name: ["divorce", "relationship_start_date"],
    placeholder: "Insira a data",
    label: "Início do relacionamento",
    tooltip: "Inserir a data de início do relacionamento",
  },
  {
    element: "input",
    type: "date",
    required: true,
    name: ["divorce", "relationship_end_date"],
    placeholder: "Insira a data",
    label: "Fim do relacionamento",
    tooltip:
      "Inserir a data do final do relacionamento. Se a relação não terminou, inserir a data de hoje.",
  },
  // {
  //   element: "input",
  //   type: "text",
  //   name: ["divorce", "property_regime"],
  //   placeholder: "Insira o regime",
  //   label: "Regime de bens",
  //   tooltip:
  //     "Inserir o regime de bens do casal e a data do casamento (se possível, anexar ao final a certidão de casamento).",
  // },
  // {
  //   element: "input",
  //   type: "date",
  //   required: true,
  //   name: ["divorce", "marriage_date"],
  //   placeholder: "Insira a data",
  //   label: "Data do casamento",
  //   tooltip: null,
  // },
  {
    element: "input",
    type: "text",
    required: true,
    name: ["divorce", "known_property"],
    placeholder: "Insira o bens",
    label: "Bens conhecidos",
    tooltip: "Inserir bens ativos e passivos.",
  },
  // {
  //   element: "input",
  //   type: "text",
  //   name: ["divorce", "processes"],
  //   placeholder: "Insira a judicialização",
  //   label: "Judicialização",
  //   tooltip:
  //     "O caso já foi judicializado? Se sim, indicar todos os processos relacionados com o casal, incluindo o processo de divórcio, processo de guarda, processo de separação de corpos, alimentos etc., incluindo o número do processo, eventual senha e cópias.",
  // },
  // {
  //   element: "input",
  //   type: "text",
  //   name: ["divorce", "co_participans"],
  //   placeholder: "Insira a rede",
  //   label: "Rede principal de relacionamentos",
  //   tooltip:
  //     "Qual é a rede de relacionamentos do investigado? Quais são as pessoas que possivelmente possam ter ajudado o investigado(a) a ocultar ou desviar patrimônio?",
  // },
  // {
  //   element: "input",
  //   type: "text",
  //   name: ["divorce", "objectives"],
  //   placeholder: "Insira o objetivo e o escopo",
  //   label: "Objetivos e escopo",
  //   tooltip:
  //     "Objetivo da investigação (explicar neste campo a finalidade para a qual se pretende utilizar as informações, quais são os objetivos da investigação e o que se espera que a investigação revele).",
  // },
  {
    element: "input",
    type: "text",
    name: ["divorce", "observations"],
    placeholder: "Insira as observações adicionais",
    label: "Observações",
    tooltip:
      "Inserir aqui qualquer informação adicional e complementar que se considere relevante.",
  },
];

// TODO: falta bater as keys
const ps_inventory: InfoCaseType[] = [
  {
    element: "select-location",
    name: ["inventory", "focus_city"],
  },
  {
    element: "input",
    type: "text",
    name: ["inventory", "dead_name"],
    placeholder: "Insira o nome",
    label: "Nome do falecido",
    tooltip:
      "Preencher aqui o nome da pessoa que faleceu (de cujus, autor da herança ou sucessão)",
  },
  {
    element: "input",
    type: "cpf",
    name: ["inventory", "dead_cpf_and_name"],
    placeholder: "Insira o cpf",
    label: "CPF do falecido",
    tooltip:
      "Preencher aqui o CPF da pessoa que faleceu (de cujus, autor da herança ou sucessão)",
  },
  {
    element: "input",
    type: "date",
    required: true,
    name: ["inventory", "death_date"],
    placeholder: "Insira a data",
    label: "Data do óbito",
    tooltip: "Indicar aqui a data do óbito.",
  },
  {
    element: "input",
    type: "text",
    name: ["inventory", "processes"],
    placeholder: "Insira a judicialização",
    label: "Judicialização",
    tooltip:
      "O caso já foi judicializado? Se sim, indicar todos os processos relacionados com o casal, incluindo o processo de divórcio, processo de guarda, processo de separação de corpos, alimentos etc., incluindo o número do processo, eventual senha e cópias.",
  },
  {
    element: "input",
    type: "text",
    name: ["inventory", "known_property"],
    placeholder: "Insira o bens",
    label: "Bens conhecidos",
    tooltip: "Inserir bens ativos e passivos.",
  },
  {
    element: "input",
    type: "text",
    name: ["inventory", "successors"],
    placeholder: "Insira os sucessores",
    label: "Sucessores",
    tooltip: "Inserir quem são os sucessores (herdeiros e legatários",
  },
  {
    element: "input",
    type: "text",
    name: ["inventory", "creditors"],
    placeholder: "Insira os credores",
    label: "Credores",
    tooltip: null,
  },
  {
    element: "input",
    type: "text",
    name: ["inventory", "debtors"],
    placeholder: "Insira os devedores",
    label: "Devedores",
    tooltip: null,
  },
  {
    element: "select",
    options: [
      {
        label: "Comunhão total",
        value: "Comunhão total",
      },
      {
        label: "Comunhão parcial",
        value: "Comunhão parcial",
      },
      {
        label: "Separação convencional (total)",
        value: "Separação convencional (total)",
      },
      {
        label: "Superação obrigatória",
        value: "Superação obrigatória",
      },
      {
        label: "Participação final nos aquestos",
        value: "Participação final nos aquestos",
      },
    ],
    type: "text",
    name: ["inventory", "property_regime"],
    placeholder: "Selecione uma opção",
    label: "Regime de bens",
    tooltip:
      "Regime de bens do de cujus (Informar o regime de bens do de cujus (na hipótese de ter falecido casado ou com união estável). Se possível informar cartório, livro e folha em que está registrada a certidão de casamento; ou contrato de convivência; ou pacto antenupcial. Se esses arquivos estiverem disponíveis, anexar ao final)",
  },
  {
    element: "input",
    type: "text",
    name: ["inventory", "testament"],
    placeholder: "Insira o testamento",
    label: "Testamento",
    tooltip:
      "O de cujus deixou testamento conhecido? Se sim, indicar o cartório, livro e folha. Se o arquivo estiver disponível, anexar ao final.",
  },
  {
    element: "input",
    type: "text",
    name: ["inventory", "hidden_suspicious"],
    placeholder: "Insira as suspeitas",
    label: "Suspeitas de ocultamento",
    tooltip:
      "Há alguma razão para crer que alguém está escondendo ou ocultando patrimônio do de cujus? Justificar.",
  },
  {
    element: "input",
    type: "text",
    name: ["inventory", "family_situation"],
    placeholder: "Insira as suspeitas",
    label: "Situação familiar",
    tooltip:
      "Qual era a situação familiar que precedeu a morte do de cujus? (Ex.: havia rixas ou disputas familiares? Havia pessoas brigadas entre si? Se sim, quem? Por quê? Enfim, indicar qualquer informação sobre a situação familiar que possa ser útil para as pesquisas)",
  },
  {
    element: "input",
    type: "text",
    name: ["inventory", "objectives"],
    placeholder: "Insira o objetivo e o escopo",
    label: "Objetivos e escopo",
    tooltip:
      "Objetivo da investigação (explicar neste campo a finalidade para a qual se pretende utilizar as informações, quais são os objetivos da investigação e o que se espera que a investigação revele).",
  },
  {
    element: "input",
    type: "text",
    name: ["inventory", "observations"],
    placeholder: "Insira as observações adicionais",
    label: "Observações",
    tooltip:
      "Inserir aqui qualquer informação adicional e complementar que se considere relevante.",
  },
];

const ps_joker: InfoCaseType[] = [
  {
    element: "select-location",
    name: ["general", "focus_city"],
  },
  {
    element: "input",
    type: "text",
    name: ["general", "objectives"],
    placeholder: "Insira o objetivo e o escopo",
    label: "Objetivos e escopo",
    tooltip:
      "Objetivo da investigação (explicar neste campo a finalidade para a qual se pretende utilizar as informações, quais são os objetivos da investigação e o que se espera que a investigação revele).",
  },
  {
    element: "input",
    type: "text",
    name: ["general", "observations"],
    placeholder: "Insira as observações adicionais",
    label: "Observações",
    tooltip:
      "Inserir aqui qualquer informação adicional e complementar que se considere relevante.",
  },
];

//
// PREVENTIVE
const credit_analysis: InfoCaseType[] = [
  // {
  //     element: "select-location",
  //     name: ["credit_analysis", "focus_city"],
  // },
  {
    element: "input",
    type: "currency",
    name: ["credit_analysis", "credit_value"],
    placeholder: "Inserir valor",
    label: "Valor do crédito a ser concedido",
    tooltip:
      "Inserir aqui o valor do crédito a ser concedido ou o valor da operação do negócio.",
  },
  {
    element: "select",
    options: [
      {
        value: "curto",
        label: "Curto",
      },
      {
        value: "longo",
        label: "Longo",
      },
    ],
    type: "text",
    name: ["credit_analysis", "credit_deadline"],
    placeholder: "Selecione o prazo",
    label: "Prazo do crédito",
    tooltip:
      "Informar aqui se o crédito a ser concedido é de longo ou curto prazo. Curto Prazo = 6 meses ou menos Longo Prazo é + de 6 Meses.",
  },
  // {
  //     element: "select",
  //     options: [{
  //         value: true,
  //         label: "Sim"
  //     }, {
  //         value: false,
  //         label: "Não"
  //     }],
  //     type: "text",
  //     name: ["credit_analysis", "first_relationship"],
  //     placeholder: "Selecione uma opção",
  //     label: "É da primeira concessão de crédito deste cliente?",
  //     tooltip: "Informar se é a primeira vez que se faz negócio com essa parte."
  // },
  // {
  //     element: "input",
  //     type: "text",
  //     name: ["credit_analysis", "observations"],
  //     placeholder: "Insira as observações adicionais",
  //     label: "Observações",
  //     tooltip: "Inserir aqui qualquer informação adicional e complementar que se considere relevante."
  // },
];

//
// ALL
const _docs: InfoCaseType[] = [
  {
    element: "select-location",
    name: ["docs", "focus_city"],
  },
  {
    element: "input",
    type: "text",
    name: ["docs", "history"],
    placeholder: "Insira o histórico",
    label: "Pesquisa avulsa ou documento a ser solicitado",
    tooltip:
      "Informar qual é a pesquisa avulsa ou documento solicitado (EXEMPLOS.: aquisição da procuração de livro n. 3451, fl. 244, do 1º Tabelião de Notas de São Paulo/SP; ou realização de pesquisa de escrituras e procurações do Fulano de Tal, CPF xxx.xxx.xxx-xx; ou realização de pesquisa de imóveis em nome da empresa XPTO S/A, CNPJ xx.xxx.xxx/0001-24, na cidade de Belo Horizonte/MG; ou realização de diligência in loco para obtenção de todos do haras Villa do Descanso, em Boituva/SP; ou aquisição de todos os atos societários das empresa MAÇÃ S/A, CNPJ xx.xxx.xxx/0001-35, e BIGHARD S/A, CNPJ xx.xxx.xxx/0001-46; etc).",
  },
  {
    element: "input",
    type: "text",
    name: ["docs", "objectives"],
    placeholder: "Insira o objetivo e o escopo",
    label: "Objetivos e escopo",
    tooltip:
      "Objetivo da investigação (explicar neste campo a finalidade para a qual se pretende utilizar as informações, quais são os objetivos da investigação e o que se espera que a investigação revele).",
  },
  {
    element: "input",
    type: "text",
    name: ["docs", "observations"],
    placeholder: "Insira as observações adicionais",
    label: "Observações",
    tooltip:
      "Inserir aqui qualquer informação adicional e complementar que se considere relevante.",
  },
];


export interface CaseTypeInfoType {
  charge_and_credit_recovery?: InfoCaseType[],
  judicial_recovery?: InfoCaseType[],
  due_dilligence_merge_and_acquisition?: InfoCaseType[],
  due_dilligence_general?: InfoCaseType[],
  compliance?: InfoCaseType[],
  divorce?: InfoCaseType[],
  inventory?: InfoCaseType[],
  docs?: InfoCaseType[],
  coringa?: InfoCaseType[],
  credit_analysis?: InfoCaseType[],
  simples?: InfoCaseType[],
}


// *********************
// ***** OBJECT'S *****
// *********************
const pro_smart: CaseTypeInfoType = {
  charge_and_credit_recovery: ps_credit_recovery,
  judicial_recovery: ps_credit_recovery_rj,
  due_dilligence_merge_and_acquisition: ps_due_diligence_ma,
  due_dilligence_general: ps_preventive,
  compliance: ps_compliance,
  divorce: ps_divorce,
  inventory: ps_inventory,
  docs: _docs,
  coringa: ps_joker,
  credit_analysis: credit_analysis,
};


const smart: CaseTypeInfoType = {
  charge_and_credit_recovery: ps_credit_recovery,
  judicial_recovery: ps_credit_recovery_rj,
  // due_dilligence_merge_and_acquisition: ps_due_diligence_ma,
  // due_dilligence_general: ps_preventive,
  // compliance: ps_compliance,
  divorce: ps_divorce,
  // inventory: ps_inventory,
  // docs: _docs,
  // coringa: ps_joker,
  // preventive: credit_analysis,
};

const preventive: CaseTypeInfoType  = {
  credit_analysis: credit_analysis,
};

const docs: CaseTypeInfoType = {
  docs: _docs,
};


const simple: CaseTypeInfoType = {
  simples: _simple,
};

export interface MapInfosByCaseType_ {
  simples: CaseTypeInfoType,
  smart: CaseTypeInfoType,
  smart_pro: CaseTypeInfoType,
  preventivo: CaseTypeInfoType,
  custom: CaseTypeInfoType | null,
  docs: CaseTypeInfoType,
}

export const mapInfosByCaseType: MapInfosByCaseType_ = {
  simples: simple,
  smart: smart,
  smart_pro: pro_smart,
  preventivo: preventive,
  custom: null,
  docs: docs,
};
