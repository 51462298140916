
import React, { Route, Routes } from "react-router-dom"
// import Home from "../../features/dashborad/pages/home";
// import  { CreateReportLogged } from "../../features/dashborad/pages/create-report";
// import SimpleCase from "../../features/dashborad/pages/create-survey";
import ListReportsPage from "./pages/list_reports";
// import ListSurveysPage from "../../features/dashborad/pages/list_surveys";
// import MyProfile from "../../features/dashborad/pages/my-profile";
import UsersClient from "./pages/users";
import ClientsAdm from "./pages/clients-adm"
import ReportPage from "./pages/report-page";
import TargetStatusPage from "../target_status_page";
// import Home from "./pages/home";
// import PurchasedProductsPage from "./pages/purchased_products";

// import UserWalletValuesCard from "./components/wallet_values";

const Dashboard = () => {
  

  return (
    <>
     {/* <HeaderDash/> */}
      <Routes>
        <Route path="/" element={<ListReportsPage />} />
        <Route path="/report/:reportId" element={<ReportPage />} />
        <Route path="/report/:reportId/target/:targetId" element={<ReportPage />} />
        <Route path="/report/:reportId/target?/:targetId?/status" element={<TargetStatusPage />} />
        {/* <Route path="/home" element={<Home />} /> */}
        {/* <Route path="/create-report" element={<CreateReportLogged />} /> */}
        {/* <Route path="/create-survey" element={<SimpleCase isLogged={true} />} /> */}
        {/* <Route path="/reports" element={<ListReportsPage />} /> */}
        {/* <Route path="/surveys" element={<ListSurveysPage />} /> */}
        {/* <Route path="/profile" element={<MyProfile />} /> */}
        <Route path="/users/:clientId" element={<UsersClient />} />
        <Route path="/clients-adm" element={<ClientsAdm />} />
        {/* <Route path="/minhas-compras" element={<PurchasedProductsPage />} /> */}
      </Routes>
      {/* <UserWalletValuesCard /> */}
    </>
  );
};

export default Dashboard;
