
import React, { FunctionComponent, ReactNode, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//
import Menu from "../menu";
// @ts-ignore
import logo from "../../images/logo.png";
import "./index.scss";
import {
  loginSelector,
  resetGetProfileState,
  resetLoginState,
} from "../../../features/login/slice";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "antd";
import Button from "../button";
import { Icon } from "../Icons";
import StateStatus from "../../../utils/stateStatus";
import { ItemType } from "antd/lib/menu/interface";
import { MenuProps } from "antd/lib";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navRoutes = [
    {
      name: "Acesse nosso site",
      path: "https://inquest.com.br/site/",
    },
    {
      name: "Termos de uso",
      path: "/termos",
    },
    {
      name: localStorage.getItem("access_token") ? "Sair" : "Fazer login",
      path: "/logout",
    },
  ];

  return (
    <div className="container-header">
      <header>
        <img
          src={logo}
          className="logo"
          onClick={() => {
            if (window.location.href.includes("termos")) {
              navigate("/");
            } else {
              window.scrollTo(0, 0);
            }
          }}
          role="presentation"
          alt="inquest logo"
        />

        <div className="header-options">
          {navRoutes.map((route) => (
            <button
              type="button"
              key={route.name}
              className="header-option"
              onClick={() => {
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                dispatch(resetLoginState());

                if (route.path?.includes("https")) {
                  window.open(route.path, "_blank");
                } else if(route.path){
                  navigate(route.path);
                }
              }}
            >
              {route.name}
            </button>
          ))}
        </div>
      </header>

      <Menu routes={navRoutes} />
    </div>
  );
};


export const HeaderDash = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginState = useSelector(loginSelector);

  const profile = loginState.data?.profile
  const client = loginState.data?.client

  const navRoutes = [
    // {
    //   name: "Página inicial",
    //   path: "/dashboard",
    // },
    {
      name: "Início",
      path: "/dashboard",
    },
    // {
    //   name: "Buscas customizadas",
    //   path: "/dashboard/surveys",
    // },
    // {
    //   name: "Meu Perfil",
    //   path: "/dashboard/profile",
    // },
    {
      name: "Usuários",
      path: `/dashboard/users/${client?.id}`,
      isHide: !(profile?.is_staff || profile?.is_superuser || profile?.is_client_adm)
    },
    // {
    //   key: "/dashboard/home?carrinho=true",
    //   label: "Carrinho",
    // },
    // {
    //   key: "/dashboard/minhas-compras",
    //   label: "Minhas compras",
    // },
    {
      name: "Clientes (ADM)",
      path: "/dashboard/clients-adm",
      isHide: (!profile?.is_superuser || !profile?.is_staff)
    },
    {
      name: "Monitoramento",
      path: "/monitoring",
      isHide: (!profile?.is_superuser || !profile?.is_staff)
    },
    {
      name: localStorage.getItem("access_token") ? "Sair" : "Fazer login",
      path: "/logout",
    },
  ];
  // const itemsBuscas = [
  //   {
  //     key: "/dashboard/reports",
  //     label: "Buscas por CPF/CNPJ",
  //   },
  //   // {
  //   //   key: "/dashboard/surveys",
  //   //   label: "Buscas customizadas",
  //   // },
  // ];

  interface ItemMenu {
    key?: string,
    label?: ReactNode,
    isHide?: boolean | undefined,
    type?: string,
  }

  const itemsUser: ItemMenu[] = [
    {
      key: "/dashboard",
      label: "Início",
    },
    {
      key: `/dashboard/users/${client?.id}`,
      label: "Usuários",
      isHide: !(profile?.is_staff || profile?.is_superuser || profile?.is_client_adm)
    },
    // {
    //   key: "/dashboard/home?carrinho=true",
    //   label: "Carrinho",
    // },
    // {
    //   key: "/dashboard/minhas-compras",
    //   label: "Minhas compras",
    // },
    { type: "divider" },
    {
      key: "sair",
      label: <a href="#/logout">Sair</a>,
    },
  ];
  const itemsAdm = [
    {
      key: "/dashboard/clients-adm",
      label: "Clientes",
    },
    {
      key: "/monitoring",
      label: "Monitoramento",
    },
  ];

  interface DropdownItemProps {
    text?: ReactNode | undefined, 
    icon?:  ReactNode | undefined,
    items?: ItemType[] | undefined,
    onClick?: (e: any) => void,
  }

  

  
  const DropdownItem: FunctionComponent<DropdownItemProps> = ({ text, icon, items, onClick, ...props }) => {

    return (
      <Dropdown menu={{ items, onClick, ...props }} placement="bottom" arrow>
        <Button className="header-option">
          {icon ?? ""}
          {text}
        </Button>
      </Dropdown>
    );
  };

  const onClick = (e: any) => {
    navigate(e.key);
  };

  // useEffect(() => {
  //   dispatch(getProfileThunk());
  // }, []);

  useEffect(() => {
    if (loginState.status.getProfile === StateStatus.succeeded) {
      

      // setClient({
      //     id: loginState.data.client?.id ?? "",
      //     name: loginState.data.client?.name ?? "",
      //     cpf_cnpj: loginState.data.client?.cpf_cnpj ?? "",
      //     email: loginState.data.profile?.email ?? "",
      // })
    }

    if (loginState.status.getProfile === StateStatus.failed) {
      // localStorage.removeItem("access_token");
      // localStorage.removeItem("refresh_token");
      dispatch(resetLoginState());
      dispatch(resetGetProfileState());
      // if(redirectWithNoAuth === undefined 
      //   || redirectWithNoAuth === null 
      //   || redirectWithNoAuth) {
      // }
    }
  }, [loginState.status.getProfile]);

  return (
    <div className="container-header">
      <header>
        <Link to="/dashboard">
          <img
            src={logo}
            className="logo"
            onClick={() => {
              if (window.location.href.includes("termos")) {
                navigate("/");
              } else {
                window.scrollTo(0, 0);
              }
            }}
            role="presentation"
            alt="inquest logo"
          />
        </Link>

        <div className="header-options">
          {profile?.email ? (
            <>
              {profile?.is_superuser || profile?.is_staff ? (
                <DropdownItem
                  text={"Inquest Adm."}
                  items={itemsAdm}
                  onClick={onClick}
                />
              ) : null}
              <DropdownItem
                text={profile?.name}
                items={itemsUser.filter(x => !x.isHide).map(x => ({key: x.key, label: x.label, type: x.type}) as ItemType)}
                onClick={onClick}
                icon={
                  <Icon icon={"user-profile"} className="user-profile-icon" />
                }
              />
            </>
          ) : null}
        </div>
      </header>
      <Menu routes={navRoutes.filter(x => !x.isHide)} />
    </div>
  );
};
export default Header;
