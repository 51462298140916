import React, { FunctionComponent } from "react"
import { formatCurrency } from "../../../../../../utils/formatters"
import "./styles.scss"
import { Link, useLocation } from "react-router-dom"
import Icons from "../../../../../../common/components/Icons"
import { Col, Row } from "antd"

interface CardCounterProps {
    title: string, 
    qtd: number | undefined | null, 
    toLink: string, 
    labelLink: string | undefined | null, 
    currencyValue?: number | undefined | null,
    onClickLink?: () => void,
    highlightColor?: "red" | "green" | undefined,
    isLoading?: boolean,
}

const CardCounter: FunctionComponent<CardCounterProps> = ({title, qtd, toLink, labelLink, onClickLink, currencyValue, isLoading, highlightColor}) => {
    
    return (
        <div className={`card-counter ${highlightColor && qtd ? 'highlight-'+ highlightColor : ''}`}>
            <Row justify={"space-between"}>
                <Col>
                    <div className="title">{title}</div>
                    {qtd !== undefined && qtd !== null ? (
                        <div><span>Total:</span><span className="qtd-value">{" "}{qtd ?? 0}</span></div>
                    ) : null}
                </Col>
                {isLoading ? (
                    <Col>
                        <Icons icon="loading" spin={true}/>
                    </Col>
                ) : null}
            </Row>
            <div className="footer-card">
                <div className="link"><Link to={toLink} onClick={onClickLink} target="_self" 
                    //onClick={() => window.location.replace(`http://localhost:3000/#${toLink}`)}
                >{labelLink}</Link></div>
                {currencyValue !== undefined && currencyValue !== null ? (
                    <div className="total-value">{formatCurrency(currencyValue ?? 0, true)}</div>
                ) : null}
            </div>
        </div>
    )
}

export default CardCounter